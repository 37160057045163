import { useWindowSize } from '@react-hook/window-size'
import React, { useState } from 'react';
import Select from "react-select";
import Axios from "axios";
import DatePicker from "react-date-picker";
import Multiselect from 'multiselect-react-dropdown';
import { Row, Col, Button, Label, Input, FormGroup, Form } from 'reactstrap';
import Loader from '../Loader/Loader';
import Strip from '../Strip/Strip';
import StatusData from '../../utils/json/statusData';
import application_contants from '../../constants/application_contant';
import FileUploader from '../FileUploader/FileUploader';
import { displayMMDDYY, displayYYMMDD } from '../../utils/displayFormatter';
import apiConfig from '../../../api/apis/apiConfig';
import SevaService from '../../../api/services/SevaService';

const AddSeva = (props) => {
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [description, setDescription] = useState('');
    const [sectionTitle, setSectionTitle] = useState('');
    const [sectionDescription, setSectionDescription] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [totalAmount, setTotalAmount] = useState(0);
    const [imgFile, setImgFile] = useState('');
    const [imgFileName, setImgFileName] = useState('');
    const [isThumbnailFileChanged, setIsThumbnailFileChanged] = useState('');
    const [status, setStatus] = useState(false);
    const [isPaymentSchedule, setIsPaymentSchedule] = useState(false);
    const [id, setId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [width] = useWindowSize();
    const [subSevaOptions, setSubSevaOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const { isAdd, item, handleRefresh } = props;

    console.log("item", item);
    React.useEffect(() => {
        if (isAdd) {
            setId('');
            setTitle('');
            setSubTitle('');
            setDescription('');
            setSectionTitle('');
            setSectionDescription('');
            setStartDate('');
            setEndDate('');
            setTotalAmount('');
            setIsPaymentSchedule(false);
            setStatus(false);
            setImgFileName('')
        } else {
            setId(item.id)
            setTitle(item.sevaType === 'null' ? '' : item.sevaType);
            setSubTitle(item.sevaTypeTitle === 'null' ? '' : item.sevaTypeTitle);
            setDescription(item.description === 'null' ? '' : item.description);
            setSectionTitle(item.sectionTitle === 'null' ? '' : item.sectionTitle);
            setSectionDescription(item.sectionDescription === 'null' ? '' : item.sectionDescription);
            setImgFileName(item.imgPath);
            setIsPaymentSchedule(item.isPaymentSchedule === 0 ? false : true);
            setTotalAmount(item.totalAmount);
            const statusItem = StatusData.find((it) => it.value === item.status);
            setStatus(statusItem);
            if (item.isPaymentSchedule === 1) {
                setStartDate(displayMMDDYY(item.startDate));
                setEndDate(displayMMDDYY(item.endDate));
            } else {
                setStartDate(new Date());
                setEndDate(new Date());
            }
            if (item.subSeva.length > 0) {
                let arrayItem = [];
                item.subSeva.map((i) => {
                    const selectedItems = props.options.find((it) => it.id === i.subSevaId);
                    arrayItem.push(selectedItems);
                });
                setSelectedValue([...arrayItem]);
            }
        }
        setSubSevaOptions(props.options);

    }, [isAdd, item, props.options])

    const getThumbnail = async (item) => {
        await setImgFile(item[0].file);
        await setImgFileName(item[0].file.name);
        setIsThumbnailFileChanged(true);
    }

    const handleStartDate = async (date) => {
        setStartDate(date.toDateString());
    };

    const handleEndDate = async (date) => {
        setEndDate(date.toDateString());
    };

    const handlePaymentStatus = (value) => {
        setIsPaymentSchedule(value === 1 ? true : false)
    }

    const handleTitle = (event) => {
        setTitle(event.target.value);
    }

    const handleSubTitle = (event) => {
        setSubTitle(event.target.value);
    }

    const handleDescription = (event) => {
        setDescription(event.target.value);
    }

    const handleTotalAmount = (event) => {
        setTotalAmount(event.target.value);
    }

    const handleSectionTitle = (event) => {
        setSectionTitle(event.target.value)
    }

    const handleSectionDescription = (event) => {
        setSectionDescription(event.target.value)
    }

    const handleStatus = (item) => {
        setStatus(item)
    }

    const onSelect = (item) => {
        setSelectedValue(item);
    }

    const onRemove = (item) => {
        setSelectedValue(item);
    }


    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId');
        let selectedArray = [];
        if (selectedValue.length > 0) {
            selectedValue.map((it) => {
                selectedArray.push(it.id)
            });
        }

        let data = {
            userId: userId,
            id: id,
            sevaType: title,
            sevaTypeTitle: subTitle,
            description: description,
            sectionTitle: sectionTitle,
            sectionDescription: sectionDescription,
            totalAmount: totalAmount,
            isPaymentSchedule: isPaymentSchedule === true ? 1 : 0,
            moreInfo: `[]`,
            subSevaId: selectedArray.length > 0 ? JSON.stringify(selectedArray) : '[]',
            imgPath: isThumbnailFileChanged ? `/seva/${imgFileName}` : imgFileName,
            startDate: isPaymentSchedule === true ? displayYYMMDD(startDate) : '',
            endDate: isPaymentSchedule === true ? displayYYMMDD(endDate) : '',
            status: status && status.value === 1 ? 1 : 0,
        };

        if (isThumbnailFileChanged) {
            let files = [];
            files.push(imgFile);
            UploadVideo(files, data)
        } else {
            setIsLoading(true);
            insertOrUpdate(data);
        }
    }

    const UploadVideo = async (files, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/img/");
        await files.map((item) => {
            data.append("image", item);
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        // console.log('photos : ', data);
        Axios.post(`${apiConfig.BASEURL}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alert(`${res.message}`)
                            // alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };

    const insertOrUpdate = async (data) => {
        await SevaService.insertOrUpdateSeva(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alert(`${result.message}`)
                                // alertSuccessMessage(`${result.message}`);
                                handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alert(`${result.message}`)
                                //alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-seva"
            className="strip strip-no-padding"
            containerType="container admin-container box-shadow"
        >
            <Row className="mx-0 pt-3 pb-3 align-items-center">
                <Col xs={12} sm={12} md={12} lg={10} xl={10}>
                    <h6 className="sub-section-title title-black txt-uppercase gothamBlack align-left">
                        {props.isAdd ? 'Add Seva' : 'Update Seva'}
                    </h6>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                    <div>
                        <Button className="btn btn-block custom-blue-btn custom-btn--small" onClick={() => props.handleToggle()}>Back</Button>
                    </div>
                </Col>
            </Row>
            <Row className="mx-0 pt-3 pb-3 align-items-center">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
                    <Form>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Title* :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Title"
                                    id="Title"
                                    name="Title"
                                    className="form-control common-form-control"
                                    value={title}
                                    onChange={event => handleTitle(event)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    Sub Title :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="SubTitle"
                                    id="SubTitle"
                                    name="SubTitle"
                                    className="form-control common-form-control"
                                    value={subTitle}
                                    onChange={event => handleSubTitle(event)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Label className="common-label text-left">
                                    upload Image* :
                                </Label>
                                <FileUploader
                                    isImg={true}
                                    getThumbnail={(item) =>
                                        getThumbnail(item)
                                    }
                                    value={imgFileName}
                                    labelName="Upload Thumbnail File"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Description* :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Title"
                                    id="Title"
                                    name="Title"
                                    className="form-control common-form-control textarea-form-control"
                                    value={description}
                                    onChange={event => handleDescription(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    SubSeva :
                                </Label>
                                <Multiselect
                                    options={subSevaOptions} // Options to display in the dropdown
                                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                                    onSelect={onSelect} // Function will trigger on select event
                                    onRemove={onRemove} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                    style={{
                                        option: {
                                            fontSize: '0.75rem',
                                            fontFamily: 'Gotham-Book'
                                        }
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Label className="common-label text-left">
                                    Total Amount* :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Total Amount"
                                    id="Total Amount"
                                    name="Total Amount"
                                    className="form-control common-form-control"
                                    value={totalAmount}
                                    onChange={event => handleTotalAmount(event)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Label className="common-label text-left">
                                    Section Title :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Section Title"
                                    id="section Title"
                                    name="Section Title"
                                    className="form-control common-form-control"
                                    value={sectionTitle}
                                    onChange={event => handleSectionTitle(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Section Description :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="section Description"
                                    id="Section Description"
                                    name="Section Description"
                                    className="form-control common-form-control textarea-form-control"
                                    value={sectionDescription}
                                    onChange={event => handleSectionDescription(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Payment Schedule* :
                                </Label>
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex align-items-center" onClick={() => handlePaymentStatus(1)}>
                                                        <img src={isPaymentSchedule === true ? application_contants.SELECTIMG : application_contants.UNSELECTEDIMG} alt="" className={`cursor-pointer ${isPaymentSchedule === true ? 'icon_width_30' : 'width_40'}`} />
                                                        <span>
                                                            <Label className="common-label text-left fw-800">True</Label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex align-items-center" onClick={() => handlePaymentStatus(0)}>
                                                        <img src={isPaymentSchedule === false ? application_contants.SELECTIMG : application_contants.UNSELECTEDIMG} alt="" className={`cursor-pointer ${isPaymentSchedule === false ? 'icon_width_30' : 'width_40'}`} />
                                                        <span>
                                                            <Label className="common-label text-left fw-800">False</Label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            {isPaymentSchedule ?
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormGroup
                                            >
                                                <Label className="common-label text-left">
                                                    Start Date* :
                                                </Label>
                                                <DatePicker
                                                    type="text"
                                                    id="date"
                                                    onChange={(date) => handleStartDate(date)}
                                                    value={new Date(startDate)}
                                                    maxDate={new Date()}
                                                    className="react-date-picker"
                                                    showLeadingZeros={true}
                                                    clearIcon={false}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormGroup
                                            >
                                                <Label className="common-label text-left">
                                                    End Date* :
                                                </Label>
                                                <DatePicker
                                                    type="text"
                                                    id="date"
                                                    onChange={(date) => handleEndDate(date)}
                                                    value={new Date(endDate)}
                                                    minDate={new Date()}
                                                    className="react-date-picker"
                                                    showLeadingZeros={true}
                                                    clearIcon={false}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </Col>
                                : null}
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Status* :
                                </Label>
                                <Select
                                    value={status}
                                    onChange={handleStatus}
                                    options={StatusData}
                                    placeholder={"Select"}
                                    classNamePrefix="common-select-drop-down"
                                    isSearchable={false}
                                    isClearable
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8} className="m-auto">
                    <Row className="no-margin py-2 mt-4 mx-0">
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={3}
                            className={`${isLoading ? 'm-auto' : 'm-auto'}`}
                        >
                            {!isLoading ?
                                <Row>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-theme-btn custom-btn--small"
                                            onClick={props.handleToggle}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-blue-btn custom-btn--small"
                                            onClick={handleSubmit}
                                            disabled={title && imgFileName ? false : true}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row> : null}
                            {isLoading ? (
                                <div className="d-flex align-item-center justify-content-center">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Strip>
    )
}


export default AddSeva;

