import React, { useState } from 'react'
import Axios from "axios";
import DatePicker from 'react-date-picker';
import { Row, Col, Button, FormGroup, Form, Input, Label } from 'reactstrap';
import FileUploader from '../FileUploader/FileUploader';
import Loader from '../Loader/Loader';
import Strip from '../Strip/Strip';
import apiConfig from '../../../api/apis/apiConfig';
import BlogService from '../../../api/services/BlogService';
import { displayYYMMDD } from '../../utils/displayFormatter';


const AddBlogBannerImage = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [imgFileName, setImgFileName] = useState('');
    const [imgFile, setImgFile] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [title, setTitle] = useState('');
    const [id, setId] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [reference, setReference] = useState('');
    const [referenceDescription, setReferenceDescription] = useState('');
    const [referenceDate, setReferenceDate] = useState(new Date());
    const [referenceVideoOrImgFile, setReferenceVideoOrImgFile] = useState('');
    const [referenceVideoOrImgFileName, setReferenceVideoOrImgFileName] = useState('');
    const [isReferenceFileChanged, setIsReferenceFileChanged] = useState(false);

    const { handleToggle, isAdd, item, handleRefresh } = props;

    React.useEffect(() => {
        if (isAdd) {
            setId('');
            setImgFile('');
            setImgFileName('');
            setTitle('');
            setIsFileChanged(false);
            setShortDescription('');
            setReference('');
            setReferenceDate(new Date());
            setReferenceVideoOrImgFile('')
            setReferenceVideoOrImgFileName('');
            setDescription('');
        } else {
            setId(item.id)
            setImgFileName(item.imgPath);
            setTitle(item.title);
            setShortDescription(item.shortDescription);
            setReference(item.reference);
            setReferenceDate(new Date(item.referenceDate));
            setReferenceVideoOrImgFileName(item.referenceImgOrVideo);
            setDescription(item.description);
            setReferenceDescription(item.referenceDescription);
        }
    }, [isAdd, item])

    const handleTitle = (event) => {
        setTitle(event.target.value)
    }

    const getThumbnail = async (item) => {
        await setImgFile(item[0].file);
        await setImgFileName(item[0].file.name);
        setIsFileChanged(true);
    }

    const getVideoOrItem = async(item) => {
        await setReferenceVideoOrImgFile(item[0].file);
        await setReferenceVideoOrImgFileName(item[0].file.name);
        setIsReferenceFileChanged(true);
    }

    const handleShortDescription = (event) => {
        setShortDescription(event.target.value)
    }


    const handleDescription = (event) => {
        setDescription(event.target.value)
    }

    const handleReferenceDescription = (event) => {
        setReferenceDescription(event.target.value)
    }

    const handleReference = (event) => {
        setReference(event.target.value)
    }

    const handleReferenceDate = async (date) => {
        setReferenceDate(date.toDateString());
    };

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            imgPath: isFileChanged ? `/blog-assets/img/${imgFileName}` : imgFileName,
            id: id,
            title: title,
            userId: userId,
            description: description,
            shortDescription: shortDescription,
            referenceDate: displayYYMMDD(referenceDate),
            reference: reference,
            referenceDescription: referenceDescription,
            referenceVideoOrImg: isReferenceFileChanged ? `/blog-assets/img/${referenceVideoOrImgFileName}` : referenceVideoOrImgFileName,
            status: 1
        };

        if (isFileChanged && isReferenceFileChanged) {
            let files = [];
            files.push(imgFile);
            files.push(referenceVideoOrImgFile)
            UploadVideo(files, data)
        } else if (isFileChanged) {
            let files = [];
            files.push(imgFile);
            UploadVideo(files, data)
        } else if (referenceVideoOrImgFile) {
            let files = [];
            files.push(referenceVideoOrImgFile);
            UploadVideo(files, data)
        } else {
            setIsLoading(true);
            insertOrUpdate(data);
        }
    }

    const UploadVideo = async (files, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/img/");
        await files.map((item) => {
            data.append("image", item);
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        // console.log('photos : ', data);
        Axios.post(`${apiConfig.BASEURL}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alert(`${res.message}`)
                            // alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };

    const insertOrUpdate = async (data) => {
        await BlogService.insertOrUpdateBlogImg(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alert(`${result.message}`)
                                // alertSuccessMessage(`${result.message}`);
                                handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alert(`${result.message}`)
                                //alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-seva"
            className="strip strip-no-padding"
            containerType="container admin-container box-shadow"
        >
            <Row className="mx-0 pt-3 pb-3 align-items-center bg-light-blue br-lr-8px">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h6 className="sub-section-title title-white txt-uppercase gothamBlack align-left">
                        {isAdd ? 'Add Banner Image' : 'Update Banner Image'}
                    </h6>
                </Col>
            </Row>

            <Row className="mx-0 pt-3 pb-3 align-items-center">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
                    <Form>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Title* :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Title"
                                    id="Title"
                                    name="Title"
                                    className="form-control common-form-control"
                                    value={title}
                                    onChange={event => handleTitle(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Label className="common-label text-left">
                                    Upload Image File* :
                                </Label>
                                <FileUploader
                                    isImg={true}
                                    getThumbnail={(item) =>
                                        getThumbnail(item)
                                    }
                                    value={imgFileName}
                                    labelName="Upload Image File"
                                />
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Label className="common-label text-left">
                                    Upload Image-Video File* :
                                </Label>
                                <FileUploader
                                    isBoth={true}
                                    getVideoOrItem={(item) =>
                                        getVideoOrItem(item)
                                    }
                                    value={referenceVideoOrImgFileName}
                                    labelName="Upload Image/Video File"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Label className="common-label text-left">
                                    Reference Name* :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Reference Name"
                                    id="reference"
                                    name="reference"
                                    className="form-control common-form-control"
                                    value={reference}
                                    onChange={event => handleReference(event)}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Label className="common-label text-left">
                                    Reference Date* :
                                </Label>
                                <DatePicker
                                    type="text"
                                    id="date"
                                    onChange={(date) => handleReferenceDate(date)}
                                    value={new Date(referenceDate)}
                                    maxDate={new Date()}
                                    className="react-date-picker"
                                    showLeadingZeros={true}
                                    clearIcon={false}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Reference Description* :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Reference Description"
                                    id="ReferenceDescription"
                                    name="ReferenceDescription"
                                    className="form-control common-form-control"
                                    value={referenceDescription}
                                    onChange={event => handleReferenceDescription(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Short Description* :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Short Description"
                                    id="shortDescription"
                                    name="shortDescription"
                                    className="form-control common-form-control"
                                    value={shortDescription}
                                    onChange={event => handleShortDescription(event)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Description :
                                </Label>
                                <Input
                                    type="textarea"
                                    placeholder="Description"
                                    id="description"
                                    name="description"
                                    className="textarea-form-control form-control common-form-control"
                                    value={description}
                                    onChange={event => handleDescription(event)}
                                />
                            </Col>

                        </FormGroup>

                    </Form>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8} className="m-auto">
                    <Row className="no-margin py-2 mt-4 mx-0">
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={3}
                            className={`${isLoading ? 'm-auto' : 'm-auto'}`}
                        >
                            {!isLoading ?
                                <Row>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-theme-btn custom-btn--small"
                                            onClick={handleToggle}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-blue-btn custom-btn--small"
                                            onClick={handleSubmit}
                                            disabled={title && imgFileName && reference && shortDescription && referenceDate ? false : true}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row> : null}
                            {isLoading ? (
                                <div className="d-flex align-item-center justify-content-center">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Strip>
    )
}


export default AddBlogBannerImage;

