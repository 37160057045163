import React from "react";
import memoizeOne from "memoize-one";
import { displayMonthNameWithDate } from "../../../utils/displayFormatter";

const TablesColumns = memoizeOne(() => [
    {
        name: "PaymentId",
        grow: 2.5,
        selector: "paymentId",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.paymentId}</p></div>,
    },
    {
        name: "FullName",
        selector: "fullName",
        sortable: false,
        grow: 2,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.fullName.length > 50 ? `${row.fullName.substr(0, 50)}...` : row.fullName}</p></div>,
    },
    {
        name: "EmailId",
        grow: 2.5,
        selector: "emailId",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.emailId}</p></div>,
    },
    {
        name: "Amount",
        grow: 2,
        selector: "donateAmount",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.donateAmount}</p></div>,
    },
    {
        name: "Type",
        grow: 1,
        selector: "CurrencyType",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.currencyType}</p></div>,
    },
    {
        name: "Date",
        grow: 2,
        selector: "createdAt",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{displayMonthNameWithDate(row.createdAt)}</p></div>,
    },
    {
        name: "Status",
        selector: "paymentStatus",
        sortable: false,
        cell: (row) => <div className="py-1"><p className={`p-medium-small sub-section-title gothamBook ${row.paymentStatus === '1' ? 'title-green fw-600' : 'title-danger-light fw-600'}`}>{`${row.paymentStatus === '1' ? 'Success' : 'Failure'}`}</p></div>,
    },
]);


export default TablesColumns;
