import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import React, { useState } from 'react';
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import application_contants from '../../../constants/application_contant';
import Header from '../../../customComponents/Header';
import { setCurrentPage } from '../../../redux/actions/currentPage';
import { setCurrentPosition } from '../../../redux/actions/currentPosition';
import { setDirection } from '../../../redux/actions/direction';


const MobileHome = (props) => {
    const [data, setData] = useState([]);
    const state = useSelector(state => state);
    const [isVideoPlay, setIsVideoPlay] = useState(true);
    const { direction, isPlay } = state;
    const dispatch = useDispatch();
    const onlyWidth = useWindowWidth()
    const onlyHeight = useWindowHeight()

    React.useEffect(() => {
        if (props.data) {
            setData(props.Data);
        }
    }, [props]);

    const handleVideoPlay = () => {
        setIsVideoPlay(!isVideoPlay);
        dispatch(setDirection(direction.direction === application_contants.NONE ? application_contants.FORWARD : direction.direction));
        dispatch(setCurrentPosition(1));
    }

    const handleNavigation = (name) => {
        dispatch(setCurrentPage(name))
    }

    return (
        <div>
            <Header {...props} isMenuAnimate={true} isScroll={false} bgClassName={isVideoPlay ? '' : 'bg-stack-grey'} />
            {isVideoPlay ?
                <div>
                    <ReactPlayer
                        className="react-player"
                        url={direction && direction.direction === application_contants.BACKWARD ? application_contants.MOBILEVIDEO : application_contants.MOBILEVIDEO}
                        width={onlyWidth}
                        height={onlyHeight}
                        playing={isVideoPlay}
                        controls={false}
                        volume={0}
                        muted={true}
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1 },
                            },
                            attributes: {
                                controlsList: "download",
                                //poster: `${direction && direction.direction === application_contants.FORWARD ? item.posterImg : item.posterImg}`,
                            },
                        }}
                        onEnded={() => handleVideoPlay()}
                    />
                </div> :
                <div className="pt-4rem contianer">
                    <Row className={`mx-0 pt-4 content-mobile-container--center`}>
                        <Col xs={12} sm={12} md={11} lg={10} xl={8} className="m-auto">
                            <Row className="mx-0 justify-content-center">
                                {props.data[1].list.length > 0 && props.data[1].list.map((i, ind) => (
                                    <Col xs={6} sm={6} md={6} lg={4} xl={4} key={ind}
                                        className={`cursor-pointer animate__animated ${isPlay && isPlay.isPlay ? `animate__fadeOutUp ${`${i.classNames}`}` : `animate__fadeInUp ${`${i.classNames}`}`}`}
                                        onClick={() => handleNavigation(i.link)}>
                                        <div>
                                            <div className="position-relative">
                                                <img src={i.boxShadowImg} alt="" className="img-container" />
                                                <div className="layer-img">
                                                    <img src={i.img} alt="" className="layer-img-container" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="title-position--container">
                                            <div>
                                                <div className="pt-3 pb-2">
                                                    <h3 className="h3-small sub-section-title">{i.title}</h3>
                                                </div>
                                                <div className="content-description">
                                                    <p className="p-small sub-section-title gothamBook title-orinoco-green wp-1px">{i.desc.length < 80 ? i.desc.substr(0, 80) : i.desc}</p>
                                                    <p className="p-small sub-section-title gothamBook title-orinoco-green wp-1px pb-3">{i.subDesc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>

                    </Row>
                </div>}
        </div>
    )
}

export default MobileHome;