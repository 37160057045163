import React, { useState } from 'react';
import { Row, Col, CardBody, Card, FormGroup, Label, Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import RenderPage from '../../customComponents/RenderPage/RenderPage';
import Strip from '../../customComponents/Strip/Strip';
import { isEmailValid, isPwdValid } from '../../utils/customValidators';
import application_contants from '../../constants/application_contant';
import Loader from '../../customComponents/Loader/Loader';
import UserService from '../../../api/services/UserService';
import { updateUserAuthenticated } from '../../redux/actions/userInfo';
import { asyncStoreSave } from '../../utils/asyncStorage';

const Login = () => {
    const dispatch = useDispatch();
    const [emailId] = useState('');
    const [password] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all"
    });

    const onSubmit = async (data) => {
        await UserService.login(data.emailId, data.password)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                setIsLoading(false);
                                asyncStoreSave('fullName', result.data.fullName);
                                asyncStoreSave('emailId', result.data.emailId);
                                asyncStoreSave('userId', result.data.id);
                                asyncStoreSave('isLogingin', true);
                                dispatch(updateUserAuthenticated(result.data.fullName, result.data.emailId, true));
                            } else {
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }


    return (
        <RenderPage
            id="login-page"
            className="render-page mt-0 full-height d-flex align-items-center login-bg-color"
            containerType="container-fluid "
        >
            <Strip
                id="tst-login"
                className="strip strip-no-padding"
                containerType="container"
            >
                <Row>
                    <Col xs={12} sm={12} md={12} lg={8} xl={5} className="m-auto">
                        <Card className="card-container">
                            <CardBody className="card-body--container p-0">
                                <Row className="ht-80vh align-items-center">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Row>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="m-auto pb-2 pt-4">
                                                <img src={application_contants.BLACKLOGOIMG} alt="" />
                                            </Col>
                                        </Row>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mx-0 px-3">
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                                    <FormGroup>
                                                        <Label className="common-label">Email</Label>
                                                        <input
                                                            {...register("emailId", {
                                                                required: "emailId is required.",
                                                                pattern: {
                                                                    value: isEmailValid,
                                                                    message: "please enter valid emailId."
                                                                },
                                                            })}
                                                            defaultValue={password}
                                                            className="form-control common-form-control"
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="emailId"
                                                            render={({ messages }) => {
                                                                console.log("messages", messages);
                                                                return messages
                                                                    ? Object.entries(messages).map(([type, message]) => (
                                                                        <p key={type} className="p-small help-block">{message}</p>
                                                                    ))
                                                                    : null;
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-3">
                                                    <FormGroup>
                                                        <Label className="common-label">Password</Label>
                                                        <input
                                                            type="password"
                                                            {...register("password", {
                                                                required: "password is required.",
                                                                pattern: {
                                                                    value: isPwdValid,
                                                                    message: "please enter valid password."
                                                                },
                                                            })}
                                                            defaultValue={emailId}
                                                            className="form-control common-form-control"
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="password"
                                                            render={({ messages }) => {
                                                                console.log("messages", messages);
                                                                return messages
                                                                    ? Object.entries(messages).map(([type, message]) => (
                                                                        <p key={type} className="p-small help-block">{message}</p>
                                                                    ))
                                                                    : null;
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} ms={12} md={12} lg={12} xl={12} className="pb-4 pt-3">
                                                    <Button className="btn btn-block custom-theme-btn">Submit</Button>
                                                </Col>
                                            </Row>
                                        </form>
                                        {isLoading ? (
                                            <div className="loader-position">
                                                <Loader containerClassName="" />
                                            </div>
                                        ) : null}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>
            </Strip>
        </RenderPage>
    )
};


export default Login;