import { useWindowSize } from '@react-hook/window-size';
import React, { useState } from 'react';
import { ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import { isNumeric } from '../../utils/customValidators';
import { getDays } from '../../utils/displayFormatter';
import FormValidator from '../../utils/FormValidator';
import IsInputInError from '../../utils/IsInputInError';
import PaymentFromValidator from '../../utils/json/PaymentFromValidator';
import PaymentSchedule from '../../utils/json/PaymentSchedule';

let validator;
validator = new FormValidator([...PaymentFromValidator]);

const PaymentForm = (props) => {
    const [width] = useWindowSize();
    const [values, setValues] = useState({
        donationAmount: '',
        fullName: '',
        emailId: '',
        mobile: '',
        address: '',
    });
    const [isRefresh, setIsRefresh] = useState(false);
    const [validationField] = useState(validator.valid());
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [amount, setAmount] = useState(100)
    let isValidate = true;

    const handleChange = e => {
        e.preventDefault();
        validator.touched(e.target.name);
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    React.useEffect(() => {
        setIsRefresh(!isRefresh);
        setValues({
            ...values,
            donationAmount: 100,
            fullName: '',
            emailId: '',
            mobile: '',
            address: '',
        });
        return (() => {
            validator = new FormValidator([
                {
                    field: "fullName",
                    constraints: [
                        {
                            method: "isEmpty",
                            validWhen: false,
                            message: "Please enter fullName",
                        },
                    ],
                    touched: false,
                },
                {
                    field: "emailId",
                    constraints: [
                        {
                            method: "isEmpty",
                            validWhen: false,
                            message: "Please enter emailId",
                        },
                        {
                            method: "isEmail",
                            validWhen: true,
                            message: "Please enter valid email Id.",
                        },
                    ],
                    touched: false,
                },
                {
                    field: 'mobile',
                    constraints: [
                        {
                            method: isNumeric, validWhen: true, message: 'mobile Number allows only numeric characters'
                        },
                        {
                            method: 'isLength', validWhen: true, message: 'mobile Number sholud be max 12 characters', args: [{ max: 12 }],
                        },
                    ],
                    touched: false,
                },
                {
                    field: 'donationAmount',
                    constraints: [
                        {
                            method: isNumeric, validWhen: true, message: 'amount allows only numeric characters'
                        },
                    ],
                    touched: false,
                }
            ]);
        })
    }, [])

    const handleSubmit = async (e) => {

        let errorMessage = [];
        errorMessage = validator.getErrorMessage(values);
        if (errorMessage.length <= 0) {
            let data = {
                fullName: values.fullName,
                amount: values.donationAmount,
                emailId: values.emailId,
                mobile: values.mobile,
                address: values.address,
                paymentSchedule: selectedIndex,
            }
            props.handleFormData(e, data);
        } else {
            alert(`${errorMessage[0]}`);
        }

    }

    // const handleReset = () => {
    //     setValues({
    //         ...values,
    //         fullName: '',
    //         comment: '',
    //         phoneNumber: '',
    //         organization: '',
    //         emailId: ''
    //     });
    //     validator.unTouched();
    // }

    const validation = isValidate
        ? validator.validate(values)
        : validationField;



    const handleSelectedIndex = (index) => {
        setSelectedIndex(index)
    }

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const handleAmount =(amount) => {
        if(amount !== -1){
            setValues({
                ...values,
                donationAmount: amount
            });
            setAmount(amount)
        }else{
            setValues({
                ...values,
                donationAmount: 0
            });
            setAmount('Other') 
        }
    }

    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                <Form className="px-3">
                    <FormGroup row className="mx-0 align-items-center">
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mobile-no-padding">
                            <Label className="common-label text-left">
                                Donate Amount*:
                            </Label>
                            <div className={IsInputInError(
                                validation.donationAmount.isInvalid
                            )}>
                                <InputGroup>
                                    <Input
                                        type="text"
                                        placeholder="Donate Amount"
                                        id="First Name"
                                        name="donationAmount"
                                        className="form-control common-form-control"
                                        value={`${values.donationAmount}`}
                                        onChange={handleChange}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <ButtonDropdown className="input-group-btn-small" isOpen={isOpen} toggle={() => toggle()}>
                                            <DropdownToggle caret>
                                                {amount}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => handleAmount(100)}>100</DropdownItem>
                                                <DropdownItem onClick={() => handleAmount(500)}>500</DropdownItem>
                                                <DropdownItem onClick={() => handleAmount(1000)}>1000</DropdownItem>
                                                <DropdownItem onClick={() => handleAmount(5000)}>5000</DropdownItem>
                                                <DropdownItem onClick={() => handleAmount(-1)}>Other</DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </InputGroupAddon>
                                </InputGroup>
                                <p className="p-small help-block">
                                    {validation.donationAmount.message}
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className="mobile-no-padding">
                            <Label className="common-label text-left">
                                Full Name*:
                            </Label>
                            <div className={IsInputInError(
                                validation.fullName.isInvalid
                            )}>
                                <Input
                                    type="text"
                                    placeholder="Full Name"
                                    id="First Name"
                                    name="fullName"
                                    className="form-control common-form-control"
                                    value={values.fullName}
                                    onChange={handleChange}
                                />
                                <p className="p-small help-block">
                                    {validation.fullName.message}
                                </p>
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mx-0 align-items-center">

                        <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mobile-16px">
                            <Label className="common-label text-left">
                                Email Id*:
                            </Label>
                            <div className={IsInputInError(
                                validation.emailId.isInvalid
                            )}>
                                <Input
                                    type="text"
                                    placeholder="Email Id"
                                    id="Email Id"
                                    name="emailId"
                                    className="form-control common-form-control"
                                    value={values.emailId}
                                    onChange={handleChange}
                                />
                                <p className="p-small help-block">
                                    {validation.emailId.message}
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Label className="common-label text-left">
                                Mobile*:
                            </Label>
                            <div className={IsInputInError(
                                validation.mobile.isInvalid
                            )}>
                                <Input
                                    type="text"
                                    placeholder="Mobile Number"
                                    id="mobile"
                                    name="mobile"
                                    className="form-control common-form-control"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    maxLength={12}
                                />
                                <p className="p-small help-block">
                                    {validation.mobile.message}
                                </p>
                            </div>
                        </Col>

                    </FormGroup>
                    <FormGroup row className="mx-0 align-items-center">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-no-padding">
                            <Label className="common-label text-left">
                                Address:
                            </Label>
                            <Input
                                type="text"
                                placeholder="Mailing Address"
                                id="Mailing Address"
                                name="address"
                                className="form-control common-form-control"
                                value={values.address}
                                onChange={handleChange}
                            />

                        </Col>
                    </FormGroup>
                    {props.isPaymentSchedule ?
                        <FormGroup row className="mx-0 align-items-center mt-3">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-no-padding d-flex align-items-center justify-content-between">
                                {PaymentSchedule.length > 0 && PaymentSchedule.map((it, index) => (
                                    <div className="">
                                        <div className="d-flex align-items-center cursor-pointer" key={index} onClick={() => handleSelectedIndex(index)}>
                                            <img src={selectedIndex === index ? application_contants.ACTIVERADIOIMG : application_contants.INACTIVERADIOIMG} alt="" className="width_24px" />
                                            <p className="mb-0 common-label pl-6px">{it.type}</p>
                                        </div>
                                    </div>
                                ))}
                            </Col>
                        </FormGroup>
                        : null}
                    <FormGroup row className={`mx-0 align-items-center pt-3 ${props.totalAmount === 0 ? 'pb-3' : ''}`}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-no-padding">
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <p className="p-small sub-section-title align-left gothamBook cursor-pointer" onClick={() => props.handleModal()}>{`Back`}</p>
                                </div>
                                {props.totalAmount === 0 ?
                                    <div className={`${width <= application_contants.MOBILE_BREAKPOINT? 'width_70' : 'width_38'}`}>
                                        <div className="position-relative donate-btn hover-one ml-auto">
                                            <img src={application_contants.REDDONATEIMG} alt="" />
                                            <div className="donate-btn-text--position">
                                                {values.fullName !== '' && values.emailId !== '' && values.donationAmount !== '' && values.mobile !== "" ?
                                                    <p className="p-small sub-section-title title-black txt-uppercase saolStandardMedium btn--small cursor-pointer pt-1" onClick={(e) => handleSubmit(e)}>Donate Now</p>
                                                    : <p className="p-small sub-section-title title-black txt-uppercase saolStandardMedium btn--small cursor-pointer pt-1">Donate Now</p>
                                                }
                                            </div>
                                        </div>
                                    </div> : null}
                            </div>
                        </Col>
                    </FormGroup>
                </Form>
                {props.totalAmount !== 0 ?
                    <Row className="mx-0 align-items-center payment-form-info--container position-relative">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-no-padding">
                            <Row className="mx-0 align-items-start">
                                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                                    <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-very-small' :'p-small'} sub-section-title gothamBook fw-600 title-white align-center p-1 br-bt-1px`}>YOUR SUPPORT IS OUR STRENGTH</p>
                                    <div className="line-divider"></div>
                                    <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-very-small' :'p-small'} sub-section-title gothamBook fw-600 title-white align-center pt-1`}>{props.totalDonateAmount} raised of {props.totalAmount} goal</p>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2} className="position-relative">
                                    <div className={`progress--circle progress--pie progress--${parseInt((props.totalDonateAmount / props.totalAmount) * 100)}`}>
                                    </div>
                                    <div className="pie-position">
                                        <img src={application_contants.TIMERIMG} alt="" />

                                    </div>
                                </Col>
                                <Col xs={3} sm={3} md={3} lg={3} xl={3} className="pt-3">
                                    <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-very-small' : 'p-small'} sub-section-title gothamBook fw-600 title-white align-center`}>{`${getDays(props.startDate, props.endDate)}`} DAYS TO GO</p>
                                    <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'width_45' : 'width_38'} payment-btn--position`}>
                                        <div className="position-relative donate-btn hover-one ml-auto">
                                            <img src={application_contants.REDDONATEIMG} alt="" />
                                            <div className="donate-btn-text--position">
                                                {values.fullName !== '' && values.emailId !== '' && values.donationAmount !== '' && values.mobile !== "" ?
                                                    <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-very-small' : 'p-small'} sub-section-title title-black txt-uppercase saolStandardMedium btn--small cursor-pointer pt-1`} onClick={(e) => handleSubmit(e)}>Donate Now</p>
                                                    : <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-very-small' : 'p-small'} sub-section-title title-black txt-uppercase saolStandardMedium btn--small cursor-pointer pt-1`}>Donate Now</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Col>
                    </Row> : null}
            </Col>
        </Row>
    )
}

export default PaymentForm;