import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
    PaymentOutlined,
    ListAltOutlined,
    WorkOutlineSharp
} from "@material-ui/icons";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import application_contants from "../../constants/application_contant";
import activeTab from "../../utils/activeTab";

const SlideMenu = () => {
    const history = useHistory();
    const location = useLocation();
    return (
        <Navigation
            activeItemId={activeTab(location.pathname)}
            onSelect={({ itemId }) => {
                history.push(itemId);
            }}
            items={[
                {
                    title: "Payment Info",
                    itemId: application_contants.PAYMENT,
                    elemBefore: () => <PaymentOutlined />,
                    //subNav: [],
                },
                {
                    title: "Seva Info",
                    itemId: application_contants.ADMINSEVALIST,
                    elemBefore: () => <ListAltOutlined />,
                    subNav: [
                        {
                            title: 'Seva List',
                            itemId: application_contants.ADMINSEVALIST,
                            elemBefore: () => <ListAltOutlined />,
                        },
                        {
                            title: 'SubSeva List',
                            itemId: application_contants.ADMINSUBSEVALIST,
                            elemBefore: () => <ListAltOutlined />,
                        },
                    ],
                },
                {
                    title: "Blog",
                    itemId: application_contants.ADMINBLOGVIDEOLIST,
                    elemBefore: () => <WorkOutlineSharp />,
                    subNav: [
                        {
                            title: 'Banner Video',
                            itemId: application_contants.ADMINBLOGVIDEOLIST,
                            elemBefore: () => <WorkOutlineSharp />,
                        },
                        {
                            title: 'Blog List',
                            itemId: application_contants.ADMINBLOGIMAGELIST,
                            elemBefore: () => <WorkOutlineSharp />,
                        },
                        {
                            title: 'Submission List',
                            itemId: application_contants.ADMINBLOGSUBMISSIONLIST,
                            elemBefore: () => <WorkOutlineSharp />,
                        },

                    ],
                },
                // {
                //     title: 'Seva',
                //     itemId: '/seva',
                //     elemBefore: () =>  <ViewCarousel />,
                //     subNav: [
                //       {
                //         title: 'Seva List',
                //         itemId: '/seva/sevaList',
                //         elemBefore: () =>  <ViewCarousel />,
                //       },
                //       {
                //         title: 'Sub Seva',
                //         itemId: '/seva/sub-seva',
                //         elemBefore: () =>  <ViewCarousel />,
                //       },
                //     ],
                // },    
                // {
                //     title: "Location",
                //     itemId: application_contants.LOCATION,
                //     elemBefore: () => <MapOutlined />,
                //     //subNav: [],
                // },
                // {
                //     title: "About",
                //     itemId: application_contants.ABOUT,
                //     elemBefore: () => <HomeOutlined />,
                //     //subNav: [],
                // },
                // {
                //     title: "People",
                //     itemId: application_contants.PEOPLES,
                //     elemBefore: () => <PeopleAltRounded />,
                //     //subNav: [],
                // },
                // {
                //     title: "Supporter",
                //     itemId: application_contants.SUPPORTER,
                //     elemBefore: () => <CollectionsBookmark />,
                //     //subNav: [],
                // },
                // {
                //     title: "Projects",
                //     itemId: application_contants.PROJECT,
                //     elemBefore: () => <WorkOutline />,
                //     //subNav: [],
                // },
                // {
                //     title: "Financials",
                //     itemId: application_contants.FINANACIAL,
                //     elemBefore: () => <ReportOffOutlined />,
                //     //subNav: [],
                // },
            ]}
        />
    );
};

export default SlideMenu;
