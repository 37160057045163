import application_contants from "../../constants/application_contant";

const ChairmansMessageData = [
    {
        imgPath:application_contants.MADHUPANDITCHAIRMANIMG,
        name: 'Madhu Pandit Dasa',
        designation:'Chairman',
        foundation: 'Srila Prabhupada Foundation',
        title: 'Chairman’s Message',
        description: [
            {
                params: `His Divine Grace A.C. Bhaktivedanta Swami Prabhupada was born
                Abhay Charan De, in 1896, Calcutta, India. Right from infancy, he exhibited
                a natural devotion to Sri Krishna. In 1922, Abhay first met his spiritual master
                Srila Bhaktisiddhanta Saraswati Goswami, a pure devotee of Lord Krishna,
                who requested Abhay to spread Krishna Consciousness in the western world. `
            },
            {
                params: `This was a period of unrest in Indian society owing to foreign rule, riots based
                on ephemeral conceptions of religion and the Non-cooperation movement.
                Srila Prabhupada fearlessly and single-handedly set out on a mission from
                India to America in 1965 at the advanced age of 69 to fulfil the order of his
                spiritual master. He had a divine mission to enrich a spiritually impoverished
                world with the timeless message of bhakti-yoga, universal love and
                brotherhood.`
            },
            {
                params: `He propagated a unique set of solutions to the problems that plagued the
                society based on the solid principles laid out in the Bhagavad Gita.
                He practically demonstrated the idea of Vasudhaiva Kutumbakam (the whole
                world as one’s family) mentioned in the scriptures. He built a house big
                enough for the whole world to live together in peace and harmony.
                Srila Prabhupada spread the universal, non-sectarian message of love for God
                and all living beings in a world inflicted with strife and war. Thus, he brought
                peace and joy to the lives of millions worldwide.`
            },
            {
                params: `Srila Prabhupada Foundation has been established to preserve, spread and
                articulate the unique solutions oered by Srila Prabhupada to the current day
                problems of society. To seek a deeper understanding of Srila Prabhupada and
                to spread awareness of his saintly personality and compassionate mission, we
                have planned various activities such as acquiring, maintaining and preserving
                the works, memorabilia and other historical materials about his life, preserving
                and renovating the places, medieval buildings and ancient temples related to
                Srila Prabhupada, establishing a museum of his personal items, memorabilia,
                mementos and objects pertaining to his life and his institution, publishing
                books, articles and periodicals both online and oine in as many languages
                as possible, organizing lectures, seminars, symposia, conferences, and
                exhibitions in India and abroad to promote and encourage the study of his
                life and teachings and availing to the public a suitable place to encourage
                the serious study and research of his transcendental personality, life, work,
                teachings and institutions.I welcome you to be a part of our endeavour in
                propagating Srila Prabhupada’s mission. To get more insight into the
                activities of Srila Prabhupada Foundation.`
            },
            {
                params: `I request you to please visit our newly launched website (srilaprabhupada.in)
                and our new blog (humanspirit.in). As we embark on this new beginning,
                I thank all of our supporters, donors, well-wishers, and volunteers who are
                extending their unwavering support.`
            },
        ],
        signatureText: 'Yours in the service of God and humanity,',
        signatureImg: application_contants.MADHUPANDITSIGNATUREIMG
    }
];

export default ChairmansMessageData;