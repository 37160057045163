import React from "react";
import memoizeOne from "memoize-one";
import { Button } from "reactstrap";

const TablesColumns = memoizeOne((handleEdit) => [
    {
        name: "EmailId",
        grow: 2.5,
        selector: "emailId",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.emailId}</p></div>,
    },
    {
        name: "Title",
        grow: 2.5,
        selector: "title",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.sevaType}</p></div>,
    },
    {
        name: "Type",
        grow: 2.5,
        selector: "storyType",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.storyType}</p></div>,
    },
    {
        name: "Description",
        selector: "description",
        sortable: false,
        grow: 2,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.description ? row.description.length > 50 ? `${row.description.substr(0, 20)}...` : row.description : ''}</p></div>,
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        cell: (row) => <div className="py-1"><p className={`p-medium-small sub-section-title gothamBook fw-600 ${row.status === 1 ? 'title-stack-grey' : row.status === 2 ? 'title-green' : 'title-danger-light'}`}>{`${row.status === 1 ? 'Pending' : row.status === 2 ? 'Approval' : 'Reject'}`}</p></div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        ignoreRowClick: true,
        allowOverflow: true,
        grow: 2,
        cell: (row) => (
            <div className="d-flex py-1">
                <div onClick={() => handleEdit(row)}>
                    <Button className="btn btn-small custom-green-btn custom-btn--small fs-10px">Edit</Button>
                </div>
            </div>
        ),
    },
]);


export default TablesColumns;
