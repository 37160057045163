import { isNumeric } from '../../utils/customValidators';

const PaymentFromValidator = [
    {
        field: "fullName",
        constraints: [
            {
                method: "isEmpty",
                validWhen: false,
                message: "Please enter fullName",
            },
        ],
        touched: false,
    },
    {
        field: "emailId",
        constraints: [
            {
                method: "isEmpty",
                validWhen: false,
                message: "Please enter emailId",
            },
            {
                method: "isEmail",
                validWhen: true,
                message: "Please enter valid email Id.",
            },
        ],
        touched: false,
    },
    {
        field: 'mobile',
        constraints: [
            {
                method: isNumeric, validWhen: true, message: 'mobile Number allows only numeric characters'
            },
            {
                method: 'isLength', validWhen: true, message: 'mobile Number sholud be max 12 characters', args: [{ max: 12 }],
            },
        ],
        touched: false,
    },
    {
        field: 'donationAmount',
        constraints: [
            {
                method: isNumeric, validWhen: true, message: 'amount allows only numeric characters'
            },
        ],
        touched: false,
    }
]

export default PaymentFromValidator;