import React, { useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "@react-hook/window-size";
import Header from "../../../customComponents/Header";
import application_contants from "../../../constants/application_contant";
import { setCurrentPage } from "../../../redux/actions/currentPage";
import Footer from "../../../customComponents/Footer/Footer";
import GuruParampara from "../../GuruParampara/GuruParampara";
import VideoPlayer from "./VideoPlayer";
import Influence from "../../Influence/Influence";
import SrilaprabupadaData from "../../../utils/json/srilaprabupadaData";

const SrilaprahupadaBioHistory = () => {
    const prevScrollY = useRef(0);
    const state = useSelector(state => state);
    const { direction } = state;
    let myRef = useRef(null);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0)
    const [bioData, setBioData] = useState(null);
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [classNamesLR, setClassNamesLF] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('')
    const [goingUp, setGoingUp] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [referenceHeight, setReferenceHeight] = useState(0);
    const [width] = useWindowSize();
    const item = SrilaprabupadaData[1];

    React.useEffect(() => {
        setData(SrilaprabupadaData[1])
        setData(SrilaprabupadaData[1]);
        setBioData(SrilaprabupadaData[1] ? SrilaprabupadaData[1].bio[0] : []);
        setActiveIndex(0);
        window.scrollTo(0, 0);
    }, [data]);


    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn animate__slow` : `animate__animated animate__zoomIn animate__slow`
            setClassNames(cls);
            let clsLR = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInRight animate__slow` : `animate__animated animate__slideInRight animate__slow`
            setClassNamesLF(clsLR)
        }, 500)
        return () => {

            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
                setClassNamesLF('');
            }, 200)
        }
    }, [direction]);


    React.useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (prevScrollY.current < currentScrollY && goingUp) {
                setGoingUp(false);
            }
            if (prevScrollY.current > currentScrollY && !goingUp) {
                setGoingUp(true);
            }

            prevScrollY.current = currentScrollY;
            // console.log(goingUp, currentScrollY);
            setScrollHeight(currentScrollY);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => window.removeEventListener("scroll", handleScroll);
    }, [goingUp]);


    const handleMoreInfo = (index) => {
        setBioData(data ? data.bio[index] : []);
        window.scrollTo(0, 0);
        setActiveIndex(index)
    }

    const handleNavigation = (name) => {
        dispatch(setCurrentPage(name));
    }

    const handleMenu = (value) => {
        setSelectedTitle(value);
        setActiveIndex(-1);
        window.scrollTo(0, 0);
    }

    const handleRef = (value) => {
        setReferenceHeight(value)
    };

    return (
        <div>
            <div className={`bio-content-container ${activeIndex !== -1 ? 'pt-4rem' : ''} ${selectedTitle === '' ? 'bg-narvik-green' : ''}`} id="section-3">
                <Header isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} isFooter={false} isScroll={false} menuClassNames={'title-melanzane-Violet'} scrollPositionClassNames={`bt-32px`}
                    bgClassName={activeIndex !== -1 ? 'bg-narvik-green' : selectedTitle === 'His Influence' ? scrollHeight >= 350 ? 'bg-celeste' : '' : activeIndex === -1 && selectedTitle === 'Guru Parampara' ? scrollHeight >= referenceHeight ? 'bg-celeste' : '' : ''}
                />
                {classNames !== '' ?
                    <div>
                        {activeIndex === -1 && selectedTitle === 'His Influence' ?
                            <div>
                                <Influence />
                                {width <= application_contants.MOBILE_BREAKPOINT ?
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3} className={`bg-white py-3 ${isAnimate ? `${classNames}` : ''}`}>
                                        <div className="mx-0">
                                            <div>
                                                <div className="d-flex align-items-start">
                                                    <div className="item_width_70">
                                                        <div>
                                                            <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex !== -1 ? 'title-black gothamBlack' : 'gothamBook'}`}
                                                                onClick={() => handleMoreInfo(0)}
                                                            >
                                                                Srila Prabhupada
                                                            </p>
                                                        </div>

                                                        <div onClick={() => handleMenu(`His Influence`)}>
                                                            <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex === -1 && selectedTitle === 'His Influence' ? 'title-black gothamBlack' : 'gothamBook'}`}
                                                            >
                                                                His Influence
                                                            </p>
                                                        </div>
                                                        <div onClick={() => handleMenu(`Guru Parampara`)}>
                                                            <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer  ${activeIndex === -1 && selectedTitle === 'Guru Parampara' ? 'title-black gothamBlack' : 'gothamBook'}`}
                                                            >
                                                                Guru Parampara
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                        {item.bio.length > 0 && item.bio.map((it, index) => (
                                                            <div key={index} onClick={() => handleMoreInfo(index)} className={`${isAnimate ? `${classNames}` : ''}  ${index === 0 ? 'dp-none' : ''}`}>
                                                                <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer 
                                                             ${activeIndex === index ? 'title-black gothamBlack' : 'title-stack-grey gothamBook'}`}
                                                                >
                                                                    {it.title}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null}
                            </div> : null}
                        {activeIndex === -1 && selectedTitle === 'Guru Parampara' ?
                            <div>
                                <GuruParampara handleRef={(value) => handleRef(value)} />
                                {width <= application_contants.MOBILE_BREAKPOINT ?
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3} className={`bg-white py-3 ${isAnimate ? `${classNames}` : ''}`}>
                                        <div className="mx-0">
                                            <div>
                                                <div className="d-flex align-items-start">
                                                    <div className="item_width_70">
                                                        <div>
                                                            <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex !== -1 ? 'title-black gothamBlack' : 'gothamBook'}`}
                                                                onClick={() => handleMoreInfo(0)}
                                                            >
                                                                Srila Prabhupada
                                                            </p>
                                                        </div>

                                                        <div onClick={() => handleMenu(`His Influence`)}>
                                                            <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex === -1 && selectedTitle === 'His Influence' ? 'title-black gothamBlack' : 'gothamBook'}`}
                                                            >
                                                                His Influence
                                                            </p>
                                                        </div>
                                                        <div onClick={() => handleMenu(`Guru Parampara`)}>
                                                            <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer  ${activeIndex === -1 && selectedTitle === 'Guru Parampara' ? 'title-black gothamBlack' : 'gothamBook'}`}
                                                            >
                                                                Guru Parampara
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-wrap">
                                                        {item.bio.length > 0 && item.bio.map((it, index) => (
                                                            <div key={index} onClick={() => handleMoreInfo(index)} className={`${isAnimate ? `${classNames}` : ''}  ${index === 0 ? 'dp-none' : ''}`}>
                                                                <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer 
                                                             ${activeIndex === index ? 'title-black gothamBlack' : 'title-stack-grey gothamBook'}`}
                                                                >
                                                                    {it.title}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    : null}
                            </div>
                            : null}
                        {activeIndex !== -1 ?
                            <div className="bg-narvik-green">
                                <Row className={`mx-0 ${width <= 1024 ? 'pt-32px' : ''}`}>
                                    <Col xs={11} sm={11} md={11} lg={{ offset: 2, size: 7 }} xl={{ offset: 2, size: 7 }} className={`position-relative ${width <= application_contants.MOBILE_BREAKPOINT ? 'm-auto' : 'ml-auto'}`}>
                                        <div>
                                            {bioData !== null ?
                                                <div className={``}>
                                                    <div className="title-fixed-position">
                                                        <h3 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h1-small align-center' : 'align-left'} sub-section-title py-2 ${isAnimate ? `${classNames}` : ''}`}>{bioData.title}</h3>
                                                    </div>
                                                    <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'pt-5rem' : 'py-5'}`} ref={myRef} id="myDiv">
                                                        {bioData.desc.length > 0 && bioData.desc.map((i, ind) => (
                                                            <div>
                                                                {ind === 2 ?
                                                                    <div>
                                                                        <p key={ind} className={`p-medium sub-section-title align-left gothamBook lh-26px wp-1px ${`${i.isPadding ? 'pb-4' : ''}`} ${`${i.isItalic ? 'fs-italic' : ''}`} ${isAnimate ? `${classNames}` : ''}`}>{i.params}</p>
                                                                        {bioData.videoURL ?
                                                                            <div className="pb-4">
                                                                                <VideoPlayer videoURL={bioData.videoURL} />
                                                                            </div> : null}
                                                                    </div>
                                                                    :
                                                                    <p key={ind} className={`p-medium sub-section-title align-left gothamBook lh-26px wp-1px ${`${i.isPadding ? 'pb-4' : ''}`} ${`${i.isItalic ? 'fs-italic' : ''}`} ${isAnimate ? `${classNames}` : ''}`}>{i.params}</p>
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </Col>
                                    {width <= application_contants.MOBILE_BREAKPOINT ?
                                        <Col xs={12} sm={12} md={12} lg={3} xl={3} className={`bg-white py-3 ${isAnimate ? `${classNames}` : ''}`}>
                                            <div className="mx-0">
                                                <div>
                                                    <div className="d-flex align-items-start">
                                                        <div className="item_width_70">
                                                            <div>
                                                                <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex !== -1 ? 'title-black gothamBlack' : 'gothamBook'}`}
                                                                    onClick={() => handleMoreInfo(0)}
                                                                >
                                                                    Srila Prabhupada
                                                                </p>
                                                            </div>

                                                            <div onClick={() => handleMenu(`His Influence`)}>
                                                                <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex === -1 && selectedTitle === 'His Influence' ? 'title-black gothamBlack' : 'gothamBook'}`}
                                                                >
                                                                    His Influence
                                                                </p>
                                                            </div>
                                                            <div onClick={() => handleMenu(`Guru Parampara`)}>
                                                                <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer  ${activeIndex === -1 && selectedTitle === 'Guru Parampara' ? 'title-black gothamBlack' : 'gothamBook'}`}
                                                                >
                                                                    Guru Parampara
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-wrap">
                                                            {item.bio.length > 0 && item.bio.map((it, index) => (
                                                                <div key={index} onClick={() => handleMoreInfo(index)} className={`${isAnimate ? `${classNames}` : ''}  ${index === 0 ? 'dp-none' : ''}`}>
                                                                    <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer 
                                                             ${activeIndex === index ? 'title-black gothamBlack' : 'title-stack-grey gothamBook'}`}
                                                                    >
                                                                        {it.title}
                                                                    </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        : null}
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3} className={`${isAnimate ? `${classNamesLR}` : ''} pt-4`}>
                                        {width <= application_contants.MOBILE_BREAKPOINT ?
                                            <Row className="mx-0 pb-4 pt-4">
                                                {item.moreLinks.length > 0 && item.moreLinks.map((i, ind) => (
                                                    <Col xs={12} sm={12} md={10} lg={3} xl={3} className="mx-0" key={ind}>
                                                        <Row className="mx-0 pb-4 align-items-center">
                                                            <Col xs={4} sm={5} md={5} lg={6} xl={6}>
                                                                <img src={i.img} alt="" className="cursor-pointer" onClick={() => handleNavigation(i.link)} />
                                                            </Col>
                                                            <Col xs={8} sm={7} md={7} lg={6} xl={6}>
                                                                <h3 className={`mb-0 h3-small sub-section-title align-left saolStandardThin pb-2`}>{i.type}</h3>
                                                                <h3 className={`mb-0 h5-small sub-section-title align-left saolStandardMedium pt-2 lh-26px cursor-pointer`} onClick={() => handleNavigation(i.link)}>{i.title}</h3>
                                                                <div className="d-flex align-items-center">
                                                                    <div className={`common-width`}>
                                                                        <img src={application_contants.LINEIMG} alt="" />
                                                                    </div>
                                                                    <div className="common-width">
                                                                        <p className={`p-very-small sub-section-title align-left magdalenaAltRegular`}>
                                                                            {i.date}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                ))}
                                            </Row>
                                            :
                                            <div>
                                                {item.moreLinks.length > 0 && item.moreLinks.map((i, ind) => (
                                                    <Row className={`mx-0 ${ind === 1 ? 'pt-4' : 0}`} key={ind} >
                                                        <Col xs={8} sm={8} md={8} lg={12} xl={12}>
                                                            <h3 className={`mb-0 h3-medium sub-section-title align-left saolStandardThin pb-2`}>{i.type}</h3>
                                                        </Col>
                                                        <Col xs={8} sm={8} md={8} lg={6} xl={5}>
                                                            <div className={`more-link-img__container`}>
                                                                <img src={i.img} alt="" className="cursor-pointer" onClick={() => handleNavigation(i.link)} />
                                                            </div>
                                                        </Col>
                                                        <Col xs={10} sm={10} md={10} lg={10} xl={8}>
                                                            <h3 className={`mb-0 h4-small sub-section-title align-left saolStandardMedium pt-2 lh-26px cursor-pointer`} onClick={() => handleNavigation(i.link)}>{i.title}</h3>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                                                            <div className="d-flex align-items-center">
                                                                <div className={`common-width`}>
                                                                    <img src={application_contants.LINEIMG} alt="" />
                                                                </div>
                                                                <div className="common-width">
                                                                    <p className={`p-very sub-section-title align-left magdalenaAltRegular`}>
                                                                        {i.date}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>}
                                    </Col>
                                </Row>
                            </div> : null}
                    </div>
                    : null}
            </div>
            {width <= application_contants.MOBILE_BREAKPOINT ? null :
                <div>
                    {classNames !== '' ?
                        <div className="d-flex align-items-start justify-content-center py-2">
                            <div>
                                <div onClick={() => handleMoreInfo(0)}>
                                    <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex !== -1 ? 'title-black gothamBlack' : 'gothamBook'}`}
                                    >
                                        Srila Prabhupada
                                    </p>
                                </div>
                                <div onClick={() => handleMenu(`His Influence`)}>
                                    <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-1 cursor-pointer ${activeIndex === -1 && selectedTitle === 'His Influence' ? 'title-black gothamBlack' : 'gothamBook'}`}
                                    >
                                        His Influence
                                    </p>
                                </div>
                                <div onClick={() => handleMenu(`Guru Parampara`)}>
                                    <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-1 cursor-pointer  ${activeIndex === -1 && selectedTitle === 'Guru Parampara' ? 'title-black gothamBlack' : 'gothamBook'}`}
                                    >
                                        Guru Parampara
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex align-items-center justify-content-center">
                                    {item.bio.length > 0 && item.bio.map((it, index) => (
                                        <div key={index} onClick={() => handleMoreInfo(index)} className={`${isAnimate ? `${classNames}` : ''} ${index === 0 ? 'dp-none' : ''}`}>
                                            <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer 
                                          ${activeIndex === index ? 'title-black gothamBook' : 'title-stack-grey gothamBook'}`}
                                            >
                                                {it.title}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div> : null}
                </div>}
            <div>
                <Footer />
            </div>
        </div>
    )
};

export default SrilaprahupadaBioHistory;