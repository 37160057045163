import { useWindowSize } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../../constants/application_contant';
import Header from '../../../customComponents/Header';
import { setCurrentPage } from '../../../redux/actions/currentPage';
import SrilaprabupadaData from '../../../utils/json/srilaprabupadaData';

const Banner = () => {
    const [width] = useWindowSize();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [yearCount, setYearCount] = useState(1896);
    const [classNames, setClassNames] = useState('');

    const { year, signatureImg, flyImg, title, titleParams, subTitle } = SrilaprabupadaData[0];

    React.useEffect(() => {
        let interval;
        setTimeout(() => {
            interval = setInterval(() => {
                if (yearCount < new Date().getFullYear()) {
                    setYearCount(yearCount + 1);
                }
            }, 100);
        }, 2500)

        return () => {
            clearInterval(interval);
        };
    }, [yearCount]);


    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
        }, 300)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 300)
        }
    }, [direction]);

    const handleNavigation = () => {
        dispatch(setCurrentPage(application_contants.SRILAPRABHUPADAHISTORY)); 
    }

    return (
        <div className="content-container position-relative" id="section-1">
            <Header isScroll={false} />
            {classNames !== '' ?
                <Row className="mx-0 pb-5 full-width animate__hover">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="full-width">
                        <div className={`position-relative year-container ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                            <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h1-large' : 'h1-extra-large '} sub-section-title poplarStd title-orinoco-green`}>{yearCount}</h1>
                            <div className="singature--container">
                                <img src={signatureImg} alt="" className="singature-img" />
                            </div>
                        </div>
                        <div className={`fly-brid--container ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                            <img src={flyImg} alt="" className="fly-brids" />
                        </div>
                        <div className={`banner-title--container ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                            <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h1-very-small lh-0px' : 'h1-small lh-48px'} sub-section-title align-left`}>{title}</h1>
                            <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h1-medium-small' : 'h1-medium lh-72px'}  sub-section-title saolStandardMedium align-left`}>{titleParams}</h1>
                            <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h1-very-small' : 'h1-small'} sub-section-title align-right pl-38px lh-0px`}>{subTitle}</h1>
                        </div>
                        <div className={`banner-img-container ${isAnimate ? `${classNames} animate__slow` : ''}`}>
                            <img src={application_contants.PRABHUPADAIMG} alt="" />
                        </div>
                    </Col>
                </Row> : null}
            <div className={`info-container animate__animated  animate__slower`} >
                <div className="scroll-arrow--container hover-one" onClick={() => handleNavigation()}>
                    <img src={application_contants.SCROLLARROWIMG} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Banner;