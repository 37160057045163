import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import Authenticated from "../customComponents/Authenticated/Authenticated";
import PublicRoute from "../customComponents/PublicRoute/PublicRoute";
import BannerBlogImage from "../customPages/Admin/BannerBlogImage/BannerBlogImage";
import BannerBlogVideo from "../customPages/Admin/BannerBlogVideo/BannerBlogVideo";
import Blog from "../customPages/Admin/Blog/Blog";
import BlogSubmissionList from "../customPages/Admin/BlogSubmissionList/BlogSubmissionList";
import Payment from "../customPages/Admin/Payment/Payment";
import SevaList from "../customPages/Admin/SevaList/SevaList";
import SubSevaList from "../customPages/Admin/SubSevaList/SubSevaList";
import Home from "../customPages/Home/Home";
import Login from "../customPages/Login/Login";
import NotFound from "../customPages/NotFound/NotFound";
import { updateUserAuthenticated } from "../redux/actions/userInfo";
import { asyncStoreGet } from "../utils/asyncStorage";
import BlogHome from "../customPages/Blog/Blog";

const RoutesComponent = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { userInfo } = state;
    const [authenticated, setAuthenticated] = useState(false);

    React.useEffect(() => {
        setAuthenticated(userInfo.authenticated)
    }, [authenticated, userInfo]);

    React.useEffect(() => {
        handleAuth();
    }, []);

    const handleAuth = async () => {
        const fullName = await asyncStoreGet('fullName');
        const emailId = await asyncStoreGet('emailId');
        const authenticated = await asyncStoreGet('isLogingin');
        dispatch(updateUserAuthenticated(fullName, emailId, authenticated === null ? false : authenticated));
    }

    return (
        <div>
            <Route exact path={'/'} component={Home} {...props} />
            <Route exact path={'/blogs'} component={BlogHome} {...props} />

            <PublicRoute
                path={'/login'}
                component={Login}
                {...props}
                authenticated={authenticated}
            />
            <Authenticated
                exact
                path={'/payment'}
                component={Payment}
                authenticated={authenticated}
                {...props}
            />
              <Authenticated
                exact
                path={'/seva/seva-list'}
                component={SevaList}
                authenticated={authenticated}
                {...props}
            />
            <Authenticated
                exact
                path={'/seva/sub-seva-list'}
                component={SubSevaList}
                authenticated={authenticated}
                {...props}
            />
               <Authenticated
                exact
                path={'/blog/blog-list'}
                component={Blog}
                authenticated={authenticated}
                {...props}
            />
             <Authenticated
                exact
                path={'/blog/img-list'}
                component={BannerBlogImage}
                authenticated={authenticated}
                {...props}
            />
             <Authenticated
                exact
                path={'/blog/video-list'}
                component={BannerBlogVideo}
                authenticated={authenticated}
                {...props}
            />
             <Authenticated
                exact
                path={'/blog/submission'}
                component={BlogSubmissionList}
                authenticated={authenticated}
                {...props}
            />
             
            {/* <Route component={NotFound} /> */}
        </div>
    );
};

export default RoutesComponent;
