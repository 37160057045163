import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ReactPlayer from 'react-player';
import apiConfig from '../../../api/apis/apiConfig';
import application_contants from '../../constants/application_contant';
import BlogFooter from '../../customComponents/BlogFooter/BlogFooter';
import BlogHeader from '../../customComponents/BlogHeader/BlogHeader';
import { setBlogItem } from '../../redux/actions/blogDetails';
import { setCurrentPage } from '../../redux/actions/currentPage';
import { displayDayAndMonth, displayYear } from '../../utils/displayFormatter';
import MoreLink from '../../utils/json/moreLink';
import { useWindowSize } from '@react-hook/window-size';


const BlogDetails = (props) => {
    const [width] = useWindowSize();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { direction } = state;
    const list = state.blogItem.item;
    const blogList = state.blogList.item.imgList;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__fadeIn` : `animate__animated animate__fadeIn`
            setClassNames(cls);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 100)
        }
    }, [direction]);

    const handleNavigation = (name) => {
        dispatch(setCurrentPage(name));
    }

    const handleNavigationItem = (item) => {
        dispatch(setBlogItem(item));
        window.scrollTo(0, 0);
    }

    const handleBack = () => {
        dispatch(setCurrentPage(application_contants.BLOG));
    }

    return (
        <div className="blog-container">
            <BlogHeader isScroll={false} isBlackLogo={true} isBlackMenu={true} menuClassNames={`title-melanzane-Violet`} />
            <div className="mt-5">
                {list ?
                    <Row className={`mx-0 ${width <= application_contants.MOBILE_BREAKPOINT ? 'pb-4 mt-7rem' : 'pb-5 mt-7rem'}`}>
                        <Col xs={11} sm={11} md={11} lg={10} xl={10} className="m-auto pt-4 px-0">
                            <Row className={`mx-0 align-items-center ${width <= application_contants.MOBILE_BREAKPOINT ? 'fd-cr' : ''}`}>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6} className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'pt-5' : ''}`}>
                                    <div className={`${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                        <img src={`${apiConfig.URL}${list.imgPath}`} alt="" />
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                    <Row className="mx-0">
                                        <Col xs={12} sm={12} md={12} lg={10} xl={9} className="m-auto">
                                            <h2 className={`sub-section-title align-center wp-1px pt-2 pb-2 ${isAnimate ? `${classNames} animate__slower` : ''}`}>{list.title}</h2>
                                            <h6 className={`h6-medium sub-section-title title-stack-grey align-center wp-1px gothamBook pb-4 ${isAnimate ? `${classNames} animate__slower` : ''}`}>{list.shortDescription}</h6>
                                            <div className="blog-divider-line"></div>
                                            <h6 className={`h6-medium sub-section-title align-center wp-1px gothamBook pt-2 pb-3 ${isAnimate ? `${classNames} animate__slower` : ''}`}>{`Ref: ${list.referenceDescription}`}
                                                <span className="pl-10px">{`#${displayDayAndMonth(list.referenceDate)}, ${displayYear(list.referenceDate)}`}</span>
                                            </h6>
                                            <div className="blog-sharing-container">
                                                <div>
                                                    <img src={application_contants.RETWEETSIMG} alt="" className="common-width" />
                                                </div>
                                                <div>
                                                    <img src={application_contants.LIKESIMG} alt="" className="common-width" />
                                                </div>
                                                <div>
                                                    <img src={application_contants.SHAREIMG} alt="" className="common-width" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row> : null}
                {list ?
                    <Row className="mx-0 pb-3">
                        <Col xs={11} sm={11} md={11} lg={{ offset: 2, size: 10 }} xl={{ offset: 3, size: 9 }} className={`pt-4 ${width <= application_contants.MOBILE_BREAKPOINT ? 'm-auto' : ''}`}>
                            <Row className="mx-0 align-items-start">
                                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                    {list.description.split(/[\n\r]/g).length > 0 ?
                                        <div className="blog-params-container">
                                            {list.description.split(/[\n\r]/g).map((params, i) => (
                                                <div>
                                                    {list.description.split(/[\n\r]/g).length / 2 === i ?
                                                        <div className="pb-3">
                                                            {list.referenceImgOrVideo ?
                                                                <div>
                                                                    {list.referenceImgOrVideo.includes('mp4') ?
                                                                        <ReactPlayer
                                                                            className="react-player1"
                                                                            url={`${apiConfig.URL}${list.referenceImgOrVideo}`}
                                                                            width="100%"
                                                                            height="100%"
                                                                            controls={true}
                                                                            volume={0.85}
                                                                            muted={false}
                                                                            config={{
                                                                                youtube: {
                                                                                    playerVars: { showinfo: 1 },
                                                                                },
                                                                                attributes: {
                                                                                    controlsList: "download",
                                                                                },
                                                                            }}
                                                                        />
                                                                        : <img src={`${apiConfig.URL}${list.referenceImgOrVideo}`} alt="" />}
                                                                </div> : null}
                                                        </div>
                                                        : null}
                                                    <p className="p-medium sub-section-title align-left gothamBook pb-4 lh-26px wp-1px" key={i}>{params}</p>
                                                </div>
                                            ))}
                                        </div> :
                                        <div>
                                            {list.referenceImgOrVideo.includes('mp4') ?
                                                <ReactPlayer
                                                    className="react-player1"
                                                    url={`${apiConfig.URL}${list.referenceImgOrVideo}`}
                                                    width="100%"
                                                    height="100%"
                                                    controls={true}
                                                    volume={0.85}
                                                    muted={false}
                                                    config={{
                                                        youtube: {
                                                            playerVars: { showinfo: 1 },
                                                        },
                                                        attributes: {
                                                            controlsList: "download",
                                                        },
                                                    }}
                                                />
                                                : <img src={`${apiConfig.URL}${list.referenceImgOrVideo}`} alt="" />}
                                        </div>}
                                    <div className="pt-4">
                                        {list.referenceDescription ?
                                            <p className={`p-medium-small sub-section-title align-left gothamBook wp-1px pb-3 ${isAnimate ? `${classNames} animate__slower` : ''}`}>Ref: {list.referenceDescription}</p>
                                            : null}
                                    </div>
                                </Col>
                                {width <= application_contants.MOBILE_BREAKPOINT ?
                                    <Col xs={12} sm={12} md={12} lg={4} xl={4} className="pt-4">
                                        <div>
                                            <div className="pb-2">
                                                <p className={`p-medium sub-section-title align-left title-white gothamBook cursor-pointer fw-600`}>
                                                    <font className="bg-light-blue p-1">Also Read</font>
                                                </p>
                                            </div>
                                            {blogList.list.map((it, index) => (
                                                <div className={`pt-1 ${it.title === list.title ? 'dp-none' : ''}`} onClick={() => handleNavigationItem(it)}>
                                                    {it.title !== list.title && index <= 5 ?
                                                        <p className={`p-medium sub-section-title align-left title-light-blue cursor-pointer txt-underline lh-26px`} key={index}>
                                                            {it.title}
                                                        </p>
                                                        : null}
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                    : null}
                                {width <= application_contants.MOBILE_BREAKPOINT ?
                                    <Col xs={12} sm={12} md={12} lg={4} xl={4} className="pt-4">
                                        {MoreLink.length > 0 && MoreLink.map((i, ind) => (
                                            <div>
                                                <Row className={`mx-0 align-items-center pb-3 ${ind === 1 ? 'pt-4' : 0}`} key={ind}>
                                                    <Col xs={4} sm={5} md={5} lg={6} xl={6}>
                                                        <div className={`more-link-img__container`}>
                                                            <img src={i.img} alt="" className="cursor-pointer" onClick={() => handleNavigation(i.link)} />
                                                        </div>
                                                    </Col>
                                                    <Col xs={8} sm={7} md={7} lg={6} xl={6}>
                                                        <h3 className={`mb-0 h3-medium sub-section-title align-left saolStandardThin pb-1`}>{i.type}</h3>
                                                        <h3 className={`mb-0 h4-small sub-section-title align-left saolStandardMedium pt-2 lh-26px cursor-pointer`} onClick={() => handleNavigation(i.link)}>{i.title}</h3>
                                                        <div className="d-flex align-items-center">
                                                            <div className="common-width">
                                                                <img src={application_contants.LINEIMG} alt="" />
                                                            </div>
                                                            <div className="common-width">
                                                                <p className={`p-very sub-section-title align-left magdalenaAltRegular`}>
                                                                    {i.date}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                        ))}
                                        <div className="mt-3 mb-3 blog-book-container">
                                            <img src={application_contants.BLOGBOOKIMG} alt="" />
                                        </div>
                                    </Col>
                                    :
                                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                        {MoreLink.length > 0 && MoreLink.map((i, ind) => (
                                            <div>
                                                {ind === 1 ?
                                                    <div className="mt-3 blog-book-container">
                                                        <img src={application_contants.BLOGBOOKIMG} alt="" />
                                                    </div> : null}
                                                <Row className={`mx-0 ${ind === 1 ? 'pt-4' : 0}`} key={ind}>
                                                    <Col xs={8} sm={8} md={8} lg={12} xl={12}>
                                                        <h3 className={`mb-0 h3-medium sub-section-title align-left saolStandardThin pb-2`}>{i.type}</h3>
                                                    </Col>
                                                    <Col xs={8} sm={8} md={8} lg={6} xl={5}>
                                                        <div className={`more-link-img__container`}>
                                                            <img src={i.img} alt="" className="cursor-pointer" onClick={() => handleNavigation(i.link)} />
                                                        </div>
                                                    </Col>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={8}>
                                                        <h3 className={`mb-0 h4-small sub-section-title align-left saolStandardMedium pt-2 lh-26px cursor-pointer`} onClick={() => handleNavigation(i.link)}>{i.title}</h3>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="common-width">
                                                                <img src={application_contants.LINEIMG} alt="" />
                                                            </div>
                                                            <div className="common-width">
                                                                <p className={`p-very sub-section-title align-left magdalenaAltRegular`}>
                                                                    {i.date}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row> : null}
                {blogList.list.length > 0 ?
                    <Row className="mx-0 pb-5 pt-3">
                        <Col xs={11} sm={11} md={11} lg={{ offset: 2, size: 10 }} xl={{ offset: 3, size: 9 }} className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'm-auto' : ''}`}>
                            {width <= application_contants.MOBILE_BREAKPOINT ? null :
                                <div className="d-flex align-items-center flex-wrap">
                                    <div>
                                        <p className={`p-very-small sub-section-title align-left title-white gothamBook cursor-pointer bg-light-blue fw-600 p-1`}>
                                            Also Read
                                        </p>
                                    </div>
                                    {blogList.list.map((it, index) => (
                                        <div className={`blog-list-more-item ${it.title === list.title ? 'dp-none' : ''}`} onClick={() => handleNavigationItem(it)}>
                                            {it.title !== list.title && index <= 5 ?
                                                <p className={`p-medium sub-section-title align-left title-light-blue gothamBook cursor-pointer lh-26px`} key={index}>
                                                    {it.title}
                                                    {index === blogList.list.length - 1 ? null :
                                                        <span className="pl-10px">|</span>
                                                    }
                                                </p>
                                                : null}
                                        </div>
                                    ))}
                                </div>}
                        </Col>
                    </Row>
                    : null}
            </div>
            <BlogFooter />
            <div className="donate-go-back-container" onClick={() => handleBack()}>
                <p className="p-small sub-section-title saolStandardMedium align-left fw-600 cursor-pointer">GO BACK</p>
            </div>
        </div>
    )
}

export default BlogDetails;