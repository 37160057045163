export const ActionTypes = {
    SET_DIRECTION: 'SET_DIRECTION',
    SET_CURRENTPOSITION: 'SET_CURRENTPOSITION',
    SET_PLAY: "SET_PLAY",
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    UPDATE_SEVA: 'UPDATE_SEVA',
    FETCH_SEVA: 'FETCH_SEVA',
    USER_INFO: 'USER_INFO',
    USER_AUTHENTICATED: 'USER_AUTHENTICATED',
    LOGIN_OUT: 'LOGOUT_OUT',
    SET_BLOG_ITEM: 'BLOG_ITEM',
    FETCH_BLOG: 'FETCH_BLOG',
}