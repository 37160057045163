import apiConfig from "../apis/apiConfig";
import { Fetch } from "../apis/Fetch";


const UserService = {

    login: async (emailId, password) => {
        debugger;
        const { BASEURL, LOGIN } = apiConfig;

        const url = BASEURL + LOGIN;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            emailId: `${emailId}`,
            password: `${password}`,
        });

        const loginServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_login",
            true
        );
        return loginServicePromise;
    },

    insertOrUpdateUser: async (data) => {
        const { BASEURL, INSERT_OR_UPDATE_USER } = apiConfig;

        const url = BASEURL + INSERT_OR_UPDATE_USER;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            fullName: `${data.fullName}`,
            emailId: `${data.emailId}`,
            password: `${data.password}`,
            mobileNo: `${data.mobileNo}`,
            gender: data.gender,
            status: data.status,
        });

        const insertOrUpdateUserServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_insertOrUpdateUser",
            true
        );
        return insertOrUpdateUserServicePromise;
    },

};

export default UserService;
