import React, { useState } from 'react';
import Axios from "axios";
import { Row, Col, Input, Label, FormGroup, Button } from 'reactstrap';
import apiConfig from '../../../api/apis/apiConfig';
import BlogService from '../../../api/services/BlogService';
import application_contants from '../../constants/application_contant';
import FileUploader from '../FileUploader/FileUploader';
import Loader from '../Loader/Loader';
import { useWindowSize } from '@react-hook/window-size';

const SubmissionsGuidelinesForm = (props) => {
    const [width] = useWindowSize();
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [articalName, setArticalName] = useState('');
    const [fileThumbnailFileName, setFileThumbnailFileName] = useState('');
    const [fileThumbnailFile, setFileThumbnailFile] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);

    const getDocFileThumbnail = async (item) => {
        await setFileThumbnailFile(item[0].file);
        await setFileThumbnailFileName(item[0].file.name);
        setIsFileChanged(true);
    }

    const handleFirstName = (event) => {
        setFirstName(event.target.value);
    }

    const handleEmailId = (event) => {
        setEmailId(event.target.value);
    }

    const handleArticalName = (event) => {
        setArticalName(event.target.value);
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const data = {
            firstName: firstName,
            emailId: emailId,
            articalName: articalName,
            filePath: isFileChanged ? `/blog-assets/img/${fileThumbnailFileName}` : fileThumbnailFileName,
            isApproval: 0,
            id: '',
        };
        let files = [];
        files.push(fileThumbnailFile);
        await UploadDocFile(files, data)
    }

    const UploadDocFile = async (files, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/img/");
        await files.map((item) => {
            data.append("image", item);
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        // console.log('photos : ', data);
        Axios.post(`${apiConfig.BASEURL}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alert(`${res.message}`)
                            // alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };


    const insertOrUpdate = async (data) => {
        await BlogService.insertOrUpdateBlogSubmission(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alert(`${result.message}`);
                                // alertSuccessMessage(`${result.message}`);
                                setIsLoading(false);
                                setFirstName('');
                                setEmailId('');
                                setArticalName('');
                                setFileThumbnailFile('');
                                setFileThumbnailFileName('');
                                props.handleRefresh();

                            } else {
                                setIsLoading(false);
                                alert(`${result.message}`)
                                //alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }


    return (
        <Row className="mx-0 py-5">
            <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto submit-form-container">
                <Row className="mx-0 bg-light-blue align-items-center">
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <div className="submit-form--img-container">
                            <img src={application_contants.FBLOGLOGO} alt="" />
                        </div>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div className="submit-form--img-container">
                            <img src={application_contants.FBLOGIMG} alt="" />
                        </div>
                    </Col>
                </Row>
                <Row className="mx-0 py-5">
                    <Col xs={12} sm={12} md={11} lg={10} xl={10} className="m-auto">
                        <Row className="mx-0">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormGroup className="form-group-large">
                                    <Label className="form-common-label text-left">
                                        First Name* :
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        id="FirstName"
                                        name="FirstName"
                                        className="form-control common-form-control--large"
                                        value={firstName}
                                        onChange={event => handleFirstName(event)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormGroup className="form-group-large">
                                    <Label className="form-common-label text-left">
                                        Email Address* :
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        id="EmailAddress"
                                        name="EmailAddress"
                                        className="form-control common-form-control--large"
                                        value={emailId}
                                        onChange={event => handleEmailId(event)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormGroup className="form-group-large">
                                    <Label className="form-common-label text-left">
                                        Artical Name* :
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        id="ArticalName"
                                        name="ArticalName"
                                        className="form-control common-form-control--large"
                                        value={articalName}
                                        onChange={event => handleArticalName(event)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={width <= 1024 ? 12 : 4} xl={4}>
                                <FormGroup className="form-group-large">
                                    <Label className="form-common-label text-left">
                                        Upload* :
                                    </Label>
                                    <FileUploader
                                        isDoc={true}
                                        isLargeForm={true}
                                        getDocFile={(item) =>
                                            getDocFileThumbnail(item)
                                        }
                                        value={fileThumbnailFileName}
                                        labelName="Upload Thumbnail File"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 pt-5">
                                {!isLoading ?
                                    <Row className="mx-0">
                                        <Col xs={12} sm={12} md={12} lg={width <= 1024 ? 6 : 4} xl={4} className="ml-auto">
                                            <FormGroup className="form-group-large">
                                                <Button className="custom-blue-btn btn-block custom-btn-extra-large"
                                                    disabled={firstName && emailId && articalName && fileThumbnailFileName ? false : true}
                                                    onClick={() => handleSubmit()}>
                                                    SUBMIT
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row> : null}
                                {isLoading ? (
                                    <div className="d-flex align-item-center justify-content-center">
                                        <Loader containerClassName="" />
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default SubmissionsGuidelinesForm;