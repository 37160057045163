import moment from "moment";


export const getDays = (startDate, endDate) => {
    var end = moment(endDate, "MM-DD-YYYY");
    var start = moment(new Date(startDate)).startOf('day');
    return moment.duration(end.diff(start)).asDays();
}

export const displayMonthNameWithDate = (date) => {
    return moment(date).format("DD MMM YYYY");
  };

  export const displayYYMMDD = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };
  
  export const displayMMDDYY = (date) => {
    debugger;
    return moment(new Date(date), "MM/DD/YYYY").format("MM/DD/YYYY");
  };

  export const displayDayAndMonth = (date) => {
    return moment(date).format("DD-MM");
  };

  export const displayYear= (date) => {
    return moment(date).format("YYYY");
  };