import apiConfig from "../apis/apiConfig";
import { Fetch } from "../apis/Fetch";


const SevaService = {

    getSevaList: async (status) => {
        const { BASEURL, GET_SEVA_LIST } = apiConfig;

        const url = BASEURL + GET_SEVA_LIST + `?status=${status}`;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const getSevaListServicePromise = await Fetch(
            url,
            "GET",
            headers,
            "",
            "services_getSevaList",
            true
        );
        return getSevaListServicePromise;
    },


    getSubSevaList: async () => {
        const { BASEURL, GET_SUB_SEVA_LIST } = apiConfig;

        const url = BASEURL + GET_SUB_SEVA_LIST;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const getSubSevaListServicePromise = await Fetch(
            url,
            "GET",
            headers,
            "",
            "services_getSubSevaList",
            true
        );
        return getSubSevaListServicePromise;
    },

    insertOrUpdateSeva: async (data) => {
        const { BASEURL, INSERT_OR_UPDATE_SEVA } = apiConfig;

        const url = BASEURL + INSERT_OR_UPDATE_SEVA;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        
        const params = JSON.stringify({
            userId: data.userId,
            id: data.id,
            sevaType: `${data.sevaType}`,
            sevaTypeTitle: data.sevaTypeTitle,
            description: `${data.description}`,
            sectionTitle: `${data.sectionTitle}`,
            sectionDescription: `${data.sectionDescription}`,
            totalAmount: data.totalAmount,
            isPaymentSchedule: data.isPaymentSchedule,
            moreInfo: `${data.moreInfo}`,
            subSevaId: `${data.subSevaId}`,
            imgPath: `${data.imgPath}`,
            startDate: data.startDate,
            endDate: data.endDate,
            status: data.status,
        });


        const insertOrUpdateSevaServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_insertORUpdateSeva",
            true
        );
        return insertOrUpdateSevaServicePromise;
    },

    insertOrUpdateSubSeva: async (data) => {
        const { BASEURL, INSERT_OR_UPDATE_SUBSEVA } = apiConfig;

        const url = BASEURL + INSERT_OR_UPDATE_SUBSEVA;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });
        
        const params = JSON.stringify({
            userId: data.userId,
            subSevaId: data.id,
            sevaType: `${data.sevaType}`,
            sevaTypeTitle: data.sevaTypeTitle,
            description: `${data.description}`,
            sectionTitle: `${data.sectionTitle}`,
            sectionDescription: `${data.sectionDescription}`,
            totalAmount: data.totalAmount,
            isPaymentSchedule: data.isPaymentSchedule,
            moreInfo: `${data.moreInfo}`,
            imgPath: `${data.imgPath}`,
            startDate: data.startDate,
            endDate: data.endDate,
            status: data.status,
        });


        const insertOrUpdateSubSevaServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_insertORUpdateSubSeva",
            true
        );
        return insertOrUpdateSubSevaServicePromise;
    },

};

export default SevaService;
