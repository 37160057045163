import application_contants from "../../constants/application_contant";

const GuruParamparaData = [
    {
        imgPath: application_contants.GURUIMG5,
        name: 'Lord Krishna'
    },
    {
        imgPath: application_contants.GURUIMG6,
        name: 'Lord Brahma'
    },
    {
        imgPath: application_contants.GURUIMG7,
        name: 'Sri Narada Muni'
    },
    {
        imgPath: application_contants.GURUIMG8,
        name: 'Sri Vedavyasa Muni'
    },
    {
        imgPath: application_contants.GURUIMG9,
        name: 'Srila Madhvacharya'
    },
    {
        imgPath: application_contants.GURUIMG10,
        name: 'Sri Padmanabha Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG11,
        name: 'Sri Narahari Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG12,
        name: 'Sri Madhava Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG13,
        name: 'Sri Aksobhya Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG14,
        name: 'Sri Jaya Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG15,
        name: 'Sri Jnanasindhu Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG16,
        name: 'Sri Dayanidhi Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG17,
        name: 'Sri Vidyanidhi Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG18,
        name: 'Sri Rajendra Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG19,
        name: 'Sri Jayadharma Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG20,
        name: 'Sri Purushottama Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG21,
        name: 'Sri Brahmanya Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG22,
        name: 'Sri Vyasa Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG23,
        name: 'Sri Laksmipati Tirtha'
    },
    {
        imgPath: application_contants.GURUIMG24,
        name: 'Sri Madhavendra Puri'
    },
    {
        imgPath: application_contants.GURUIMG25,
        name: 'Sri Isvara Puri'
    },
    {
        imgPath: application_contants.GURUIMG26,
        name: 'Lord Sri Chaitanya Mahaprabhu'
    },
    {
        imgPath: application_contants.GURUIMG27,
        name: 'Srila Rupa Goswami'
    },
    {
        imgPath: application_contants.GURUIMG28,
        name: 'Srila Raghunatha Dasa Goswami'
    },
    {
        imgPath: application_contants.GURUIMG29,
        name: 'Srila Krishnadasa Kaviraja Goswami'
    },
    {
        imgPath: application_contants.GURUIMG30,
        name: 'Srila Narottama Dasa Thakura'
    },
    {
        imgPath: application_contants.GURUIMG31,
        name: 'Srila Visvanatha Chakravathi Thakura'
    },
    {
        imgPath: application_contants.GURUIMG32,
        name: 'Srila Jagannatha Dasa Babaji Maharaj'
    },
    {
        imgPath: application_contants.GURUIMG33,
        name: 'Srila Bhaktivinoda Thakura'
    },
    {
        imgPath: application_contants.GURUIMG34,
        name: 'Srila Gaurakisora Dasa Babaji Maharaj'
    },
    {
        imgPath: application_contants.GURUIMG35,
        name: 'Srila Bhaktisiddhanta Sarasvati Thakura'
    },
    {
        imgPath: application_contants.GURUIMG36,
        name: 'Srila Bhaktivedanta Swami Prabhupada'
    }
];

export default GuruParamparaData;