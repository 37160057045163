import React, { useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import PaymentService from '../../../api/services/PaymentService';
import application_contants from '../../constants/application_contant';
import Header from '../../customComponents/Header';
import PaymentForm from '../../customComponents/PaymentForm/PaymentForm';
import { setCurrentPage } from '../../redux/actions/currentPage';
import PaymentMethodType from '../../utils/json/PaymentMethodType';
import apiConfig from '../../../api/apis/apiConfig';

const SevaDonate = () => {
    const onlyWidth = useWindowWidth()
    const state = useSelector(state => state);
    const { direction } = state;
    const { item } = state.sevaItem;
    const dispatch = useDispatch();
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [isDonate, setIsDonate] = useState(false);
    const [donateType, setDonateType] = useState('');
    const [currencyType, setCurrencyType] = useState('');
    const [totalDonateAmount, setTotalDonateAmount] = useState(0)

    React.useEffect(() => {
        getSevaTotalAmount();
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn animate__slow` : `animate__animated animate__zoomIn animate__slow`
            setClassNames(cls);
        }, 500)
        return () => {

            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 200)
        }
    }, [direction]);

    const getSevaTotalAmount = async () => {
        if (item.totalAmount > 0) {
            await PaymentService.getSevaTotalAmount(item.id, item.subSevaId ? item.subSevaId : -1)
                .then(async (res) => {
                    if (res.status === 200) {
                        try {
                            res.text().then(async (res) => {
                                let result = JSON.parse(res);
                                if (result.code === 0) {
                                    setTotalDonateAmount(result.data.totalAmount)
                                } else {
                                    console.log(`${result.message}`);
                                }
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const loadRazorpay = (URL) => {
        return new Promise((resolve => {
            const script = document.createElement('script');
            script.src = URL
            document.body.appendChild(script);
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
        }))

    }

    const handlePaymentMethod = async (event, data) => {
        const res = await loadRazorpay(`https://checkout.razorpay.com/v1/checkout.js`);
        if (!res) {
            alert('no')
            return
        }
        const options = {
            // Test server Enter the Key ID generated from the Dashboard
            //key: application_contants.DOMESTIC === donateType ? 'rzp_test_ti5ZvPtzdpkVRv' : 'rzp_test_6sXn3WuLblht8k', 

            //Production server Key
            key: application_contants.DOMESTIC === donateType ? 'rzp_live_09DrlDutjyBJDQ' : 'rzp_live_rcDlSsXKbjc8dd', // Enter the Key ID generated from the Dashboard
            
            amount: `${data.amount}00`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: currencyType,
            name: `${item.sevaType} ${item.sevaTypeTitle}`,
            description: "Thanks you",
           /// image: `${`${apiConfig.URL}/logo192.png`}`,
            // order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: async function (response) {
                console.log(response);
                alert(response.razorpay_payment_id);
                await insertPayment(response.razorpay_payment_id, data, 1);
            },
            prefill: {
                name: data.fullName,
                email: data.emailId,
                contact: data.mobile,
                //address: data.address,
            },
            notes: {
                address: "Razorpay Corporate Office"
            },
            theme: {
                color: "#D7D8B3"
            }
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', async function (response) {
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.payment_id);
            console.log(response.error);
            await insertPayment(response.error.metadata.payment_id, data, 0);
        });
        rzp1.open();

    }

    const handleDonateMethod = (item) => {
        setIsDonate(!isDonate);
        setDonateType(item.type);
        setCurrencyType(item.currency)
    }

    const handleModal = () => {
        setIsDonate(!isDonate);
    }

    const insertPayment = async (paymentId, details, status) => {
        const data = {
            paymentId: paymentId,
            fullName: `${details.fullName}`,
            emailId: `${details.emailId}`,
            donateAmount: details.amount,
            mobile: `${details.mobile}`,
            address: `${details.address}`,
            paymentSchedule: item.isPaymentSchedule ? details.paymentSchedule : -1,
            currencyType: currencyType,
            paymentStatus: status,
            sevaId: item.id,
            subSevaId: item.subSevaId ? item.subSevaId : -1,
        }
        await PaymentService.insertPayment(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                console.log(`${result.message}`);
                                if (status !== 0) {
                                    dispatch(setCurrentPage(application_contants.NAMATHE));
                                }
                            } else {
                                console.log(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleBack =() => {
        dispatch(setCurrentPage(application_contants.SEVAINFO));  
    }

    return (
        <div className={`seva-donate-content-container ${isDonate ? 'full-width' : ''} ${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pt-4rem' : ''}`}>
            <Header isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} menuClassNames={'title-melanzane-Violet'} isScroll={false} isFooter={false} bgClassName={`bg-narvik-green`}/>
            {!isDonate && classNames !== '' ?
                <Row className={`mx-0 align-items-center ${isDonate ? 'full-width' : ''} ${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'py-5' : ''}`}>
                    <Col xs={11} sm={11} md={10} lg={onlyWidth === 1270 ? 10 : onlyWidth <= 1024 ? 10 :8} xl={onlyWidth === 1270 ? 10 : 5} className="m-auto">
                        <div className="pb-3">
                            <Row className="mx-0">
                                <Col xs={12} sm={12} md={10} lg={8} xl={9} className={`m-auto ${isAnimate ? `${classNames}` : ''}`}>
                                    <div className="position-relative">
                                        <img src={application_contants.SEVAINFOASSETS4} alt="" />
                                        <div className="seva-donate-title--position">
                                            <div>
                                                <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'p-smallest' : 'p-small'} sub-section-title gothamBook fw-600 title-white text-uppercase`}>{item.sevaType}</p>
                                                <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'p-smallest' : 'p-small'} sub-section-title gothamBook fw-600 title-white text-uppercase`}>{item.sevaTypeTitle}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <h3 className={`h3-small sub-section-title magdalenaAltMedium title-danger-light ${isAnimate ? `${classNames}` : ''}`}>SELECT YOUR COUNTRY</h3>
                        <Row className="mx-0 pt-5">
                            <Col xs={12} sm={12} md={8} lg={6} xl={6} className={`${isAnimate ? `${classNames}` : ''} m-auto`}>
                                <div className="position-relative hover-one">
                                    <img src={application_contants.INDIACOUNTRYIMG} alt="" />
                                    <div className="save-donate-title--container" onClick={() => handleDonateMethod(PaymentMethodType[0])}>
                                        <h1 className="h1-small sub-section-title saolStandardMedium title-blue-light text-uppercase">India</h1>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6} className={`${isAnimate ? `${classNames}` : ''} m-auto`}>
                                <div className="position-relative hover-one">
                                    <img src={application_contants.OTHERCOUNTRYIMG} alt="" />
                                    <div className="save-donate-title--container" onClick={() => handleDonateMethod(PaymentMethodType[1])}>
                                        <h1 className="h1-small sub-section-title saolStandardMedium title-blue-light text-uppercase">All Others</h1>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                : null}
            {!isDonate ? null :
                <Row className={`align-items-center mx-0  ${isAnimate ? `${classNames}` : ''} ${isDonate ? 'full-width' : ''}`}>
                    <Col xs={10} sm={10} md={10} lg={onlyWidth === 1270 ? 9 : onlyWidth <= 1024 ? 10 : 6} xl={onlyWidth === 1270 ? 9 : 6} className="m-auto seva-form-container pt-3">
                        <p className="p-medium sub-section-title gothamBook title-black align-left p-3">
                            Purpose of Payment: <font className="text-uppercase gothamBlack title-blue-light">{item.sevaType} {item.sevaTypeTitle}</font>
                        </p>
                        <PaymentForm handleFormData={(event, data) => handlePaymentMethod(event, data)} handleModal={() => handleModal()} isPaymentSchedule={item.isPaymentSchedule} totalAmount={item.totalAmount} totalDonateAmount={totalDonateAmount} startDate={item.startDate} endDate={item.endDate} />
                    </Col>
                </Row>
            }
            <div className="donate-go-back-container" onClick={() => handleBack()}>
                <p className="p-small sub-section-title saolStandardMedium align-left fw-600 cursor-pointer">GO BACK</p>
            </div>
        </div>
    )
};


export default SevaDonate