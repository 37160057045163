import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import DamodarTempleData from '../../utils/json/damodarTempleData';


const DamodarTemple = () => {
    const [width] = useWindowSize();
    const state = useSelector(state => state);
    const { direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');


    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 100)
        }

    }, [direction]);

    return (
        <div className="damodar-content-container position-relative">
            {classNames !== '' ?
                <Row className="mx-0 align-items-center">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto">
                        <Row className="mx-0">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                {DamodarTempleData.length > 0 && DamodarTempleData.map((it, index) => (
                                    <Row className="mx-0" key={index}>
                                        <Col xs={12} sm={12} md={10} lg={8} xl={8} className="m-auto py-5">
                                            <div className={`${isAnimate ? `${classNames} animate__slower` : ''} `}>
                                                <img src={it.imgPath} alt="" />
                                            </div>
                                            <div className={`${isAnimate ? `${classNames} animate__slower` : ''} py-4`}>
                                                <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h3-small' : 'h1-small lh-48px'} sub-section-title align-left pb-2 pl-38px`}>{it.preTitle}</h1>
                                                <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h2-small-large align-center' : 'h1-medium lh-72px'} sub-section-title saolStandardMedium align-left`}>{it.title}</h1>
                                                <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h3-small' : 'h1-small pr-64px'} sub-section-title align-right lh-0px`}>{it.postTitle}</h1>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto">
                                            <Row className="mx-0 pb-5">
                                                {it.bio.length > 0 && it.bio.map((bioitem, ind) => (
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        {ind === 5 ?
                                                            <div className={`position-relative ${width <= application_contants.MOBILE_BREAKPOINT ? 'pt-2' : 'pt-3'}`}>
                                                                <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'full-width pb-3' : 'width_75 pb-5'} d-flex`}>
                                                                    <div className="px-2">
                                                                        <img src={application_contants.DAMODARBG} alt="" />
                                                                    </div>
                                                                    <div className="px-2">
                                                                        <img src={application_contants.DAMODARBG} alt="" />
                                                                    </div>
                                                                    <div className="px-2">
                                                                        <img src={application_contants.DAMODARBG} alt="" />
                                                                    </div>
                                                                    <div className="px-2">
                                                                        <img src={application_contants.DAMODARBG} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="temple-img--container">
                                                                    <img src={it.sectionImg} alt="" />
                                                                </div>
                                                                <div className="pt-5">
                                                                    <p key={ind} className={`p-medium sub-section-title align-left lh-26px gothamBook pb-4 wp-1px ${width <= application_contants.MOBILE_BREAKPOINT ? 'pt-3' : 'pt-5'} ${isAnimate ? `${classNames}` : ''}`}>{bioitem.description}</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <p key={ind} className={`p-medium sub-section-title align-left lh-26px gothamBook pb-4 wp-1px ${isAnimate ? `${classNames}` : ''}`}>{bioitem.description}</p>
                                                        }
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                : null}
        </div>
    )
}

export default DamodarTemple;