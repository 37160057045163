import { useWindowSize } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import apiConfig from '../../../api/apis/apiConfig';
import BlogService from '../../../api/services/BlogService';
import application_contants from '../../constants/application_contant';
import BlogFooter from '../../customComponents/BlogFooter/BlogFooter';
import BlogHeader from '../../customComponents/BlogHeader/BlogHeader';
import Loader from '../../customComponents/Loader/Loader';
import VideoPlayerGrid from '../../customComponents/VideoPlayerGrid/VideoPlayerGrid';
import { FetchBlogItem, setBlogItem } from '../../redux/actions/blogDetails';
import { setCurrentPage } from '../../redux/actions/currentPage';
import { displayDayAndMonth, displayMonthNameWithDate, displayYear } from '../../utils/displayFormatter';


const Blog = (props) => {
    const [width] = useWindowSize();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const state = useSelector(state => state);
    const { direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [videoList, setVideoList] = useState([]);
    const [blogList, setBlogList] = useState([]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(!isLoading);
        getBlogImgList();
        setIsLoading(!isLoading);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__fadeIn` : `animate__animated animate__fadeIn`
            setClassNames(cls);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 100)
        }
    }, [direction]);


    const getBlogImgList = async () => {
        setIsLoading(true);
        await BlogService.getBlogInfo()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                setIsLoading(false);
                                if (result.data.videoList) {
                                    setVideoList(result.data.videoList.list.length > 0 ? result.data.videoList.list : [])
                                }
                                if (result.data.imgList) {
                                    setBlogList(result.data.imgList.list.length > 0 ? result.data.imgList.list : [])
                                }
                                dispatch(FetchBlogItem(result.data));
                            } else {
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });

    }

    const handleNavigation = (item) => {
        dispatch(setBlogItem(item))
        dispatch(setCurrentPage(application_contants.BLOGDETAILSITEM));
    }


    return (
        <div className="blog-container">
            <BlogHeader isScroll={false} isBlackLogo={true} isBlackMenu={true} menuClassNames={`title-melanzane-Violet`} />
             <div className={`pb-5 ${width <= application_contants.MOBILE_BREAKPOINT ? '' : 'mt-4'}`}>
                <Row className={`mx-0 pb-5 ${width <= application_contants.MOBILE_BREAKPOINT ? 'mt-7rem' : 'mt-7rem'}`}>
                    {!isLoading ?
                        <Col xs={12} sm={12} md={12} lg={10} xl={9} className={`m-auto ${width <= application_contants.MOBILE_BREAKPOINT ? 'px-0' : ''}`}>
                            <VideoPlayerGrid videoList={videoList} isPadding={false} />
                        </Col> : null}
                </Row>
                <Row className={`mx-0 ${width <= application_contants.MOBILE_BREAKPOINT ? '' : 'pt-5'}`}>
                    <Col xs={12} sm={12} md={12} lg={{ offset: 4, size: 8 }} xl={{ offset: 4, size: 8 }} className={`m-auto px-0 ${width <= application_contants.MOBILE_BREAKPOINT ? '' : 'pt-5'}`}>
                        <Row className="mx-0">
                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                {blogList.length > 0 ?
                                    <Row className="mx-0">
                                        {width <= application_contants.MOBILE_BREAKPOINT || width <= 1024 ? null
                                            :
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="position-relative hover-one" onClick={() => handleNavigation(blogList[0])}>
                                                <img src={`${apiConfig.URL}${blogList[0].imgPath}`} alt="" />
                                                <div className="blog-img--title-container">
                                                    <h3 className="sub-section-title align-left title-white-smoke"><span>{blogList[0].title}</span></h3>
                                                    <p className="p-small sub-section-title align-left gothamBook title-white-smoke pl-2 pt-3">{blogList[0].reference}
                                                        <span>{`#${displayDayAndMonth(blogList[0].referenceDate)},${displayYear(blogList[0].referenceDate)}`}</span>
                                                    </p>
                                                </div>
                                            </Col>}
                                    </Row> : null}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={width <= 1024 ? 12 : 6} xl={6}>
                                {blogList.length > 0 ?
                                    <Row className="mx-0">
                                        {blogList.length > 0 && blogList.map((it, index) => (
                                            <Col xs={12} sm={12} md={12} lg={width <= 1024 ? 12 : 6} xl={6} key={index} className={`hover-one cursor-pointer ${width <= application_contants.MOBILE_BREAKPOINT || width <= 1024 ? 'd-flex align-items-center py-4 br-bt-4px' : `${index === 0 ? 'dp-none' : ''} pb-5`}`} onClick={() => handleNavigation(it)}>
                                                <div className={`${isAnimate ? `${classNames} animate__slower` : ''} ${width <= application_contants.MOBILE_BREAKPOINT || width <= 1024 ? 'width_40 pd_8px' : ''}`}>
                                                    <img src={`${apiConfig.URL}${it.imgPath}`} alt="" />
                                                </div>
                                                <div className={`${width <= application_contants.MOBILE_BREAKPOINT || width <= 1024 ? 'width_60 pd_8px pr-0' : ''}`}>
                                                    <p className={`p-small sub-section-title align-left wp-1px magdalenaAltMedium pt-2 pb-2 ${isAnimate ? `${classNames} animate__slower` : ''}`}>{it.title}</p>
                                                    <p className={`p-very-small sub-section-title align-left wp-1px gothamBook pb-3 ${isAnimate ? `${classNames} animate__slower` : ''}`}>{it.shortDescription}</p>
                                                    {it.referenceDescription ?
                                                        <p className={`p-very sub-section-title align-left gothamBook wp-1px pb-3 ${isAnimate ? `${classNames} animate__slower` : ''}`}>Ref: {it.referenceDescription}</p>
                                                        : null}
                                                    <div className="d-flex align-items-center">
                                                        <div className="line-container">
                                                            <p className={`p-very sub-section-title align-right gothamBook wp-1px date`}>{displayMonthNameWithDate(it.referenceDate)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                    : null}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div> 
            {isLoading ?
                <div className="py-5 height_85">
                    <Loader containerClassName={`loader-position`}/>
                </div> : null}
            <div className="pt-5">
                <BlogFooter />
            </div>
        </div>
    )
}

export default Blog;