import React, { useState } from "react"
import ReactPlayer from "react-player"
import application_contants from "../../../constants/application_contant";
import Header from "../../../customComponents/Header"

const VideoPlayer = (props) => {
    const [isVideoPlay, setIsVideoPlay] = useState(false);

    return (
        <div className="position-relative">
            <div>
                <ReactPlayer
                    className="react-player1"
                    url={props.videoURL}
                    width="100%"
                    height="100%"
                    playing={isVideoPlay}
                    controls={isVideoPlay}
                    volume={0.8}
                    muted={false}
                    onEnded={() => setIsVideoPlay(false)}
                    config={{
                        youtube: {
                            playerVars: { showinfo: 1 },
                        },
                        attributes: {
                            controlsList: "download",
                            // poster: `${direction && direction.direction === application_contants.FORWARD ? item.posterImg : item.posterImg}`,
                        },
                    }}
                />
            </div>
            {!isVideoPlay ?
                <div className="play-icon--container">
                    <img src={application_contants.PLAYICONIMG} alt="" onClick={() => setIsVideoPlay(!isVideoPlay)} />
                </div> : null}
        </div>
    )
}

export default VideoPlayer;