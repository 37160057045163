const dataTablesStyledTheme = {
    table: {
        style: {
            border: "1px solid #dddddd",
        },
    },
    rows: {
        style: {
            fontSize: "0.75rem",
            minHeight: "2.25rem",
            fontFamily: "Gotham-Book",
            "&:hover": {
                color: "#fd4b9a",
                cursor: "pointer",
            },
        },
        stripedStyle: {
            "&:nth-child(odd)": {
                color: "#000",
                backgroundColor: "#ddd",
            },
        },
    },
    headRow: {
        style: {
            borderTopStyle: "solid",
            borderTopWidth: "0px",
            borderTopColor: "transparent",
            minHeight: "2.5rem",
        },
    },
    headCells: {
        style: {
            paddingLeft: "0.75rem", // override the cell padding for head cells
            paddingRight: "0.75rem",
            fontSize: "0.85rem",
            backgroundColor: "#3d505c",
            color: "#fff",
            minHeight: "2rem",
            fontWeight: 800,
            fontFamily: "Gotham-Book",
            "&:not(:last-of-type)": {
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderRightColor: "#dddddd",
            },
        },
    },
    cells: {
        style: {
            paddingLeft: "0.75rem", // override the cell padding for data cells
            paddingRight: "0.75rem",
            fontSize: "0.75rem",
            "&:hover": {
                cursor: "pointer",
                color: '#fd4b9a',
            },
            "&:not(:last-of-type)": {
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderRightColor: "#dddddd",
            },
        },
    },
    pagination: {
        style: {
            color: "#3d505c",
            fontSize: "0.75rem",
            backgroundColor: "tranparent",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            fontFamily: "RobotoSlab-Blod",
            borderTopColor: "transparent",
        },
    },
};

const dataTablesMobileStyledTheme = {
    table: {
        style: {
            border: "1px solid #dddddd",
        },
    },
    rows: {
        style: {
            fontSize: "0.85rem",
            fontFamily: "Gotham-Book",
            minHeight: "3rem",
            "&:hover": {
                color: "#fd4b9a",
                cursor: "pointer",
            },
        },
        stripedStyle: {
            "&:nth-child(odd)": {
                color: "#000",
                backgroundColor: "#ddd",
            },
        },
    },
    headRow: {
        style: {
            borderTopStyle: "solid",
            borderTopWidth: "0px",
            borderTopColor: "transparent",
            minHeight: "3rem",
        },
    },
    headCells: {
        style: {
            paddingLeft: "0.45rem", // override the cell padding for head cells
            paddingRight: "0.45rem",
            fontSize: "0.85rem",
            backgroundColor: "#3d505c",
            color: "#fff",
            fontFamily: "Gotham-Book",
            "&:not(:last-of-type)": {
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderRightColor: "#dddddd",
            },
        },
    },
    cells: {
        style: {
            paddingLeft: "0.45rem", // override the cell padding for data cells
            paddingRight: "0.45rem",
            "&:hover": {
                cursor: "pointer",
            },
            "&:not(:last-of-type)": {
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                borderRightColor: "#dddddd",
            },
        },
    },
    pagination: {
        style: {
            color: "#3d505c",
            fontSize: "0.75rem",
            backgroundColor: "tranparent",
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            fontFamily: "RobotoSlab-Blod",
            borderTopColor: "transparent",
        },
    },
};

export { dataTablesMobileStyledTheme, dataTablesStyledTheme };
