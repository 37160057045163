import apiConfig from "../apis/apiConfig";
import { Fetch } from "../apis/Fetch";


const BlogService = {

    getBlogList: async () => {
        const { BASEURL, GET_BLOG_LIST } = apiConfig;

        const url = BASEURL + GET_BLOG_LIST;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const getBlogListServicePromise = await Fetch(
            url,
            "GET",
            headers,
            "",
            "services_getBlogList",
            true
        );
        return getBlogListServicePromise;
    },

    getBlogVideoList: async () => {
        const { BASEURL, GET_BLOG_VIDEO_LIST } = apiConfig;

        const url = BASEURL + GET_BLOG_VIDEO_LIST;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const getBlogVideoListServicePromise = await Fetch(
            url,
            "GET",
            headers,
            "",
            "services_getBlogVideoList",
            true
        );
        return getBlogVideoListServicePromise;
    },

    getBlogImgList: async () => {
        const { BASEURL, GET_BLOG_IMG_LIST } = apiConfig;

        const url = BASEURL + GET_BLOG_IMG_LIST;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const getBlogImgListServicePromise = await Fetch(
            url,
            "GET",
            headers,
            "",
            "services_getBlogImgList",
            true
        );
        return getBlogImgListServicePromise;
    },

    insertOrUpdateBlogImg: async (data) => {
        const { BASEURL, INSERT_OR_UPDATE_BANNER_IMG } = apiConfig;

        const url = BASEURL + INSERT_OR_UPDATE_BANNER_IMG;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            imgPath: `${data.imgPath}`,
            id: data.id,
            title: `${data.title}`,
            userId: `${data.userId}`,
            shortDescription:`${data.shortDescription}`,
            reference:`${data.reference}`,
            referenceDescription: `${data.referenceDescription}`,
            referenceDate:`${data.referenceDate}`,
            description:`${data.description}`,
            referenceImgOrVideo:`${data.referenceVideoOrImg}`,
            status: data.status,
        });

        const insertOrUpdateBlogImgServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_insertOrUpdateBlogImg",
            true
        );
        return insertOrUpdateBlogImgServicePromise;
    },

    insertOrUpdateBlogVideo: async (data) => {
        const { BASEURL, INSERT_OR_UPDATE_BANNER_VIDEO} = apiConfig;

        const url = BASEURL + INSERT_OR_UPDATE_BANNER_VIDEO;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            videoPath: `${data.videoPath}`,
            videoUrl: `${data.videoUrl}`,
            id: data.id,
            thumbnailImg: `${data.thumbnailImg}`,
            userId: `${data.userId}`,
            status: data.status
        });

        const insertOrUpdateBlogVideoServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_insertOrUpdateBlogVideo",
            true
        );
        return insertOrUpdateBlogVideoServicePromise;
    },

    getBlogInfo: async () => {
        const { BASEURL, GET_BLOG_INFO} = apiConfig;

        const url = BASEURL + GET_BLOG_INFO;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const getBlogInfoServicePromise = await Fetch(
            url,
            "GET",
            headers,
            "",
            "services_getBlogInfo",
            true
        );
        return getBlogInfoServicePromise;
    },

    getBlogSubmissionList: async () => {
        const { BASEURL, GET_BLOG_SUBMISSION_LIST } = apiConfig;

        const url = BASEURL + GET_BLOG_SUBMISSION_LIST;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const getBlogSubmissionListServicePromise = await Fetch(
            url,
            "GET",
            headers,
            "",
            "services_getBlogSubmissionList",
            true
        );
        return getBlogSubmissionListServicePromise;
    },

    insertOrUpdateBlogSubmission: async (data) => {
        const { BASEURL, INSERT_OR_UPDATE_SUBMISSION_BLOG} = apiConfig;

        const url = BASEURL + INSERT_OR_UPDATE_SUBMISSION_BLOG;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            firstName: `${data.firstName}`,
            emailId: `${data.emailId}`,
            articalName: `${data.articalName}`,
            filePath: `${data.filePath}`,
            id: `${data.id}`,
            isApproval: data.isApproval
        });

        const insertOrUpdateBlogSubmissionServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_insertOrUpdateBlogSubmission",
            true
        );
        return insertOrUpdateBlogSubmissionServicePromise;
    },

};

export default BlogService;
