import application_contants from "../../constants/application_contant";

const InitiativesData = [
    {
        subTitle: 'Projects &',
        title: 'Initiatives',
        bgImg: application_contants.BGPROJECTIMG,
        prabuImg: application_contants.PRABUREADINGIMG,
        img: application_contants.YOUNGPRABUIMG,
        section: 1,
    },
    {
        bio: [
            {
                title: 'Projects & Initiatives',
                desc: [
                    {
                        index: 0,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Welcome to Srila Prabhupada Foundation. We are a group of Prabhupada enthusiasts aspiring to preserve and perpetuate the legacy of His Divine Grace A.C. Bhaktivedanta Swami Prabhupada, the Founder-Acharya of the International Society for Krishna Consciousness. Here we take up various projects to increase the awareness and appreciation of Srila Prabhupada’s life, his saintly personality, his mission and his teachings. We hope that all people of the world can draw inspiration from the life of Srila Prabhupada.`
                    },  
                    {
                        index: 1,
                        isBold: false,
                        isItalic: true,
                        isPadding: false,
                        params: `Srila Prabhupada Museum`
                    }, 
                    {
                        index: 2,
                        isBold: false,
                        isItalic: true,
                        isPadding: false,
                        params: `Srila Prabhupada Lila App`
                    },
                    {
                        index: 3,
                        isBold: false,
                        isItalic: true,
                        isPadding: false,
                        params: `Srila Prabhupada Website`
                    },
                    {
                        index: 4,
                        isBold: false,
                        isItalic: true,
                        isPadding: false,
                        params: `Srila Prabhupada Today Newsletter`
                    }, 
                    {
                        index: 5,
                        isBold: false,
                        isItalic: true,
                        isPadding: false,
                        params: `Srila Prabhupada Expo`
                    }, 
                    {
                        index: 6,
                        isBold: false,
                        isItalic: true,
                        isPadding: false,
                        params: `Srila Prabhupada Oral History`
                    },  
                    {
                        index: 7,
                        isBold: false,
                        isItalic: true,
                        isPadding: false,
                        params: `Srila Prabhupada Biographies`
                    }, 
                ]
            },
            {
                title: 'Biographies',
                desc: [
                    {
                        index: 0,
                        isBold: true,
                        isItalic: false,
                        isPadding: false,
                        params: `Prabhupada in South India`
                    },
                    {
                        index: 1,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `By late 1970, the Hare Krishnas had become a familiar sight across three continents. Srila Prabhupada had introduced vibrant festivals, melodious kirtanas, delicious love feasts and opulent Deity worship—all components of the rich educational and cultural Vedic tradition—to great success. He opened temples in Los Angeles, Amsterdam, Paris and Tokyo, lectured in Harvard and MIT, met with global leaders and statesmen, and engaged with the artists and intelligentsia in London. His books became standard textbooks in leading universities and occupied pride of place in libraries.`
                    },
                    {
                        index: 2,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `In 1971, Srila Prabhupada returned to India—home of Vedic culture—with forty Western disciples desiring to convince Indians to take up their traditional culture. It created a storm. Thousands flocked daily to the pandal programs, kings and queens hosted special gatherings, religious luminaries offered honors, and government institutions sought participation to tackle societal problems. Prabhupada travelled extensively.`
                    },
                    {
                        index: 3,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Between 1971 and 1976, Srila Prabhupada visited South India nine times. Teaching the science of Krishna bhakti, he held massive pandal programs and vibrant harinama processions, met with many people of prominence and initiated iconic projects. However, this important part of Gaudiya history has not been detailed sufficiently. Books are available on how Prabhupada built the Vrindavana, Mayapur and Bombay projects but not much was available on South India.`
                    },
                    {
                        index: 4,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `When Srila Prabhupada Foundation decided to take up this project the Covid pandemic had just started. Technology and collaboration would play a big part in bringing this project to fruition. The pandemic offered several challenges and opportunities. One opportunity was that many senior disciples, who would otherwise be busy on preaching tours, were available to give interviews. They readily agreed to participate in this historic project. However, many of them were settled in different parts of the world and setting up an interview involved finding a slot between their sadhana and service timings across different timezones. Some were new to Zoom calls and Google Meet but they sportingly came on board and shared fantastic memoirs of their Indian adventures. We also had the good fortune of travelling and personally interviewing some senior disciples in India. All these interviews were then transcribed and proof checked. Then followed the exhaustive process of historical research, authoring and publishing.`
                    },
                    {
                        index: 5,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Prabhupada in South India is the first book to comprehensively cover Srila Prabhupada’s pastimes in the southern India peninsular. It is written by Rajasekhara Dasa Brahmachari, an early Prabhupada disciple and best-selling international author.`
                    },
                    {
                        index: 6,
                        isBold: true,
                        isItalic: false,
                        isPadding: false,
                        params: `Gargamoney — Serving Srila Prabhupada 1966-1977`
                    },
                    {
                        index: 7,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Gregory M Scharf, son of a wealthy New Yorker, met his guru, Srila Prabhupada, when ISKCON was a fledgling spiritual movement and became Gargamuni Das. This is the story of his service to Srila Prabhupada, full of adventure and grit, even preaching the message of the Vedic scriptures in Pakistan in 1971. `
                    },
                    {
                        index: 8,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Gargamuni used his talent to raise funds for ISKCON’s missionary activities, for which Srila Prabhupada affectionately and humorously nicknamed him “Gargamoney.”`
                    },
                    {
                        index: 9,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Purnaprajna Dasa, Gargamuni’s friend and best selling author, captures these wonderful adventures in his latest book Gargamoney—Serving Srila Prabhupada 1966-1977.`
                    },
                    {
                        index: 10,
                        isBold: true,
                        isItalic: false,
                        isPadding: false,
                        params: `Srila Prabhupada’s biography in regional languages`,
                    },
                    {
                        index: 11,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Srila Prabhupada’s institutions are spread across the world, however, awareness of his life story and contributions are limited to the English language. In order to articulate these in various languages, biographical works in Assamese, Bengali, English, Gujarati, Hindi, Malayalam, Tamil and Telugu have been commissioned. These works, which are in various stages of completion, are due to be released in early 2022 as part of Srila Prabhupada’s 125th Appearance Anniversary celebrations.`,
                    },
                    {
                        index: 12,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Biographies are planned in other important European and Asian languages in the future.`,
                    },
                ]
            },
            {
                title: 'Museum',
                desc: [
                    {
                        index: 0,
                        isBold: false,
                        params: `Relationships grow by the giving and receiving of gifts, states Srila Rupa Goswami, the sixteenth-century teacher of devotional mellows. It is a spontaneous act—demonstrating the affectionate bond between two individuals. Srila Prabhupada received many gifts—a bunch of flowers, a pen, a pair of socks, sometimes a typewriter, a ring or a painting—and out of his natural loving-kindness, he would give these to his disciples. These articles, undoubtedly of artistic, cultural and historical importance, hold immense personal value. Devotees wish that others also have the opportunity to see these personal gifts of His Divine Grace—they offer an intimate glimpse of his personality—but are particular that they be handled by people who care deeply for them. Srila Prabhupada Foundation aspires to build a grand Prabhupada Museum housing these articles in the sacred land of Vrindavana, which His Divine Grace called his home.`
                    },
                    {
                        index: 1,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Vrindavana is the ideal setting for such a museum, where millions of annual pilgrims will be able to learn about Srila Prabhupada. Prabhupada Museum offers a safe environment to preserve the artifacts. Experts from various museums like Gandhi Museum are guiding us on best preservation practices so that these treasures will be available for generations. Other subject matter experts are guiding us on how to handle and preserve various materials—paper, cloth, wood, magnetic tapes and metal. `
                    },
                    {
                        index: 2,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Prabhupada Museum offers a common platform for devotees to donate the gifts they received. We will strive to best showcase these gifts so that visitors are inspired and educated. We invite more disciples of Srila Prabhupada to participate in this humble attempt to preserve and perpetuate the legacy of His Divine Grace. `
                    },
                ]
            },
            {
                title: 'Online',
                desc: [
                    {
                        index: 0,
                        isBold: true,
                        isItalic: false,
                        isPadding: false,
                        params: `Srila Prabhupada Lila app`
                    },
                    {
                        index: 1,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Srila Prabhupada was a perfect teacher of the science of bhakti-yoga. His practical presentation of the Krishna culture attracted thousands around the world to lead a life of higher purpose and happiness. The message he carried was meaningful, offering solutions to issues that beset the individual and society. Anyone who met Srila Prabhupada had a fond memory of him and when these memoirs were shared with others, it became a source of inspiration for many.`
                    },
                    {
                        index: 2,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Over the years Srila Prabhupada’s followers have diligently compiled these memoirs in various forms—audio recordings, videos, biographies—for the benefit of generations to come. Desiring to make these available on various technology platforms, Srila Prabhupada Foundation came up with the Srila Prabhupada Lila app. This app was developed in-house by a group of volunteers as their personal offering to Srila Prabhupada—a practical demonstration of the Gita principle of karma-yoga.`
                    },
                    {
                        index: 3,
                        isBold: false,
                         isItalic: false,
                        isPadding: false,
                        params: `Srila Prabhupada Lila app lists over 10,000 pastimes of Srila Prabhupada against 180+ locations as shared by more than 400 of his disciples. Popular features include the illustrated biography, rare pictures, poetry, quick facts about Srila Prabhupada and 360° view of rooms that Prabhupada had used.`
                    },
                    {
                        index: 4,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `The app is available on Android and iOS.`
                    },
                    {
                        index: 5,
                        isBold: true,
                        isItalic: false,
                        isPadding: false,
                        params: `Srila Prabhupada Today Newsletter`
                    },
                    {
                        index: 5,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `A daily newsletter sharing Srila Prabhupada’s pastimes on that particular day. Spend 5 minutes or less to immerse yourself in fascinating pastimes you aren't hearing anywhere else. Features Photo of the day, Memory of the day, Newspaper Articles, Srila Prabhupada tadiya and more.`
                    },
                ]
            },
            {
                title: 'Oral History',
                desc: [
                    {
                        index: 0,
                        isBold: true,
                        isItalic: false,
                        isPadding: false,
                        params: `Srila Prabhupada Oral History`
                    },
                    {
                        index: 1,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Srila Prabhupada has touched so many lives. Anyone who has come in contact with him has been impressed by his spotless character, his profound compassion and complete devotion to Lord Krishna. Hearing about his life and activities offers inspiration for our personal growth.`
                    },
                    {
                        index: 2,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Srila Prabhupada Oral History project aims to record, preserve and perpetuate the memoirs of Srila Prabhupada. This involves interviewing the followers and contemporaries of Srila Prabhupada, recording their memoirs, archiving the audio-visual content, and distributing them on various media. Funding and assistance are offered for other similar projects.  `
                    },
                    {
                        index: 3,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `More than 200 interviews have been conducted across the world till date and 1,000+ new memoirs recorded.`
                    },
                ]
            },
            {
                title: 'Outreach',
                desc: [
                    {
                        index: 0,
                        isBold: true,
                        isItalic: false,
                        isPadding: false,
                        params: `Srila Prabhupada Outreach`,
                    },
                    {
                        index: 1,
                        isBold: false,
                        isItalic: false,
                        isPadding: true,
                        params: `Various theme-based expos that articulate the teachings and contributions of Srila Prabhupada are conducted in major cities worldwide. ‘A Life of Pure Devotion’, ‘Story of the Bhagavad-Gita As It Is’ and ‘Ambassador of Indian Culture and Spirituality’ expos attract thousands of visitors annually. Here interactive methodologies offer each visitor a memorable learning experience about Srila Prabhupada. In recent years Ahmedabad, Bangalore, Boston, Hyderabad, Jaipur, Prayagraj, Sunnyvale and Varanasi have played host to Srila Prabhupada Expo.`
                    },
                ]
            },
        ],
        moreLinks: [
            {
                index: 1,
                img: application_contants.ASSETS15,
                title: `125th Anniversary Celebration`,
                type: 'Most Popular',
                date: '31 August 2021',
                link: application_contants.ANNIVERSARY,
            },
            {
                index: 1,
                img: application_contants.ASSETS16,
                title: `A lifetime in preparation`,
                type: 'Recommended',
                date: '31 August 2021',
                link: application_contants.RECOMMEND,
            }
        ],
        section: 2,
    }

]


export default InitiativesData;