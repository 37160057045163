import { ActionTypes } from "../constants/action-types"


export const updateSeva = (item) => {
    return {
        type: ActionTypes.UPDATE_SEVA,
        payload: item,
    }
}

