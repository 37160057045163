import React, { useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import TrusteesData from '../../utils/json/trusteesData';


const Trustees = () => {
    const onlyWidth = useWindowWidth();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');


    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 100)
        }

    }, [direction]);

    return (
        <div className="trustees-content-container position-relative">
            {classNames !== '' ?
                <Row className="mx-0 align-items-center">
                    <Col xs={12} sm={12} md={10} lg={10} xl={10} className="m-auto">
                        <Row className={`mx-0 ${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pb-4': ''}`}>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} className={`${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                <Row className="mx-0">
                                    {onlyWidth <= application_contants.MOBILE_BREAKPOINT ?
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className={`py-5 m-auto ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                            <h3 className="h1-small sub-section-title align-center lh-0px">{`Our`}</h3>
                                            <h1 className="h1-medium-small sub-section-title saolStandardMedium align-center">{`Trustees`}</h1>
                                        </Col> : null}
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6} className="m-auto">
                                        <Row className="mx-0">
                                            {TrusteesData.length > 0 && TrusteesData.map((it, index) => (
                                                <Col xs={12} sm={12} md={12} lg={{ size: onlyWidth === 1270 || onlyWidth === 1024 ? 12 : 10 }} xl={{ size: onlyWidth === 1270 || onlyWidth === 1024 ? 12 : 10 }} key={index}>
                                                    {index === TrusteesData.length - 1 ? null :
                                                        <div className="pb-3">
                                                            <Row className="mx-0">
                                                                <Col xs={12} sm={12} md={onlyWidth === 1270 || onlyWidth === 1024 ? 6 : 8} lg={onlyWidth === 1270 || onlyWidth === 1024 ? 11 : 8} xl={onlyWidth === 1270 || onlyWidth === 1024 ? 11 : 8} className="m-auto">
                                                                    <img src={it.imgPath} alt="" />
                                                                </Col>
                                                            </Row>
                                                            <div className="pt-2">
                                                                <p className="p-medium sub-section-title saolStandardMedium text-uppercase">{it.name}</p>
                                                                <p className="p-medium sub-section-title">{it.designation}</p>
                                                            </div>
                                                        </div>}
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Row className="mx-0">
                                            {TrusteesData.length > 0 && TrusteesData.map((it, index) => (
                                                <Col xs={12} sm={12} md={12} lg={{ size: onlyWidth === 1270 || onlyWidth === 1024 ? 12 : 10 }} xl={{ size: onlyWidth === 1270 || onlyWidth === 1024 ? 12 : 10 }} key={index}>
                                                    {index !== TrusteesData.length - 1 ? null :
                                                        <div className="pb-3">
                                                            <Row className="mx-0">
                                                                <Col xs={12} sm={12} md={onlyWidth === 1270 || onlyWidth === 1024 ? 6 : 8} lg={onlyWidth === 1270 || onlyWidth === 1024 ? 11 : 8} xl={onlyWidth === 1270 || onlyWidth === 1024 ? 11 : 8} className="m-auto">
                                                                    <img src={it.imgPath} alt="" />
                                                                </Col>
                                                            </Row>
                                                            <div className="pt-2">
                                                                <p className="p-medium sub-section-title saolStandardMedium text-uppercase">{it.name}</p>
                                                                <p className="p-medium sub-section-title">{it.designation}</p>
                                                            </div>
                                                        </div>}
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                              
                                </Row>
                            </Col>
                            {onlyWidth <= application_contants.MOBILE_BREAKPOINT ? null :
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} className={`m-auto ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                    <h1 className="h1-small sub-section-title align-left pl-72px lh-0px">{`Our`}</h1>
                                    <h1 className="h1-medium sub-section-title saolStandardMedium align-left lh-72px">{`Trustees`}</h1>
                                </Col>}
                        </Row>
                    </Col>
                </Row>
                : null}
        </div>
    )
}

export default Trustees;