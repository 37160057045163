import application_contants from "../../constants/application_contant";

const SocialMedia =[
    {
        img: application_contants.FACEBOOKIMG,
        link: 'https://www.facebook.com/Srila-Prabhupada-Lila-158671941264206/',
        whiteImg: application_contants.WHITEFACEBOOKIMG,
    },
    {
        img: application_contants.INSTAGRAMIMG,
        link: 'https://www.instagram.com/srilaprabhupadalila',
        whiteImg: application_contants.WHITEINSTAGRAM,
    },
    {
        img: application_contants.TWITTERIMG,
        link: 'https://twitter.com/prabhupadalila',
        whiteImg: application_contants.WHITERTWITTERIMG
    },
    // {
    //     img: application_contants.LINKEDINIMG
    // },
    {
        img: application_contants.YOUTUBEIMG,
        link: 'https://www.youtube.com/channel/UCCxuzpoBYfNUcE1pwR759PQ',
        whiteImg: application_contants.WHITEYOUTUBE,
    }
];


export default SocialMedia;