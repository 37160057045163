
import { ActionTypes } from "../constants/action-types"

const initialState = {
    fullName: '',
    emailId: '',
    gender: '',
    authenticated: false,
    status: 0
};

export const userAuthenticatedReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.USER_AUTHENTICATED:
            return {
                ...state,
                ...payload,
            }
        case ActionTypes.LOGIN_OUT:
            return {
                ...state, 
                ...payload,
            };
        default:
            return state;
    }
}
