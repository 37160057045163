import React from "react";
import memoizeOne from "memoize-one";
import { Button } from "reactstrap";

const TablesColumns = memoizeOne((handleDelete, handleEdit) => [
    {
        name: "First Name",
        grow: 0.85,
        selector: "firstName",
        sortable: false,
        cell: (row) => <div className="py-1">
            <p className={`p-medium-small sub-section-title gothamBook`}>{row.firstName}</p>
        </div>,
    },
    {
        name: "Email Address",
        selector: "emailId",
        sortable: false,
        grow: 1.5,
        cell: (row) => <div className="py-1"><p className={`p-medium-small sub-section-title gothamBook`}>{row.emailId}</p></div>,
    },
    {
        name: "Artical Name",
        selector: "articalName",
        sortable: false,
        grow: 1.5,
        cell: (row) => <div className="py-1"><p className={`p-medium-small sub-section-title gothamBook`}>{row.articalName}</p></div>,
    },
    {
        name: "Approval",
        selector: "isApproval",
        sortable: false,
        cell: (row) => <div className="py-1"><p className={`p-medium-small sub-section-title gothamBook ${row.isApproval === 1 ? 'title-green fw-600' : 'title-danger-light fw-600'}`}>{`${row.isApproval === 1 ? 'Approved' : row.isApproval === 0 ? 'pending' : 'Rejected'}`}</p></div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => (
            <div className="d-flex py-1">
                <div onClick={() => handleEdit(row)}>
                    <Button className="btn btn-small custom-green-btn custom-btn--small fs-10px">Edit</Button>
                </div>
                <div onClick={() => handleDelete(row)} className="ml-12px">
                    <Button className="btn btn-small custom-theme-btn custom-btn--small fs-10px">Delete</Button>
                </div>
            </div>
        ),
    },
]);

export default TablesColumns;
