import application_contants from "../../constants/application_contant";
import { ActionTypes } from "../constants/action-types"

const initialState = {
    name: application_contants.HOME,
}


export const currentPageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_CURRENT_PAGE:
            return { ...state, name: payload };
        default:
            return state;
    }
}

