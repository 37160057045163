import { ActionTypes } from "../constants/action-types"


export const setCurrentPage =(currentPage) => {
    return{
        type: ActionTypes.SET_CURRENT_PAGE,
        payload: currentPage,
    }
}

