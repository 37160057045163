import { useWindowWidth } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import Header from '../../customComponents/Header';
import { setCurrentPage } from '../../redux/actions/currentPage';

const ThankYou = () => {
    const onlyWidth = useWindowWidth()
    const state = useSelector(state => state);
    const { direction } = state;
    const dispatch = useDispatch();
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');

    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn animate__slow` : `animate__animated animate__zoomIn animate__slow`
            setClassNames(cls);
        }, 500);
        setTimeout(() => {
            dispatch(setCurrentPage(application_contants.DONATE));
        }, 7000)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 200)
        }
    }, [direction]);



    return (
        <div className="thank-you-content-container pt-5">
            <Header isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} menuClassNames={'title-melanzane-Violet'} isScroll={false} isFooter={false} />
            {classNames !== '' ?
                <Row className="mx-0 align-items-center full-width">
                    <Col xs={12} sm={12} md={10} lg={10} xl={10} className="m-auto">
                        <Row className="mx-0 align-items-start full-width">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames}` : ''}`}>
                                <Row className="mx-0 full-width">
                                    <Col xs={12} sm={12} md={12} lg={{ offset: 4, size: 8 }} xl={{ offset: 4, size: 8 }} className="m-auto">
                                        <div className="thank-you-img--container mb-4">
                                            <img src={application_contants.THANKSLOGOIMG} alt="" />
                                        </div>
                                        <div className="thank-you-bg-img--container">
                                            <img src={application_contants.THANKIMG} alt="" />
                                            <div className="thank-message-info">
                                                <h2 className="h2-medium sub-section-title title-black align-center">
                                                    {`Thank You for your generosity`}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pt-4' : 'pt-5'}`}>
                                            <h6 className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'p-medium' : 'h6-medium'} sub-section-title title-black align-center gothamBook`}>
                                                <span className="bg-grey p-2">We look forward to your continued support.</span>
                                            </h6>
                                        </div>
                                    </Col>

                                </Row>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                : null}
        </div>
    )
};


export default ThankYou