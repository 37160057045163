import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useWindowHeight, useWindowWidth } from "@react-hook/window-size";
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import ReactPlayer from "react-player";
import application_contants from "../../../constants/application_contant";
import ActiveItem from "../ActiveItem";
import Header from "../../Header";
import { setCurrentPage } from "../../../redux/actions/currentPage";
import { setCurrentPosition } from "../../../redux/actions/currentPosition";
import { setDirection } from "../../../redux/actions/direction";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #fff;
`;


const CarouselItem = (props) => {
    const [item, setItem] = useState(null);
    const state = useSelector(state => state);
    const [isVideoPlay, setIsVideoPlay] = useState(true);
    const { direction, isPlay } = state;
    const dispatch = useDispatch();
    const onlyWidth = useWindowWidth()
    const onlyHeight = useWindowHeight()

    React.useEffect(() => {
        setItem(props.item);
    }, [props, onlyWidth])

    const handleNavigation =(name) => {
         dispatch(setCurrentPage(name))
    }

    const handleVideoPlay =() => {
        setIsVideoPlay(!isVideoPlay);
        dispatch(setDirection(direction.direction === application_contants.NONE ? application_contants.FORWARD : direction.direction));
        dispatch(setCurrentPosition(1));
    }

    return (
        <Wrapper>
            {item ?
                <Wrapper>
                    {item.isVideo ?
                        <ActiveItem
                            forwardStyle={{}}
                            backwardStyle={{}}
                            isContent={true}
                            isFirstLayer={false}
                            timeDelay={0}
                            timing={0}
                        >{(style) => (
                            <div>
                                <Header {...props} isMenuAnimate={true} isScroll={false}/>
                                   <ReactPlayer
                                    className="react-player"
                                    url={direction && direction.direction === application_contants.BACKWARD ? item.bwVideo : item.fwVideo}
                                    width={onlyWidth}
                                    height={onlyHeight}
                                    playing={isVideoPlay}
                                    controls={false}
                                    volume={0}
                                    muted={true}
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 },
                                        },
                                        attributes: {
                                            controlsList: "download",
                                            //poster: `${direction && direction.direction === application_contants.FORWARD ? item.posterImg : item.posterImg}`,
                                        },
                                    }}
                                    onEnded={() => handleVideoPlay()}
                                />
                            </div>
                        )}
                        </ActiveItem>
                        :
                        <ActiveItem
                            forwardStyle={item.forwardStyle}
                            backwardStyle={item.backwardStyle}
                            isContent={true}
                            isFirstLayer={false}
                            timeDelay={0}
                            timing={0}
                        >{
                                (style) => (
                                    <div>
                                        <Header {...props} isMenuAnimate={true} isScroll={false}/>
                                        <Row className={`mx-0 content-container`}>
                                            {item.list.length > 0 && item.list.map((i, ind) => (
                                                <Col xs={6} sm={6} md={6} lg={2} xl={2} key={ind} 
                                                className={`cursor-pointer animate__animated ${isPlay && isPlay.isPlay ? `animate__fadeOutUp ${`${i.classNames}`}` : `animate__fadeInUp ${`${i.classNames}`}`}`}
                                                onClick={() => handleNavigation(i.link)}>
                                                    <div>
                                                        <div className="position-relative">
                                                           <img src={i.boxShadowImg} alt="" className="img-container" />
                                                            <div className="layer-img">
                                                               <img src={i.img} alt="" className="layer-img-container" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="title-position--container">
                                                        <div>
                                                            <div className="content-title pt-2 pb-2">
                                                                <h3 className="h3-small sub-section-title">{i.title}</h3>
                                                            </div>
                                                            <div className="content-description">
                                                                <p className="p-small sub-section-title gothamBook title-orinoco-green pt-3 wp-1px">{i.desc.length < 80 ? i.desc.substr(0, 80) : i.desc}</p>
                                                                <p className="p-small sub-section-title gothamBook title-orinoco-green wp-1px">{i.subDesc}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                )
                            }
                        </ActiveItem>
                    }
                </Wrapper>
                : null}
        </Wrapper>
    )
}


export default CarouselItem