import { ActionTypes } from "../constants/action-types"


export const setCurrentPosition =(currentPosition) => {
    return{
        type: ActionTypes.SET_CURRENTPOSITION,
        payload: currentPosition,
    }
}

