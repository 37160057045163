import React from "react";
import Slider from "react-slick";
import { Row, Col } from "reactstrap";
import VideoPlayer from "./VideoPlayer";
import application_contants from "../../constants/application_contant";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWindowSize } from "@react-hook/window-size";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
            <img src={application_contants.LEFTARROWIMG} alt="" className="arrow-img"/>
            {/* <ArrowForwardIosOutlined style={{ color: `#000`, fontSize: '2rem' }} /> */}
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", }}
            onClick={onClick}
        >
             <img src={application_contants.RIGHTARROWIMG} alt="" className="arrow-img"/>
            {/* <ArrowBackIosOutlined style={{ color: `#000`, fontSize: '2rem' }} /> */}
        </div>
    );
}

const VideoPlayerGrid = (props) => {
    const [width] = useWindowSize();
    const settings = {
        dots: false,
        infinite: false,
        loop: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };


    return (
        <Row className="mx-0">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto px-0">
                <Row className="mx-0">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'px-0' : ''}`}>
                        {props.videoList.length > 0 ?
                            <Slider {...settings}>
                                {props.videoList.length > 0 && props.videoList.map((item, index) => (
                                    <Row key={index} className="mx-0">
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`px-0 ${width <= application_contants.MOBILE_BREAKPOINT ? `${props.isPadding ? 'pt-3' : ''}` : 'pt-3'}`}>
                                            <VideoPlayer item={item} index={index} isPadding={props.isPadding} />
                                        </Col>
                                    </Row>
                                ))}
                            </Slider> : null}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default VideoPlayerGrid;
