import React, { useCallback, useState } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDropzone } from "react-dropzone";

const FileUploader = props => {
    const [fileFormat, setFileFormat] = useState(
        "image/jpeg, image/jpg, image/png"
    );

    React.useEffect(() => {
        if (props.isImg) {
            setFileFormat("image/jpeg, image/jpg, image/png");
        } else if (props.isVideo) {
            setFileFormat("video/mp4");
        } else if (props.isBoth) {
            setFileFormat("image/jpeg, image/jpg, image/png, video/mp4");
        } else if (props.isDoc) {
            setFileFormat(".doc, .docx");
        } else {
            setFileFormat("application/pdf");
        }
    }, [props]);

    const onDrop = useCallback(acceptedFiles => {

        let item = [];
        acceptedFiles.forEach(file => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onloadend = () => {
                const binaryStr = reader.result;
                item.push({ base64: binaryStr, file: file });
                if (props.isImg) {
                    props.getThumbnail(item);
                } else if (props.isVideo) {
                    props.getVideo(item);
                } else if (props.isBoth) {
                    props.getVideoOrItem(item);
                } else if (props.isDoc) {
                    props.getDocFile(item);
                }
                else {
                    props.getPdfFile(item);
                }
            };
            reader.readAsDataURL(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: fileFormat,
        multiple: false,
        onDrop
    });

    return (
        <section className="container-fuild">
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} disabled={props.isDefault ? true : false} />
                <div>
                    <div
                        className={`position-relative ${props.isDefault ? "cursor-none" : "cursor-pointer "
                            }`}
                    >
                        {props.isLargeForm ?
                            <div>
                                <Input readonly value={props.value} className="form-control common-form-control--large" />
                            </div>
                            :
                            <div>
                                <Input readonly value={props.value} placeholder={`${props.labelName}`} className="form-control common-form-control" />
                            </div>}
                        {props.isLargeForm ? null :
                            <div className="file-thumbnail-container">
                                <CloudUploadIcon
                                    style={{
                                        color: "#ddd",
                                        fontSize: "2.5rem",
                                        borderRadius: 4,
                                        padding: 2,
                                        position: 'absolute',
                                        right: '4px',
                                    }}
                                />
                            </div>}
                    </div>
                </div>
            </div>
        </section>
    );
};


FileUploader.propTypes = {
    isVideo: PropTypes.bool,
    isImg: PropTypes.bool,
    isBoth: PropTypes.bool,
    isDoc: PropTypes.bool,
    isLargeForm: PropTypes.bool
};

FileUploader.defaultProps = {
    isVideo: false,
    isImg: false,
    isBoth: false,
    isDoc: false,
    isLargeForm: false,
};


export default FileUploader;
