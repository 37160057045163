import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import Footer from '../../customComponents/Footer/Footer';
import Header from '../../customComponents/Header';
import { setCurrentPage } from '../../redux/actions/currentPage';


const Volunteer = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { direction } = state;;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 100)
        }
    }, [direction]);



    const handleNavigation = (name) => {
        dispatch(setCurrentPage(name));
    }

    return (
        <div>
            <div className="volunteer-container">
                <Header isScroll={false} isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} isFooter={false} menuClassNames={'title-melanzane-Violet'} scrollPositionClassNames={`bt-32px`} bgClassName={'bg-narvik-green'} />
                <div className="container-fluid pt-4rem">
                    <Row className="mx-0 py-5">
                        <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto">
                            <Row className="mx-0">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''} pb-3`}>
                                    <h1 className="h1-small sub-section-title align-left title-black text-uppercase porterBold">GET INVOLVED</h1>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''} pb-2`}>
                                    <h4 className="h4-small sub-section-title align-left title-stack-grey gothamBlack">Volunteer</h4>
                                    <h6 className="h6-small sub-section-title align-left gothamBook lh-24px">
                                        Our Volunteers play an important role in sharing our cultural values, reasearch, exhibitions and historic collection with the public. We are always on the lookut for dynamic, enthusiastic individuals who want to be a part of our team. You can either work onsite at our facilities or participate in projects online.
                                    </h6>
                                    <h6 className="h6-small sub-section-title align-left gothamBook lh-24px">If youre interested to volunteer, write to us at <a href="mailto:admin@srilaprabhupada.in" className="txt-underline title-stack-grey cursor-pointer">admin@srilaprabhupada.in</a></h6>
                                    <h6 className="h6-small sub-section-title align-left gothamBook lh-24px">To learn about future volunteer opportunities, please sign up for the mailing list below.</h6>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''} pb-2`}>
                                    <h4 className="h4-small sub-section-title align-left title-stack-grey gothamBlack">Write for us</h4>
                                    <h6 className="h6-small sub-section-title align-left gothamBook lh-24px">
                                        Write impactful articles on topics ranging from spiritual to social and get featured on our blog. Your article should be at least 500 words long and no more than 800 words. Before featuring on the blog, your article will be reviewd and copyedited for grammar, spelling, punctuation and relevence of topic.
                                    </h6>
                                    <h6 className="h6-small sub-section-title align-left gothamBook lh-24px">Read the <font className="fs-italic gothamBlack cursor-pointer" onClick={() => handleNavigation(application_contants.SUBMISSIONSGUIDNELINES)}>submission guidelines</font> to get started</h6>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''} pb-2`}>
                                    <h4 className="h4-small sub-section-title align-left title-stack-grey gothamBlack">Donate</h4>
                                    <h6 className="h6-small sub-section-title align-left gothamBook lh-24px">
                                        Support the activities of Srila Prabhupada Foundation and help us serve you better.
                                    </h6>
                                    <h6 className="h6-small sub-section-title align-left gothamBook lh-24px">
                                        Click below for donations
                                    </h6>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''} pb-2 pt-5`}>
                                    <h6 className="h6-small sub-section-title align-left gothamBook lh-24px">
                                        <font className="txt-underline title-stack-grey pr-3 cursor-pointer" onClick={() => handleNavigation(application_contants.DONATE)}>DONATE </font> (Donate page link)
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Volunteer;