
 
export const forwardStyleBG = { transform: 'scale3d(1.5, 1.5, 1.5)', opacity: 0 };
export const backwardStyleBG= { transform: 'scale3d(1, 1, 1)', opacity: 0 };

export const backwardZoomStyle = { transform: 'scale3d(1, 1, 1)', opacity: 0 };
export const forwardZoomStyle = { transform: 'scale(.2)', opacity: 0 };

export const forwardStyleLessZoom = { transform: 'scale3d(0.65 ,0.65, 0.65)', opacity: 0 };

export const forwardStyleSmallZoom = { transform: 'scale3d(0.75 ,0.75, 0.75)', opacity: 0 };
export const forwardStyleMediumZoom = { transform: 'scale(0.5)', opacity: 0 };


export const forwardStyleImage = { transform: 'translateZ(-100%)', opacity: 0 };
export const backwardStyleImage = { transform: 'translateZ(100%)', opacity: 0 };

export const forwardStyleContent = { transform: 'scale(1.2)', opacity: 0 };
export const forwardStyleContent1 = { transform: 'scale3d(1.5, 1.5, 1.5)', opacity: 0 };

export const backwardStyleBG1 = { transform: 'scale3d(1.3, 1.3, 1.3)', opacity: 0 };

export const backwardStyleContent2 = { transform: 'scale(2) translateY(100%)', opacity: 0 };
