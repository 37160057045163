import { useWindowSize } from '@react-hook/window-size'
import React, { useState } from 'react'
import DataTable from "react-data-table-component";
import { Row, Col, Button } from 'reactstrap';
import TablesColumns from './TablesColumns.js';
import application_contants from '../../../constants/application_contant'
import DashboardLayout from '../../../customComponents/InternalLayout/DashboardLayout'
import Loader from '../../../customComponents/Loader/Loader'
import RenderPage from '../../../customComponents/RenderPage/RenderPage'
import Strip from '../../../customComponents/Strip/Strip'
import { dataTablesMobileStyledTheme, dataTablesStyledTheme } from '../../../utils/dataTablesStyledTheme';
import BlogService from '../../../../api/services/BlogService.js';
import AddBlogBannerImage from '../../../customComponents/AddBlogBannerImage/AddBlogBannerImage.js';

const BannerBlogImage = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [item, setItem] = useState('');

    const [width] = useWindowSize();

    React.useEffect(() => {
        getBlogImgList();
    }, [])

    const getBlogImgList = async () => {
        setIsLoading(true);
        await BlogService.getBlogImgList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                setIsLoading(false);
                                setData(result.data ? result.data.list.length > 0 ? result.data.list : [] : []);
                            } else {
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });

    }

    const handleAddToggle = () => {
        setIsAdd(true)
        setIsModal(!isModal);
    };


    const handleEdit = (item) => {
        setIsAdd(false)
        setItem(item);
        setIsModal(!isModal);
    }

    const handleDelete = async (item) => {
        setItem(item);
    }

    const handleToggle = () => {
        setIsModal(!isModal)
    }

    const handleRefresh = async () => {
        await setIsModal(!isModal);
        await getBlogImgList();
        await setIsAdd(true)
    }

    return (
        <DashboardLayout>
            <RenderPage
                id="seva-page"
                className="render-page mt-5"
                containerType="container-fluid"
            >
                {!isModal ?
                    <Strip
                        id="tst-seva"
                        className="strip strip-no-padding"
                        containerType="container admin-container box-shadow"
                    >
                        <Row className="mx-0 pt-3">
                            <Col xs={12} sm={12} md={12} lg={10} xl={10}>
                                <h6 className="sub-section-title title-black txt-uppercase gothamBlack align-left">List</h6>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <div>
                                    <Button className="btn btn-block custom-blue-btn custom-btn--small" onClick={() => handleAddToggle()}>Add </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mx-0">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="pb-3 pt-2">
                                    {!isLoading ? (
                                        <DataTable
                                            title=""
                                            columns={TablesColumns(handleDelete, handleEdit)}
                                            data={data}
                                            pagination
                                            // expandableRows
                                            className="data-table"
                                            persistTableHead={false}
                                            highlightOnHover
                                            // expandableRowsComponent={<ExpandedComponent />}
                                            customStyles={
                                                width > application_contants.MOBILE_BREAKPOINT
                                                    ? dataTablesStyledTheme
                                                    : dataTablesMobileStyledTheme
                                            }
                                        // onRowClicked={(data) => this.getHospitalItem(data)}
                                        />
                                    ) : (
                                        <div className="pt-32px pb-64px d-flex justify-content-center">
                                            <Loader containerClassName="" />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Strip> :
                    <AddBlogBannerImage handleToggle={handleToggle} isAdd={isAdd} handleRefresh={handleRefresh} item={item}/>}
            </RenderPage>
        </DashboardLayout>
    )

}


export default BannerBlogImage;

