
const PaymentSchedule = [
    {
        id: 1,
        type: 'Week'
    },
    {
        id: 2,
        type: 'Month'
    },
    {
        id: 3,
        type: 'Quarter'
    },
    {
        id: 4,
        type: 'Year'
    }
];

export default PaymentSchedule;