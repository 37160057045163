import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import application_contants from "../../constants/application_contant";
import { userLogout } from "../../redux/actions/userInfo";
import { asyncStoreClear } from "../../utils/asyncStorage";

const Header = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { userInfo } = state;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleLogout = async () => {
        await asyncStoreClear()
        dispatch(userLogout('', '', false));
        props.history.push("/");
    };

    return (
        <div className="d-flex admin-header-container">
            <div>
                <div className="logo-position1">
                    <div className="d-flex align-items-center">
                        <div>
                            <Link to={application_contants.PAYMENT}>
                                <img src={application_contants.LOGOIMG} className="logo-container" alt="" />
                            </Link>
                        </div>
                        {/* <div className="px-2">
                            <h4 className="h4-small sub-section-title gothamBlack">ADMIN</h4>
                        </div> */}
                    </div>
                </div>
            </div>
            <div>
                <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className="btn-transparent"
                >
                    <div className="d-flex">
                        <AccountCircleOutlinedIcon
                            style={{ fontSize: '2rem', color: "#fff" }}
                        />
                        <div className="pr-2 pt-1">
                            <p className="mb-0 p-very-small sub-section-title title-white gothamBlack align-left">
                                Welcome
                            </p>
                            <p className="mb-0 p-very-small sub-section-title title-white gothamBlack align-left">
                                {userInfo ? userInfo.fullName: ''}
                            </p>
                        </div>
                    </div>
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem onClick={(event) => handleLogout(event)}>
                                            <p className="mb-0 p-medium sub-section-title gothamBook align-left">
                                                Logout
                                            </p>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
};

export default withRouter(Header);
