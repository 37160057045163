import { useWindowSize, useWindowWidth } from '@react-hook/window-size';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../../constants/application_contant';
import Footer from '../../../customComponents/Footer/Footer';
import Header from '../../../customComponents/Header';
import { setCurrentPage } from '../../../redux/actions/currentPage';
import FoundationData from '../../../utils/json/foundation';
import ChairmansMessage from '../../ChairmansMessage/ChairmansMessage';
import DamodarTemple from '../../DamodarTemple/DamodarTemple';
import Trustees from '../../Trustees/Trustees';

const BioHistoryFoundation = () => {
    const [width] = useWindowSize();
    let myRef = useRef(null);
    const state = useSelector(state => state);
    const { direction } = state;
    const dispatch = useDispatch();
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [classNamesLR, setClassNamesLF] = useState('');
    const [activeIndex, setActiveIndex] = useState(0)
    const item = FoundationData[1];


    React.useEffect(() => {
        setActiveIndex(0);
        window.scrollTo(0, 0);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn animate__slow` : `animate__animated animate__zoomIn animate__slow`
            setClassNames(cls);
            let clsLR = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInRight animate__slow` : `animate__animated animate__slideInRight animate__slow`
            setClassNamesLF(clsLR)
        }, 500)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
                setClassNamesLF('');
            }, 200)
        }
    }, [direction]);

    const handleNavigation = (name) => {
        dispatch(setCurrentPage(name));
    }

    const handleMenu = (value) => {
        setActiveIndex(value);
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <div className="foundation-content-container bg-celeste-green pt-4rem">
                <Header isBlackLogo={true} isFooter={false} isBlackMenu={true} isBlackMedia={true} menuClassNames={'title-melanzane-Violet'} isScroll={false} bgClassName={`${activeIndex === 2 ? 'bg-narvik-green' : 'bg-celeste-green'}`} />
                {classNames !== '' ?
                    <div>
                        {activeIndex === 0 ?
                            <div>
                                <Row className={`mx-0 ${width <= 1024 ? 'pt-32px' : ''}`}>
                                    <Col xs={11} sm={11} md={11} lg={{ offset: 2, size: 7 }} xl={{ offset: 2, size: 7 }} className={`position-relative ${width <= application_contants.MOBILE_BREAKPOINT ? 'm-auto' : 'ml-auto'}`}>
                                        <div>
                                            {item.bio.length > 0 && item.bio.map((it, index) => (
                                                <div key={index} className={``}>
                                                    <div className="title-fixed-position">
                                                        <h3 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h1-small align-center lh-34px' : 'align-left'} sub-section-title pb-2 ${isAnimate ? `${classNames}` : ''}`}>{it.title}</h3>
                                                    </div>
                                                    <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'pt-5rem' :'py-5'}`} ref={myRef} id="myDiv">
                                                        {it.desc.length > 0 && it.desc.map((i, ind) => (
                                                            <p key={ind} className={`p-medium sub-section-title align-left lh-26px wp-1px ${isAnimate ? `${classNames}` : ''} ${i.isBold ? 'gothamBlack' : 'gothamBook'} ${`${i.isPadding ? 'pb-4' : ''} ${`${i.isItalic ? 'fs-italic' : ''}`}`}`}>{i.params}</p>
                                                        ))}
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </Col>
                                    {width <= application_contants.MOBILE_BREAKPOINT ? null :
                                        <Col xs={12} sm={12} md={12} lg={3} xl={3} className={`${isAnimate ? `${classNamesLR}` : ''} pt-4 pb-5`}>
                                            {item.moreLinks.length > 0 && item.moreLinks.map((i, ind) => (
                                                <Row className={`mx-0 ${ind === 1 ? 'pt-4' : 0}`} key={ind}>
                                                    <Col xs={8} sm={8} md={8} lg={12} xl={12}>
                                                        <h3 className={`mb-0 h3-medium sub-section-title align-left saolStandardThin pb-2`}>{i.type}</h3>
                                                    </Col>
                                                    <Col xs={8} sm={8} md={8} lg={6} xl={5}>
                                                        <div className={`more-link-img__container`}>
                                                            <img src={i.img} alt="" className="cursor-pointer" onClick={() => handleNavigation(i.link)} />
                                                        </div>
                                                    </Col>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={8}>
                                                        <h3 className={`mb-0 h4-small sub-section-title align-left saolStandardMedium pt-2 lh-26px cursor-pointer`} onClick={() => handleNavigation(i.link)}>{i.title}</h3>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="common-width">
                                                                <img src={application_contants.LINEIMG} alt="" />
                                                            </div>
                                                            <div className="common-width">
                                                                <p className={`p-very sub-section-title align-left magdalenaAltRegular`}>
                                                                    {i.date}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>}
                                </Row>
                            </div> : null}
                        {activeIndex === 1 ?
                            <div>
                                <Trustees />
                            </div> : null}
                        {activeIndex === 2 ?
                            <div>
                                <ChairmansMessage />
                            </div>
                            : null}
                        {activeIndex === 3 ?
                            <div>
                                <DamodarTemple />
                            </div> : null}
                    </div>
                    : null}
                {classNames !== '' ?
                    <div className="bio-footer-container">
                        <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'flex-wrap p-3' : 'py-3'} d-flex align-items-center justify-content-center`}>
                            <div onClick={() => handleMenu(0)}>
                                <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex === 0 ? 'title-black gothamBlack' : 'title-stack-grey gothamBook'}`}
                                >
                                    Srila Prabhupada Foundation
                                </p>
                            </div>
                            <div onClick={() => handleMenu(1)}>
                                <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex === 1 ? 'title-black gothamBlack' : 'title-stack-grey gothamBook'}`}
                                >
                                    Our Trustees
                                </p>
                            </div>
                            <div onClick={() => handleMenu(2)}>
                                <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex === 2 ? 'title-black gothamBlack' : 'title-stack-grey gothamBook'}`}
                                >
                                    Chairman’s Message
                                </p>
                            </div>
                            <div onClick={() => handleMenu(3)}>
                                <p className={`p-medium mb-0 sub-section-section-title align-left px-2 py-2 cursor-pointer ${activeIndex === 3 ? 'title-black gothamBlack' : 'title-stack-grey gothamBook'}`}
                                >
                                    Sri Radha Damodar Temple
                                </p>
                            </div>
                        </div>
                    </div> : null}
                {width <= application_contants.MOBILE_BREAKPOINT ?
                    <Row className="mx-0 pb-4 pt-4 bg-narvik-green">
                        {item.moreLinks.length > 0 && item.moreLinks.map((i, ind) => (
                            <Col xs={12} sm={12} md={10} lg={3} xl={3} className="mx-0" key={ind}>
                                <Row className="mx-0 pb-4 align-items-end">
                                    <Col xs={4} sm={5} md={5} lg={6} xl={6}>
                                        <img src={i.img} alt="" className="cursor-pointer" onClick={() => handleNavigation(i.link)} />
                                    </Col>
                                    <Col xs={8} sm={7} md={7} lg={6} xl={6}>
                                        <h3 className={`mb-0 h3-small sub-section-title align-left saolStandardThin pb-2`}>{i.type}</h3>
                                        <h3 className={`mb-0 h5-small sub-section-title align-left saolStandardMedium pt-2 lh-26px cursor-pointer`} onClick={() => handleNavigation(i.link)}>{i.title}</h3>
                                        <div className="d-flex align-items-center">
                                            <div className={`common-width`}>
                                                <img src={application_contants.LINEIMG} alt="" />
                                            </div>
                                            <div className="common-width">
                                                <p className={`p-very-small sub-section-title align-left magdalenaAltRegular`}>
                                                    {i.date}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row> : null}
            </div>
            <Footer />
        </div>
    )
}

export default BioHistoryFoundation;