import React, { PureComponent, useState } from 'react';
import { oneOfType, node, arrayOf } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import throttle from 'lodash/throttle';
import Slide from '../Slide';
import { ContextProvider } from './Context';
import application_contants from '../../../constants/application_contant';
import { setDirection } from '../../../redux/actions/direction';
import { setCurrentPosition } from '../../../redux/actions/currentPosition';
import { setPlayStatus } from '../../../redux/actions/playStatus';

let ms;
const Carousel = (props) => {
    const state = useSelector(state => state);
    const { currentPosition, direction } = state;
    const dispatch = useDispatch();
    const { children, current, next, prev } = props;
  
    const count = children && children.length ? children.length : 0;
    //const [direction, setDirection] = useState(application_contants.NONE);
    const [currentIndex, setCurrentIndex] = useState(current !== -1 ? current : 0);
    const [nextIndex, setNextIndex] = useState(next !== -1 ? next : count ? 1 : 0);
    const [prevIndex, setPrevIndex] = useState(prev !== -1 ? prev : count ? count - 1 : 0);
    const [isScroll, setIsScroll] = useState(true);

    React.useEffect(() => {
        ms =  currentPosition.currentPosition === 0 ? 11000 : 2000;
        setCurrentIndex(currentPosition.currentPosition);
        setIsScroll(currentPosition.currentPosition === 0 ? false : true)
    },[currentPosition])

    const setNextSlide = (direction) => {
        const { children = null } = props;
        if (!children || children.length <= 1) {
            return null;
        }

        const count = children.length;
       // const { currentIndex } = this.state;
        let newIndex = 0;

        if (direction === application_contants.NONE) {
            return null;
        }

        if (direction === application_contants.FORWARD) {
            if (currentIndex < count - 1) {
                newIndex = currentIndex + 1;
            } else {
                newIndex = 0;
            }
        }
        if (direction === application_contants.BACKWARD) {
            if (currentIndex === 0) {
                newIndex = count - 1;
            } else {
                newIndex = currentIndex - 1;
            }
        }

        let newNextIndex = newIndex === count - 1 ? 0 : newIndex + 1;
        let newPrevIndex = newIndex === 0 ? count - 1 : newIndex - 1;

        dispatch(setCurrentPosition(newIndex));
        setCurrentIndex(newIndex);
        setNextIndex(newNextIndex);
        setPrevIndex(newPrevIndex)
    };

    let setNextSlideThrottled = throttle(setNextSlide, ms, {
        leading: true,
        trailing: false
    });

    const handleScroll = (e) => {
        const { deltaY } = e;
        setIsScroll(true);
        let direction = application_contants.NONE;

        if (deltaY < 0) {
            direction = application_contants.FORWARD
            dispatch(setDirection(application_contants.FORWARD));
            setIsScroll(false);
        }
        if (deltaY > 0) {
            direction = application_contants.BACKWARD
            dispatch(setDirection(application_contants.BACKWARD));
            setIsScroll(false);
        }

        dispatch(setPlayStatus(true))
        //let ms = currentPosition.currentPosition === 0 ? 12000 : 5000;
 
        setTimeout(() => {
            setNextSlideThrottled(direction);
            dispatch(setPlayStatus(false));
            setIsScroll(true);
        }, ms)

    }

    const wrappedChildren = React.Children.map(children, (child, index) => (
        <Slide
            index={index}
            currentIndex={currentIndex}
            nextIndex={nextIndex}
            prevIndex={prevIndex}
        >
            {child}
        </Slide>
    ));

    return (
        <ContextProvider value={{ currentIndex, nextIndex, prevIndex }}>
            <div
                className={'carousel__container'}
                //onWheel={(e) => isScroll ? handleScroll(e) : null}
            >
                {wrappedChildren}
            </div>
        </ContextProvider>
    )
}

Carousel.propTypes = {
    children: oneOfType([arrayOf(node), node]),
};

Carousel.defaultProps = {
    slides: []
};

export default Carousel;

// let ms;
// class Carousel extends PureComponent {
//     constructor(props) {
//         super(props);

//         const { children, current, next, prev } = props;
//         ms = this.props.currentPosition.currentPosition === 0 ? 12000 : 1000;
//         const count = children && children.length ? children.length : 0;
//         this.state = {
//             direction: application_contants.NONE,
//             currentIndex: current !== -1 ? current : 0,
//             nextIndex: next !== -1 ? next : count ? 1 : 0,
//             prevIndex: prev !== -1 ? prev : count ? count - 1 : 0,
//         };

//         this.setNextSlideThrottled = throttle(this.setNextSlide, ms, {
//             leading: true,
//             trailing: false
//         });
//     }

//     setNextSlide = (direction) => {
//         const { children = null } = this.props;
//         if (!children || children.length <= 1) {
//             return null;
//         }

//         const count = children.length;
//         const { currentIndex } = this.state;
//         let newIndex = 0;

//         if (direction === application_contants.NONE) {
//             return null;
//         }

//         if (direction === application_contants.FORWARD) {
//             if (currentIndex < count - 1) {
//                 newIndex = currentIndex + 1;
//             } else {
//                 newIndex = 0;
//             }
//         }
//         if (direction === application_contants.BACKWARD) {
//             if (currentIndex === 0) {
//                 newIndex = count - 1;
//             } else {
//                 newIndex = currentIndex - 1;
//             }
//         }

//         let newNextIndex = newIndex === count - 1 ? 0 : newIndex + 1;
//         let newPrevIndex = newIndex === 0 ? count - 1 : newIndex - 1;
//         console.log("newIndex", newIndex)
//         this.props.handleCurrentPosition(newIndex);

//         this.setState({
//             currentIndex: newIndex,
//             nextIndex: newNextIndex,
//             prevIndex: newPrevIndex,
//         });
//     };

//     handleScroll = (e) => {
//         //e.preventDefault();
//         const { deltaY } = e;

//         let direction = application_contants.NONE;

//         // setTimeout(() => {
//         if (deltaY < 0) {
//             direction = application_contants.FORWARD
//            this.props.handleSetDirection(application_contants.FORWARD)
//         }
//         if (deltaY > 0) {
//             direction = application_contants.BACKWARD
//             this.props.handleSetDirection(application_contants.BACKWARD)
//         }
//         this.props.handleSetPlay(true);
//         ms = this.props.currentPosition.currentPosition === 0 ? 12000 : 5000;
//         console.log(ms)
//         setTimeout(() => {
//             this.setNextSlideThrottled(direction);
//             this.props.handleSetPlay(false)
//           }, ms)

//     }

//     render() {
//         const { children } = this.props;
//         const { currentIndex, nextIndex, prevIndex } = this.state;

//         //let isMore = data.length > 0 ? data[currentIndex].isActive : false;

//         const wrappedChildren = React.Children.map(children, (child, index) => (
//             <Slide
//                 index={index}
//                 currentIndex={currentIndex}
//                 nextIndex={nextIndex}
//                 prevIndex={prevIndex}
//             >
//                 {child}
//             </Slide>
//         ));

//         return (
//             <ContextProvider value={{ currentIndex, nextIndex, prevIndex }}>
//                 <div
//                     className={'carousel__container'}
//                     onWheel={(e) => this.handleScroll(e)}
//                 >
//                     {wrappedChildren}
//                 </div>
//             </ContextProvider>
//         );
//     }
// }

// Carousel.propTypes = {
//     children: oneOfType([arrayOf(node), node]),
// };

// Carousel.defaultProps = {
//     slides: []
// };

// const mapStateToProps = state => ({ ...state });
// const mapDispatchToProps = dispatch => ({
//   handleSetDirection: data => dispatch(setDirection(data)),
//   handleCurrentPosition: data => dispatch(setCurrentPosition(data)),
//   handleSetPlay: data => dispatch(setPlayStatus(data)),
// });

// export default compose(connect(mapStateToProps, mapDispatchToProps))(Carousel);

