import { ActionTypes } from "../constants/action-types"


export const updateUserAuthenticated = (fullName, emailId, authenticated ) => {
    return {
        type: ActionTypes.USER_AUTHENTICATED,
        payload: {
            fullName,
            emailId,
            authenticated
        }
    }
}


export const userLogout = (fullName, emailId, authenticated ) => {
    return {
        type: ActionTypes.LOGIN_OUT,
        payload: {
            fullName,
            emailId,
            authenticated
        }
    }
}


