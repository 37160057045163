import apiConfig from "../apis/apiConfig";
import { Fetch } from "../apis/Fetch";


const PaymentService = {

    insertPayment: async (data) => {
        const { BASEURL, INSERT_PAYMENT } = apiConfig;

        const url = BASEURL + INSERT_PAYMENT;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            paymentId: `${data.paymentId}`,
            fullName: `${data.fullName}`,
            emailId: `${data.emailId}`,
            donateAmount: data.donateAmount,
            mobile: `${data.mobile}`,
            address: `${data.address}`,
            paymentSchedule: `${data.paymentSchedule}`,
            currencyType: `${data.currencyType}`,
            paymentStatus: data.paymentStatus,
            sevaId: data.sevaId,
            subSevaId: data.subSevaId,
        });


        const insertPaymentServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_insertPayment",
            true
        );
        return insertPaymentServicePromise;
    },

    getSevaTotalAmount: async (sevaId, subSevaId) => {
        const { BASEURL, GET_SEVA_AMOUNT } = apiConfig;

        const url = BASEURL + GET_SEVA_AMOUNT + `?sevaId=${sevaId}&&subSevaId=${subSevaId}`;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const getSevaTotalAmountServicePromise = await Fetch(
            url,
            "GET",
            headers,
            "",
            "services_getSevaTotalAmount",
            true
        );
        return getSevaTotalAmountServicePromise;
    },

    getPaymentList: async () => {
        const { BASEURL, GET_PAYMENT_LIST } = apiConfig;

        const url = BASEURL + GET_PAYMENT_LIST;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const getPaymentListServicePromise = await Fetch(
            url,
            "GET",
            headers,
            "",
            "services_getPaymentList",
            true
        );
        return getPaymentListServicePromise;
    },

    paymentFilters: async (data) => {
        const { BASEURL, PAYMENT_FILTERS } = apiConfig;

        const url = BASEURL + PAYMENT_FILTERS;

        const headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        });

        const params = JSON.stringify({
            startDate: `${data.startDate}`,
            endDate: `${data.endDate}`,
            sevaId: data.sevaId,
            subSevaId: data.subSevaId,
        });


        const paymentFilterServicePromise = await Fetch(
            url,
            "POST",
            headers,
            params,
            "services_PaymentFilters",
            true
        );
        return paymentFilterServicePromise;
    },

};

export default PaymentService;
