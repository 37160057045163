import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import application_contants from '../../constants/application_contant';
import { setCurrentPage } from '../../redux/actions/currentPage';
import { setCurrentPosition } from '../../redux/actions/currentPosition';
import { setDirection } from '../../redux/actions/direction';
import { setPlayStatus } from '../../redux/actions/playStatus';
import MenuData from '../../utils/json/menuData';
import SocialMedia from '../../utils/json/socialMedia';


const Header = (props) => {
    const [width] = useWindowSize();
    const [isOpen, setIsOpen] = useState(false);
    const state = useSelector(state => state);
    const { isPlay, currentPage } = state;
    const dispatch = useDispatch();
    const [isRefresh, setIsRefresh] = useState('');

    // React.useEffect(() => {
    //     setIsRefresh(!isRefresh);
    // },[isRefresh, props]);

    React.useEffect(() => {
        setIsOpen(false)
    }, [state])

    const handleMenuOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleNavigation = (name) => {
        dispatch(setCurrentPage(name));
        dispatch(setCurrentPosition(0));
        dispatch(setDirection(application_contants.FORWARD));
        dispatch(setPlayStatus(false))
        setIsOpen(!isOpen)
    }

    const handleMouseLeave = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <div className={`header--container animate__animated animate__slower ${props.isMenuAnimate ? isPlay && isPlay.isPlay ? 'animate__fadeOut' : '' : ''} ${`animate__delay-4s`} ${props.bgClassName}`}>
                <div className="common-width align-left" >
                    <img src={props.isBlackLogo ? application_contants.BLACKLOGOIMG : application_contants.LOGOIMG} alt="" className="img-item cursor-pointer" onClick={() => handleNavigation(application_contants.HOME)} />
                </div>
                <div className="common-width align-right position-relative">
                    <img src={props.isBlackLogo ? application_contants.BLACKMENUIMG : application_contants.MENUIMG} alt="" className="img-menu-icon cursor-pointer" onClick={() => handleMenuOpen()} />
                    {width <= application_contants.MOBILE_BREAKPOINT ?
                        null
                        :
                        <div>
                            {isOpen ?
                                <div className="menu-list-container" onMouseLeave={() => handleMouseLeave()}>
                                    <div>
                                        {MenuData.map((it, index) => (
                                            <div key={index} className={`animate__animated ${isOpen ? 'animate__fadeInUp' : ''} ${`animate__delay-${0.5}s`} `} onClick={() => handleNavigation(it.link)}>
                                                <p className={`p-very-small sub-section-title porterBold py-1 align-right cursor-pointer menu-item ${currentPage.name === it.name ? `${props.isBlackMenu ? 'title-stack-grey' : 'title-orinoco-green'}` : `${props.menuClassNames}`}`}>{it.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div> : null}
                        </div>}
                </div>
            </div>
            {width <= application_contants.MOBILE_BREAKPOINT && isOpen ?
                <div>
                    <div className="mobile-header-container">
                        <div>
                            <div className="menu-close-icon">
                                <img src={application_contants.TIMESIMG} alt="" onClick={() => handleMouseLeave()}/>
                            </div>
                            <div>
                                {MenuData.map((it, index) => (
                                    <div key={index} className={`animate__animated ${isOpen ? 'animate__fadeInUp' : ''} ${`animate__delay-${0.5}s`} `} onClick={() => handleNavigation(it.link)}>
                                        <h3 className={`h3-small sub-section-title porterBold p-3 align-center lh-34px cursor-pointer menu-item ${currentPage.name === it.name ? `${props.isBlackMenu ? 'title-stack-grey' : 'title-orinoco-green'}` : `title-melanzane-Violet`}`}>{it.name}</h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div> : null}
            {props.isFooter ?
                <div className={`social-media--container animate__animated  animate__slower ${props.isMenuAnimate ? isPlay && isPlay.isPlay ? 'animate__fadeOut' : '' : ''} ${`animate__delay-${4}s`} `}>
                    {SocialMedia.map((it, index) => (
                        <div key={index} >
                            <a href={it.link} target="_blank" rel="noreferrer">
                                <img src={`${props.isBlackMedia ? it.img : it.whiteImg}`} alt="" className="social-icon cursor-pointer" />
                            </a>
                        </div>
                    ))}
                </div> : null}
            {props.isScroll ?
                <div className={`info-container animate__animated  animate__slower ${props.scrollPositionClassNames} ${props.isMenuAnimate ? isPlay && isPlay.isPlay ? 'animate__fadeOut' : '' : ''} ${`animate__delay-${1}s`} `}>
                    <div className="scroll-icon">
                        <div className="dot animate__animated animate__slideInUp animate__infinite animate__slow"></div>
                    </div>
                </div> : null}
        </div>
    )
}

Header.propTypes = {
    isBlackMenu: PropTypes.bool,
    isBlackLogo: PropTypes.bool,
    isBlackMedia: PropTypes.bool,
    isMenuAnimate: PropTypes.bool,
    menuClassNames: PropTypes.string,
    scrollPositionClassNames: PropTypes.string,
    isScroll: PropTypes.bool,
    isFooter: PropTypes.bool,
    bgClassName: PropTypes.string,
};

Header.defaultProps = {
    isBlackMenu: false,
    isBlackLogo: false,
    isBlackMedia: false,
    isMenuAnimate: false,
    menuClassNames: 'title-white',
    scrollPositionClassNames: 'bt-0',
    isScroll: true,
    isFooter: true,
    bgClassName: ''
};

export default Header;