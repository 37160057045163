const PaymentMethodType = [
    {
        type: 'Domestic',
        currency: 'INR'
    },
    {
        type: 'International',
        currency: 'USD'
    }
];

export default PaymentMethodType;