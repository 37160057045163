import { useWindowSize } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import BlogHeader from '../../customComponents/BlogHeader/BlogHeader';

const ContactUs = () => {
    const [width] = useWindowSize();
    const state = useSelector(state => state);
    const { direction } = state;;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [classNamesLR, setClassNamesLR] = useState('');
    const [classNamesRL, setClassNamesRL] = useState('');

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__fadeIn`
            setClassNames(cls);
            let clsLR = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInRight` : `animate__animated animate__slideInRight`
            setClassNamesLR(clsLR)
            let clsRL = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInLeft` : `animate__animated animate__slideInLeft`
            setClassNamesRL(clsRL)
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
                setClassNamesLR("");
                setClassNamesRL('');
            }, 100)
        }
    }, [direction]);


    return (
        <div className="blog-container submission-container">
            <BlogHeader isScroll={false} isBlackLogo={true} isBlackMenu={true} menuClassNames={`title-melanzane-Violet`} />
            <div className="mt-5">
                <Row className={`mx-0 pb-5 ${width <= application_contants.MOBILE_BREAKPOINT ? 'mt-7rem' : 'mt-7rem'}`}>
                    <Col xs={10} sm={10} md={10} lg={10} xl={10} className="m-auto pt-4">
                        <h6 className="sub-section-title txt-uppercase gothamBlack align-center">
                            Coming Soon...
                        </h6>
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default ContactUs;