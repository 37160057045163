import { useWindowWidth } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import apiConfig from '../../../api/apis/apiConfig';
import PaymentService from '../../../api/services/PaymentService';
import application_contants from '../../constants/application_contant';
import Header from '../../customComponents/Header';
import { setCurrentPage } from '../../redux/actions/currentPage';
import { updateSeva } from '../../redux/actions/updateSeva';
import { getDays } from '../../utils/displayFormatter';

const SevaInfo = () => {
    const state = useSelector(state => state);
    const { direction } = state;
    const { item } = state.sevaItem;
    const list = state.sevaList.sevaList.list;
    const dispatch = useDispatch();
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [classNamesLR, setClassNamesLF] = useState('');
    const [totalDonateAmount, setTotalDonateAmount] = useState(0)
    const onlyWidth = useWindowWidth();

    React.useEffect(() => {
        getSevaTotalAmount();
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn animate__slow` : `animate__animated animate__zoomIn animate__slow`
            setClassNames(cls);
            let clsLR = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInRight animate__slow` : `animate__animated animate__slideInRight animate__slow`
            setClassNamesLF(clsLR)
        }, 500)
        return () => {

            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
                setClassNamesLF('');
            }, 200)
        }
    }, [direction, totalDonateAmount]);

    const getSevaTotalAmount = async () => {
        if (item.totalAmount > 0) {
            await PaymentService.getSevaTotalAmount(item.id, item.subSevaId ? item.subSevaId : -1)
                .then(async (res) => {
                    if (res.status === 200) {
                        try {
                            res.text().then(async (res) => {
                                let result = JSON.parse(res);
                                if (result.code === 0) {
                                    setTotalDonateAmount(result.data.totalAmount)
                                } else {
                                    console.log(`${result.message}`);
                                }
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const handleNavigation = () => {
        dispatch(setCurrentPage(application_contants.SEVADONATE));
    }

    const handleBack = (item) => {
        if (item.subSevaId !== undefined && item.subSevaId !== null && item.subSevaId !== '') {
            dispatch(setCurrentPage(application_contants.SUBSEVA));
            const subSevaItem = list.find((it) => it.id === item.id)
            dispatch(updateSeva(subSevaItem));
        } else {
            dispatch(setCurrentPage(application_contants.SEVATYPES));
        }
    }

    return (
        <div className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pt-5' : ''} seva-info-content-container pb-5 pt-4rem`}>
            <Header isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} menuClassNames={'title-melanzane-Violet'} isScroll={false} isFooter={false} bgClassName={`bg-narvik-green`}/>
            {classNames !== '' ?
                <Row className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'py-5' : ''} mx-0 align-items-center`}>
                    <Col xs={12} sm={12} md={10} lg={onlyWidth === 1270 ? 12: onlyWidth <= 1024 ? 11 : 10} xl={onlyWidth === 1270 ? 12: 10} className="m-auto">
                        <Row className="mx-0 align-items-start">
                            <Col xs={12} sm={12} md={12} lg={onlyWidth <= 1024 ? 5 : 4} xl={4} className={`${isAnimate ? `${classNames}` : ''}`}>
                                <div className="seva-info-img--container">
                                    <div className="position-relative">
                                        <div className="seva-info-img--position">
                                            <img src={`${apiConfig.URL}${item.imgPath}`} alt="" />
                                        </div>
                                        <div className="seva-info-img-title--position">
                                            <div className="position-relative">
                                                <img src={application_contants.SEVAINFOASSETS4} alt="" />
                                            </div>
                                            <div className="seva-info-title--position">
                                                <div>
                                                    <p className="p-very-small sub-section-title gothamBook fw-600 title-white text-uppercase">{item.sevaType}</p>
                                                    <p className="p-very-small sub-section-title gothamBook fw-600 title-white text-uppercase">{item.sevaTypeTitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={{ offset: onlyWidth <= 1024 ? 0 : 1, size: onlyWidth <= 1024 ? 7 : 7 }} xl={{ offset: 1, size: 7 }} className={`${isAnimate ? `${classNamesLR}` : ''} ${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pt-5rem pb-4' : ''}`}>
                                <div>
                                    {item.description !== null && item.description !== '' ?
                                        <p className="p-medium sub-section-title align-left gothamBook lh-26px fw-600">
                                            {item.description}
                                        </p> :
                                        <div>
                                            {JSON.parse(item.moreInfo).length > 0 && JSON.parse(item.moreInfo).map((m, i) => (
                                                <div key={i} className="pb-2">
                                                    <p className="p-medium sub-section-title title-light-blue align-left gothamBlack">
                                                        {m.question}
                                                    </p>
                                                    <p className="p-medium sub-section-title title-light-blue align-left gothamBook">
                                                        {m.answer}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    <div className="seva-information--container position-relative">
                                        <div className="seva-quote-container">
                                            <h4 className="sub-section-title align-left title-light-blue saolStandardRegular">{item.sectionTitle}</h4>
                                        </div>
                                        <div>
                                            <p className="p-medium sub-section-title title-light-blue align-left gothamBook">
                                                {item.sectionDescription}
                                            </p>
                                        </div>
                                        {item.totalAmount !== 0 ? null :
                                        <div className="save-info--btn-container">
                                            <div className="position-relative hover-one">
                                                <img src={application_contants.WHITEDONATEIMG} alt="" />
                                                <div className="save-info--btn-position" onClick={() => handleNavigation()}>
                                                    <p className="p-large sub-section-title title-black saolStandardMedium fw-600 text-uppercase pt-1">Donate</p>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div>
                                    {item.totalAmount !== 0 ?
                                        <Row className="mx-0 align-items-center payment-form-info--container position-relative">
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mobile-no-padding">
                                                <Row className="mx-0 align-items-start">
                                                    <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                                                        <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT || onlyWidth <= 1024 ? 'p-very-small' :'p-small'} sub-section-title gothamBook fw-600 title-white align-center p-1 br-bt-1px`}>YOUR SUPPORT IS OUR STRENGTH</p>
                                                        <div className="line-divider"></div>
                                                        <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT || onlyWidth <= 1024 ? 'p-very-small' :'p-small'} sub-section-title gothamBook fw-600 title-white align-center pt-1`}>{totalDonateAmount} raised of {item.totalAmount} goal</p>
                                                    </Col>
                                                    <Col xs={2} sm={2} md={2} lg={2} xl={2} className="position-relative">
                                                        <div className={`progress--circle progress--pie progress--${parseInt((totalDonateAmount / item.totalAmount) * 100)}`}>
                                                        </div>
                                                        <div className="pie-position">
                                                            <img src={application_contants.TIMERIMG} alt="" />
                                                        </div>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="pt-3">
                                                        <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT || onlyWidth <= 1024 ? 'p-very-small' : 'p-small'} sub-section-title gothamBook fw-600 title-white align-center`}>{`${getDays(item.startDate, item.endDate)}`} DAYS TO GO</p>
                                                        <div className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT || onlyWidth <= 1024 ? 'width_45' : 'width_38'} payment-btn--position`}>
                                                            <div className="position-relative donate-btn hover-one ml-auto">
                                                                <img src={application_contants.REDDONATEIMG} alt=""/>
                                                                <div className="donate-btn-text--position" onClick={() => handleNavigation()}>
                                                                  <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT || onlyWidth <= 1024  ? 'p-very-small' : 'p-small'} sub-section-title title-black txt-uppercase saolStandardMedium btn--small cursor-pointer pt-1`}>Donate Now</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row> : null}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                : null}
            <div className="donate-go-back-container" onClick={() => handleBack(item)}>
                <p className="p-small sub-section-title saolStandardMedium align-left fw-600 cursor-pointer">GO BACK</p>
            </div>
        </div>
    )
};


export default SevaInfo