import React from 'react';
import Banner from './Component/Banner';

const Foundation = (props) => {
    return (
        <div>
            <Banner />
        </div>
    )
}

export default Foundation;