import application_contants from "../../constants/application_contant";

const AnniversaryCelebrationData = [
    {
        year: '125',
        sectionImg: application_contants.PRABHUANNIVERSARYIMG,
        title: 'Anniversary',
        subTitle: 'Celebration',
        section: 4,
    },
    {
        title: '125th Anniversary Celebration',
        img: application_contants.BRITHANNIVERSARYIMG,
        info: [
            {
                desc: `September 1, 2021, was a significant day in the timeline of ISKCON, as we celebrated the 125th Birth Anniversary of our Founder-Acharya, His Divine Grace A.C. Bhaktivedanta Swami Prabhupada. It was a blissful celebration across all ISKCON temples as devotees performed a grand abhishek to Srila Prabhupada and offered homages unto him. Honourable Prime Minister of India, Sri Narendra Modi released a special commemorative coin of ₹ 125 on this momentous occasion.`
            },
            {
                desc: `Srila Prabhupada’s Birth Anniversary was celebrated in various parts of the world with great enthusiasm. A magnificent event was organised in Times Square, New York, a popular tourist attraction in the United States. ISKCON temples across the globe have planned various projects throughout the year to celebrate Srila Prabhupada’s quasquicentennial anniversary and his exemplary life. Special discourses on Srila Prabhupada’s teachings will be delivered and books on ISKCON’s institutional history will be published. His biography will be released in English, Hindi and six regional languages. Various symposiums will be held to spread and articulate the unique solutions offered by Srila Prabhupada for the welfare of society. A new website www.srilaprabhupada.in describing his contributions will be launched in his honour. Srila Prabhupada's books will be placed in all the libraries of Kerala to increase awareness among people about his life and teachings.`
            },
            {
                desc: `New centres will be opened in different countries to continue Srila Prabhupada’s legacy and benefit many more people. Sri Srinivasa Govinda temple at Vaikunta Hill, Bangalore and the first phase of Vrindavan Chandrodaya Mandir, the upcoming tallest temple in the world, will be inaugurated this year. A historic museum will be dedicated to Srila Prabhupada in Vrindavan, housing his personal articles, gifts, manuscripts, memorabilia and other artefacts. ISKCON will also undertake the renovation of the medieval temple and the construction of a new library at Radha Damodara Mandir, Vrindavan.`
            },
            {
                desc: `Srila Prabhupada’s achievements were commended by eminent personalities like Prime Minister Sri Narendra Modi, Dr. Mohan Bhagawat, Hindol Sengupta, D.V. Sadananda Gowda, Dr. Ashwathnarayan, Tejasvi Surya and others.`
            },
            {
                desc: `Hon. Prime Minister Sri Narendra Modi said, “Prabhupada Swami was a transcendental devotee of Lord Krishna. Srila Prabhupada and ISKCON took bhakti yoga to the world. At the age of 70 he started the world wide ISKCON movement. He presented to the world India's invaluable spiritual knowledge, cultural values and tradition.”`
            },
            {
                desc: `Hindol Sengupta, a well-known historian and journalist tweeted, “For anyone who is a writer, the legacy of Srila Prabhupada is awe-inspiring. The movement, ISKCON, he launched has, at last count, distributed half a billion copies. It is one of the world's great publishing ventures. Today that same spirit is in @AkshayaPatra in food distribution.”`,
            },
            {
                desc: `John Straton Hawley, an American author wrote in his message, “Srila Prabhupada, the ambassador from Vrindavan was a celebrated player in New York history. There he opened a new chapter in the annals of religious conversion and conversation. Prabhupad’s influence has also touched me on the other side of the world, in Vrindavan. There I have recently experienced the hospitality of the Vrindavan Chandrodaya Mandir and had the chance to see how its creators understand themselves to be fulfilling a vision that initially belonged to Prabhupad—a skyscraper for Krishna.”`
            },
            {
                desc: `Dr. Mohan Bhagwat, Sarsangchalak (RSS) offered in his tribute, “Pujya Sri  Bhaktivedanta Swami Prabhupada, the founder of ISKCON, a bhakti-based worldwide movement, has spread Krishna Bhakti in a very beautiful and joyful form throughout the world in modern times. Following the Vasudhaiva Kutumbakam tradition of Sanatan Dharma, he respiritualized the entire world through his discourses, writings and followers.”`
            },
            {
                desc: `Sri Madhu Pandit Dasa, President – ISKCON Bangalore responded to people’s wishes, “I express my heartfelt gratitude to everyone for remembering Srila Prabhupada and offering glowing tributes on his 125th Birth Anniversary. We are enthused to increase our services to humanity based on Srila Prabhupada's spiritual vision for the welfare of the world.”`,
            },
            {
                desc: `Srila Prabhupada is the greatest ambassador of India’s spiritual culture, philosophy and heritage in the modern era. He undertook an arduous journey on a steamship from Calcutta to New York City at the advanced age of sixty-nine to introduce India’s message of peace and goodwill to the West. In just twelve years, he circled the globe fourteen times on lecture tours that took him to six continents, established more than a hundred Krishna temples, a publishing house, educational institutions and farm communities. He prolifically authored over 70 volumes of bhakti literature based on ancient sacred texts of Bhagavad Gita, Srimad Bhagavatam and Caitanya Charitamrita. Highly respected by scholars for their authority, depth, and clarity, his books are used as textbooks in numerous college courses. His writings have been translated into over fifty languages.`,
            },
            {
                desc: `Srila Prabhupada built a house in which the whole world could live. He worked tirelessly to uplift everyone, regardless of age, nationality, religion, gender, caste, or social status. He practically demonstrated how we can unite in peace, friendship and prosperity by realising the nature of the human spirit. His message of universal love continues to resonate everywhere, transforming hearts even today.`,
            }
        ],
        moreLinks: [
            {
                index: 1,
                img: application_contants.ASSETS16,
                title: `A lifetime in preparation`,
                type: 'Recommended',
                date: '31 August 2021',
                link: application_contants.RECOMMEND
            }
        ],
    },
];


export default AnniversaryCelebrationData;