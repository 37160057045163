
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import store from '../redux/store';
import './App.scss';
import RoutesComponent from './RoutesComponent';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <RoutesComponent/>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
