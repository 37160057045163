import React, { useState } from 'react'
import { Row, Col, Button, Form, Label, FormGroup, Input } from 'reactstrap';
import Axios from "axios";
import Select from "react-select";
import apiConfig from '../../../api/apis/apiConfig';
import BlogService from '../../../api/services/BlogService';
import Loader from '../Loader/Loader';
import Strip from '../Strip/Strip';
import BlogStatus from '../../utils/json/BlogStatus';

const BlogSubmissionReview = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [articalName, setArticalName] = useState('');
    const [fileThumbnailFileName, setFileThumbnailFileName] = useState('');
    const [fileThumbnailFile, setFileThumbnailFile] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [id, setId] = useState('');
    const [status, setStatus] = useState(BlogStatus[0]);
    const { handleToggle, isAdd, item, handleRefresh } = props;

    React.useEffect(() => {
        if (isAdd) {
            setId('');
            setIsFileChanged(false);
            setFileThumbnailFile('');
            setFileThumbnailFileName('');
            setFirstName('');
            setEmailId('');
            setArticalName('');
        } else {
            setId(item.id);
            setFirstName(item.firstName);
            setEmailId(item.emailId);
            setArticalName(item.articalName);
            setFileThumbnailFileName(item.filePath);
            const value = BlogStatus.find((it) => it.value === item.isApproval);
            setStatus(value);
        }
    }, [isAdd, item])

    const handleStatus = (item) => {
        setStatus(item)
    }

    const handleSubmit = async () => {
        let data = {
            firstName: firstName,
            emailId: emailId,
            articalName: articalName,
            filePath: fileThumbnailFileName,
            isApproval: status ? status.value : 0,
            id: id,
        };
        insertOrUpdate(data);
    }


    const insertOrUpdate = async (data) => {
        await BlogService.insertOrUpdateBlogSubmission(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alert(`${result.message}`);
                                // alertSuccessMessage(`${result.message}`);
                                setIsLoading(false);
                                handleRefresh();

                            } else {
                                setIsLoading(false);
                                alert(`${result.message}`)
                                //alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }


    return (
        <Strip
            id="tst-add-banner-video"
            className="strip strip-no-padding"
            containerType="container admin-container box-shadow"
        >
            <Row className="mx-0 pt-3 pb-3 align-items-center bg-light-blue br-lr-8px">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h6 className="sub-section-title title-white txt-uppercase gothamBlack align-left">
                        {isAdd ? 'Add' : 'Review Submission Blog'}
                    </h6>
                </Col>
            </Row>
            <Row className="mx-0 pt-3 pb-3 align-items-center">
                <Col xs={12} sm={12} md={12} lg={8} xl={8} className="m-auto">
                    <Form>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    First Name* :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="firstName"
                                    id="firstName"
                                    name="firstName"
                                    className="form-control common-form-control"
                                    value={firstName}
                                    readOnly
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Email Id* :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="emailId"
                                    id="emailId"
                                    name="emailId"
                                    className="form-control common-form-control"
                                    value={emailId}
                                    readOnly
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Artical Name* :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="articalName"
                                    id="articalName"
                                    name="articalName"
                                    className="form-control common-form-control"
                                    value={articalName}
                                    readOnly
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Artical Name* :
                                </Label>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <iframe title={articalName} src={`https://view.officeapps.live.com/op/embed.aspx?src=${apiConfig.URL}${fileThumbnailFileName}&embedded=true`} width="100%" height="100%"></iframe>
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Status* :
                                </Label>
                                <Select
                                    value={status}
                                    onChange={handleStatus}
                                    options={BlogStatus}
                                    placeholder={"Select"}
                                    classNamePrefix="common-select-drop-down"
                                    isSearchable={false}
                                    isClearable
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8} className="m-auto">
                    <Row className="no-margin py-2 mt-4 mx-0">
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={3}
                            className={`${isLoading ? 'm-auto' : 'm-auto'}`}
                        >
                            {!isLoading ?
                                <Row>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-theme-btn custom-btn--small"
                                            onClick={handleToggle}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-blue-btn custom-btn--small"
                                            onClick={handleSubmit}
                                        //disabled={(videoUrl || videoFileName) && videoThumbnailFileName ? false : true}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row> : null}
                            {isLoading ? (
                                <div className="d-flex align-item-center justify-content-center">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Strip>
    )
}


export default BlogSubmissionReview;

