const apiConfig = {
    GET_SEVA_LIST: 'getSevaList',
    INSERT_PAYMENT: 'insert-payment',
    GET_SEVA_AMOUNT:'getSevaAmount',
    LOGIN: 'login',
    INSERT_OR_UPDATE_USER: 'insertOrUpdateUser',
    GET_PAYMENT_LIST: 'getPaymentList',
    PAYMENT_FILTERS: 'payment-filters',
    GET_SUB_SEVA_LIST:'getSubSevaList',
    GET_BLOG_LIST:'getBlogList',
    GET_BLOG_VIDEO_LIST: 'getBlogVideoList',
    GET_BLOG_IMG_LIST: 'getBlogImgList',
    INSERT_OR_UPDATE_BANNER_VIDEO: 'insertOrUpdateBannerVideo',
    INSERT_OR_UPDATE_BANNER_IMG: 'insertOrUpdateBannerImg',
    GET_BLOG_INFO: 'getBlogInfo',
    GET_BLOG_SUBMISSION_LIST:'getSubmissionsBlogInfo',
    INSERT_OR_UPDATE_SUBMISSION_BLOG: 'insertOrUpdateSubmissionsBlog',
    INSERT_OR_UPDATE_SEVA: 'insertOrUpdateSeva',
    INSERT_OR_UPDATE_SUBSEVA: 'insertOrUpdateSubSeva',
    
    //Test Server
    // URL: 'http://52.66.63.33',
    // BASEURL: 'http://52.66.63.33:5005/v1/',

    //Prod Server 3.109.26.26

   URL: 'https://api.srilaprabhupada.in',
   BASEURL: 'https://api.srilaprabhupada.in:5000/v1/',
};

export default apiConfig;