import { ActionTypes } from "../constants/action-types"

const initialState = {
    currentPosition: 0,
}


export const currentPostionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_CURRENTPOSITION:
            return { ...state, currentPosition: payload };
        default:
            return state;
    }
}

