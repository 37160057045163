import { useWindowSize, useWindowWidth } from '@react-hook/window-size';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import ChairmansMessageData from '../../utils/json/chairmansMessageData';

const ChairmansMessage = (props) => {
    const [width] = useWindowSize();
    let myRef = useRef(null);
    const state = useSelector(state => state);
    const { isPlay, direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [classNamesRL, setClassNamesRL] = useState('');
    const [classNamesIO, setClassNamesIO] = useState('');
    const onlyWidth = useWindowWidth();

    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated  animate__slideInLeft` : `animate__animated animate__slideInRight`
            setClassNames(cls);
            let clsRL = direction.direction === application_contants.FORWARD ? `animate__animated  animate__slideInRight` : `animate__animated animate__slideInLeft`
            setClassNamesRL(clsRL);
            let clsIO = direction.direction === application_contants.FORWARD ? `animate__animated  animate__fadeIn` : `animate__animated animate__fadeIn`
            setClassNamesIO(clsIO);
        }, 500)
        return () => {

            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
                setClassNamesRL('');
                setClassNamesIO('');
            }, 200)
        }
    }, [direction])


    return (
        <div className="chairman-content-container py-5 pt-4">
            <Row className={`mx-0 container-fluid ${width <= 1024 ? 'pt-32px' : ''}`}>
                {classNames !== '' ?
                    <Col xs={12} sm={12} md={12} lg={11} xl={11} className="m-auto">
                        {ChairmansMessageData.length > 0 && ChairmansMessageData.map((it, index) => (
                            <Row className="mx-0" key={index}>
                                <Col xs={12} sm={12} md={10} lg={4} xl={4} className="mb-auto">
                                    <Row className={`mx-0 ${isAnimate ? onlyWidth === 1204 ? '' : classNames : ''}`}>
                                        <Col xs={12} sm={12} md={12} lg={width <= 1024 ? 11 : 8} xl={8} className="m-auto">
                                            <img src={it.imgPath} alt="" />
                                            <div>
                                                <h3 className="sub-section-title align-center title-stack-grey">{it.name}</h3>
                                                <p className="p-medium sub-section-title align-center gothamBook">{it.designation},</p>
                                                <p className="p-medium sub-section-title align-center gothamBook">{it.foundation}</p>
                                            </div>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={8} xl={8} className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'pt-5' : ''}`}>
                                    <div className="position-relative">
                                        <div className="title-fixed-position1">
                                            <h1 className={`h1-small sub-section-title align-left title-stack-grey py-3 ${isAnimate ? `${classNamesIO} animate__slower` : ''}`}>{it.title}</h1>
                                        </div>
                                        <div className={`${isAnimate ? classNamesRL : ''}`}>
                                            <div className="py-5 pt-1" ref={myRef} id="myDiv">
                                                {it.description.length > 0 && it.description.map((i, ind) => (
                                                    <p key={ind} className={`p-medium sub-section-title align-left lh-26px gothamBook pb-3 wp-1px ${isAnimate ? `${classNames}` : ''}`}>{i.params}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${isAnimate ? `${classNamesIO} animate__slower` : ''}`}>
                                        <p className="p-medium sub-section-title align-right gothamBook">{it.signatureText}</p>
                                        <Row>
                                            <Col xs={8} sm={8} md={6} lg={4} className="ml-auto">
                                                <img src={it.signatureImg} alt="" />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </Col> : null}
            </Row>
        </div>
    )
}

export default ChairmansMessage;