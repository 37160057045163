import { forwardStyleMediumZoom, forwardStyleSmallZoom } from "../../constants/animation_style";
import application_contants from "../../constants/application_contant";

const SlideData = [
    {
        fwVideo: application_contants.FORWARDVIDEO,
        bwVideo: application_contants.BACKWARDVIDEO,
        posterImg: application_contants.POSTERIMG,
        isVideo: true,
    },
    {
        list: [
            {
                title: "Srila Prabhupada",
                link: "Srila Prabhupada",
                img: application_contants.SECTIONIMG1,
                desc: `A great visionary,
                spiritual ambassador,
                cultural reformer, and a pure
                devotee of`,
                subDesc: `Lord Krishna`,
                arrowImg: application_contants.ARROWIMG,
                forwardStyle: forwardStyleSmallZoom,
                backwardStyle: forwardStyleMediumZoom,
                boxShadowImg: application_contants.BOXSHADOWIMG,
                classNames: 'animate__faster'
            },
            {
                title: "Srila Prabhupada Foundation",
                link: 'Srila Prabhupada Foundation',
                img: application_contants.SECTIONIMG2,
                desc: `Building a home in which
                the whole world can live`,
                arrowImg: application_contants.ARROWIMG,
                forwardStyle: forwardStyleSmallZoom,
                backwardStyle: forwardStyleMediumZoom,
                boxShadowImg: application_contants.BOXSHADOWIMG,
                classNames: 'animate__fast'
            },
            {
                title: 'Projects & Initiatives',
                link: 'Projects & Initiatives',
                img: application_contants.SECTIONIMG3,
                desc: `Preserving the glorious legacy
                and sacred memory of`,
                subDesc: `Srila Prabhupada`,
                arrowImg: application_contants.ARROWIMG,
                forwardStyle: forwardStyleMediumZoom,
                backwardStyle: forwardStyleMediumZoom,
                boxShadowImg: application_contants.BOXSHADOWIMG,
                classNames: 'animate__slow'
            },
            {
                title: 'Blog',
                link: 'Blog',
                img: application_contants.SECTIONIMG4,
                desc: `The Human Spirit - Official blog
                of Srila Prabhupada Foundation`,
                arrowImg: application_contants.ARROWIMG,
                forwardStyle: forwardStyleSmallZoom,
                backwardStyle: forwardStyleSmallZoom,
                boxShadowImg: application_contants.BOXSHADOWIMG,
                classNames: 'animate__slower'
            },
            {
                title: 'Donate',
                link: 'Donate',
                img: application_contants.SECTIONIMG5,
                desc: `Help us to bring people closer to
                Srila Prabhupada`,
                arrowImg: application_contants.ARROWIMG,
                forwardStyle: forwardStyleSmallZoom,
                backwardStyle: forwardStyleSmallZoom,
                boxShadowImg: application_contants.BOXSHADOWIMG,
                classNames: 'animate__slower'
            },
        ],
        isVideo: false,
        forwardStyle: forwardStyleMediumZoom,
        backwardStyle: forwardStyleMediumZoom,
    },
]

export default SlideData;