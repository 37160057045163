
import SevaService from "../../../api/services/SevaService";
import { ActionTypes } from "../constants/action-types"

const initialState = {
};

const initialStateForSeva = {
    sevaList: []
}

export const fetchSeva = () => async (dispatch) => {
    await SevaService.getSevaList(1)
        .then(async (res) => {
            if (res.status === 200) {
                try {
                    res.text().then(async (res) => {
                        let result = JSON.parse(res);
                        if (result.code === 0) {
                            let data = result.data;
                            dispatch({ type: ActionTypes.FETCH_SEVA, payload: data ? data : [] })
                        } else {
                            console.log(`${result.message}`);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

export const sevaListReducer = (state = initialStateForSeva, { type, payload }) => {
    switch (type) {
        case ActionTypes.FETCH_SEVA:
            return { ...state, sevaList: payload };
        default:
            return state;
    }
}

export const updateSevaReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.UPDATE_SEVA:
            return { ...state, item: payload };
        default:
            return state;
    }
}

