import { useWindowSize, useWindowWidth } from '@react-hook/window-size';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import Footer from '../../customComponents/Footer/Footer';
import Header from '../../customComponents/Header';
import { setCurrentPage } from '../../redux/actions/currentPage';
import anniversaryCelebrationData from '../../utils/json/anniversaryCelebrationData';


const AnniversaryCelebration = () => {
    let myRef = useRef(null);
    const state = useSelector(state => state);
    const { direction } = state;
    const dispatch = useDispatch();
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [classNamesLR, setClassNamesLF] = useState('');
    const [data, setData] = useState([])
    const [width] = useWindowSize();

    React.useEffect(() => {
        let item = [];
        item.push(anniversaryCelebrationData[1])
        setData(item);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn animate__slow` : `animate__animated animate__zoomIn animate__slow`
            setClassNames(cls);
            let clsLR = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInRight animate__slow` : `animate__animated animate__slideInRight animate__slow`
            setClassNamesLF(clsLR)
        }, 500)
        return () => {

            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
                setClassNamesLF('');
            }, 200)
        }
    }, [direction]);

    const handleNavigation = (name) => {
        dispatch(setCurrentPage(name));
    }

    return (
        <div>
            <div className="bio-content-container pt-4rem bg-narvik-green">
                <Header isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} menuClassNames={'title-melanzane-Violet'} isScroll={false} isFooter={false} bgClassName={`bg-narvik-green`} />
                {classNames !== '' ?
                    <Row className={`mx-0 align-items-start ${width <= 1024 ? 'pt-32px' : ''}`}>
                        <Col xs={11} sm={11} md={11} lg={{ offset: 2, size: 7 }} xl={{ offset: 2, size: 7 }} className={`position-relative ${width <= application_contants.MOBILE_BREAKPOINT ? 'm-auto' : 'ml-auto'}`}>
                            <div className="scroll-content-container1">
                                {data.length > 0 && data.map((it, index) => (
                                    <div key={index} className={``}>
                                        <div>
                                            <div className={`${isAnimate ? `${classNames}` : ''}`}>
                                                <img src={it.img} alt="" className="width-25" />
                                            </div>
                                            <h3 className={`sub-section-title align-left pb-2 ${isAnimate ? `${classNames}` : ''}`}>{it.title}</h3>
                                        </div>
                                        <div className="pb-4" ref={myRef} id="myDiv">
                                            {it.info.length > 0 && it.info.map((i, ind) => (
                                                <p key={ind} className={`p-medium sub-section-title align-left gothamBook pb-4 lh-26px wp-1px ${isAnimate ? `${classNames}` : ''}`}>{i.desc}</p>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                        {width <= application_contants.MOBILE_BREAKPOINT ?
                            <Col xs={12} sm={12} md={12} lg={3} xl={3} className={`${isAnimate ? `${classNamesLR}` : ''}`}>
                                <Row className="mx-0 pb-4 pt-4">
                                    {data.length > 0 && data[0].moreLinks.map((i, ind) => (
                                        <Col xs={12} sm={12} md={10} lg={3} xl={3} className="mx-0" key={ind}>
                                            <Row className="mx-0 pb-4 align-items-end">
                                                <Col xs={4} sm={5} md={5} lg={6} xl={6}>
                                                    <img src={i.img} alt="" className="cursor-pointer" onClick={() => handleNavigation(i.link)} />
                                                </Col>
                                                <Col xs={8} sm={7} md={7} lg={6} xl={6}>
                                                    <h3 className={`mb-0 h3-small sub-section-title align-left saolStandardThin pb-2`}>{i.type}</h3>
                                                    <h3 className={`mb-0 h5-small sub-section-title align-left saolStandardMedium pt-2 lh-26px cursor-pointer`} onClick={() => handleNavigation(i.link)}>{i.title}</h3>
                                                    <div className="d-flex align-items-center">
                                                        <div className={`common-width`}>
                                                            <img src={application_contants.LINEIMG} alt="" />
                                                        </div>
                                                        <div className="common-width">
                                                            <p className={`p-very-small sub-section-title align-left magdalenaAltRegular`}>
                                                                {i.date}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                            :
                            <Col xs={12} sm={12} md={12} lg={3} xl={3} className={`${isAnimate ? `${classNamesLR}` : ''}`}>
                                {data.length > 0 && data[0].moreLinks.map((i, ind) => (
                                    <Row className="mx-0 pt-5" key={ind}>
                                        <Col xs={8} sm={8} md={8} lg={12} xl={12}>
                                            <h3 className={`mb-0 h3-medium sub-section-title align-left saolStandardThin pb-2`}>{i.type}</h3>
                                        </Col>
                                        <Col xs={8} sm={8} md={8} lg={6} xl={5}>
                                            <div className={`more-link-img__container`}>
                                                <img src={i.img} alt="" className="cursor-pointer" onClick={() => handleNavigation(i.link)} />
                                            </div>
                                        </Col>
                                        <Col xs={10} sm={10} md={10} lg={10} xl={8}>
                                            <h3 className={`mb-0 h4-small sub-section-title align-left saolStandardMedium pt-2 lh-26px cursor-pointer`} onClick={() => handleNavigation(i.link)} >{i.title}</h3>
                                        </Col>
                                        <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                                            <div className="d-flex align-items-center">
                                                <div className="common-width">
                                                    <img src={application_contants.LINEIMG} alt="" />
                                                </div>
                                                <div className="common-width">
                                                    <p className={`p-very sub-section-title align-left magdalenaAltRegular`}>
                                                        {i.date}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        }
                    </Row>
                    : null}
            </div>
            <Footer />
        </div>
    )
};


export default AnniversaryCelebration