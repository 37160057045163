import React from "react";
import memoizeOne from "memoize-one";
import { Button } from "reactstrap";
import apiConfig from "../../../../api/apis/apiConfig";

const TablesColumns = memoizeOne((handleDelete, handleEdit) => [
    {
        name: "Image URL",
        grow: 0.85,
        selector: "imgPath",
        sortable: false,
        cell: (row) => <div className="py-1">
            <img src={`${apiConfig.URL}${row.imgPath}`} alt="" className="width-25"/>
        </div>,
    },
    {
        name: "Title",
        selector: "title",
        sortable: false,
        grow: 1.5,
        cell: (row) => <div className="py-1"><p className={`p-medium-small sub-section-title gothamBook`}>{row.title}</p></div>,
    },
    {
        name: "Reference Name",
        selector: "reference",
        sortable: false,
        grow: 1.5,
        cell: (row) => <div className="py-1"><p className={`p-medium-small sub-section-title gothamBook`}>{row.reference}</p></div>,
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        cell: (row) => <div className="py-1"><p className={`p-medium-small sub-section-title gothamBook ${row.status === 1 ? 'title-green fw-600' : 'title-danger-light fw-600'}`}>{`${row.status === 1 ? 'Active' : 'InActive'}`}</p></div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        ignoreRowClick: true,
        allowOverflow: true,
        cell: (row) => (
            <div className="d-flex py-1">
                <div onClick={() => handleEdit(row)}>
                    <Button className="btn btn-small custom-green-btn custom-btn--small fs-10px">Edit</Button>
                </div>
                <div onClick={() => handleDelete(row)} className="ml-12px">
                    <Button className="btn btn-small custom-theme-btn custom-btn--small fs-10px">Delete</Button>
                </div>
            </div>
        ),
    },
]);

export default TablesColumns;
