import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import apiConfig from '../../../api/apis/apiConfig';
import { useWindowWidth } from '@react-hook/window-size';
import application_contants from '../../constants/application_contant';
import Header from '../../customComponents/Header';
import { setCurrentPage } from '../../redux/actions/currentPage';
import { updateSeva } from '../../redux/actions/updateSeva';

const SevaTypes = () => {
    const onlyWidth = useWindowWidth()
    const state = useSelector(state => state);
    const { direction } = state;
    const list = state.sevaList.sevaList.list;
    const dispatch = useDispatch();
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn animate__slow` : `animate__animated animate__zoomIn animate__slow`
            setClassNames(cls);
        }, 500)
        return () => {

            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 200)
        }
    }, [direction]);


    const handleNavigation = (it) => {
        dispatch(updateSeva(it));
        dispatch(setCurrentPage(it.subSeva.length > 0 ? application_contants.SUBSEVA : application_contants.SEVAINFO));
    }

    const handleBack = () => {
        dispatch(setCurrentPage(application_contants.DONATE));
    }

    return (
        <div>
            <Header isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} menuClassNames={'title-melanzane-Violet'} isScroll={false} isFooter={false} bgClassName={`bg-narvik-green`} />

            <div className="seva-content-container py-5 pt-4rem">
                {classNames !== '' ?
                    <Row className="mx-0 align-items-center pt-5">
                        <Col xs={12} sm={12} sevaListmd={10} lg={10} xl={10} className="m-auto">
                            <Row className="mx-0 align-items-center">
                                {list && list.length > 0 && list.map((it, index) => (
                                    <Col xs={9} sm={9} md={6} lg={onlyWidth === 1270 ? 6 : onlyWidth === 1024 ? 6 : 3} xl={onlyWidth === 1270 ? 6 : onlyWidth === 1024 ? 6 : 3} className={`position-relative pb-5 ${isAnimate ? `${classNames} animate__faster` : ''} ${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'm-auto' : ''}`} key={index}>
                                        <div className="seva-img-list-contianer">
                                            <div className="seva-img--container">
                                                <div className="position-relative">
                                                    <div className="seva-img--position">
                                                        <LazyLoadImage
                                                            alt={''}
                                                            src={`${apiConfig.URL}${it.imgPath}`} />
                                                    </div>
                                                    <div className="seva-btn--container">
                                                        <img src={application_contants.SEVAASSETS2} alt="" />
                                                        <div className="seva-title--position">
                                                            <div>
                                                                <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'p-smallest' : 'p-small'} sub-section-title gothamBook fw-600 text-uppercase`}>{it.sevaType}</p>
                                                                <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'p-smallest' : 'p-small'} sub-section-title gothamBook fw-600 text-uppercase`}>{it.sevaTypeTitle}</p>
                                                            </div>
                                                        </div>
                                                        <div className="seva-btn--position" onClick={() => handleNavigation(it)}>
                                                            <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'p-small title-white' : 'p-small'} sub-section-title saolStandardMedium`}>Donate Now</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                    : null}
                <div className="donate-go-back-container" onClick={() => handleBack()}>
                    <p className="p-small sub-section-title saolStandardMedium align-left fw-600 cursor-pointer">GO BACK</p>
                </div>
            </div>
        </div>
    )
};


export default SevaTypes