import application_contants from "../../constants/application_contant";

const TrusteesData = [
    {
        imgPath: application_contants.TRUSTESSIMG1,
        name: 'Madhu Pandit Dasa',
        designation: 'Chairman & Trustee'
    },
    {
        imgPath: application_contants.TRUSTESSIMG2,
        name: 'Chanchanapathi Dasa',
        designation: 'Trustee'
    },  
    {
        imgPath: application_contants.TRUSTESSIMG3,
        name: 'Suvyakta Narasimha Dasa',
        designation: 'Trustee'
    },
    {
        imgPath: application_contants.TRUSTESSIMG4,
        name: 'Siddhanta Dasa',
        designation: 'Trustee'
    }
];


export default TrusteesData