import { ActionTypes } from "../constants/action-types"

const initialState = {

}


export const blogItemReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_BLOG_ITEM:
            return { ...state, item: payload };
        case ActionTypes.FETCH_BLOG:
            return { ...state, item: payload };
        default:
            return state;
    }
}



export const blogReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.FETCH_BLOG:
            return { ...state, item: payload };
        default:
            return state;
    }
}






