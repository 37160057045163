import React, { useState } from 'react'
import { Row, Col, Button, Form, Label, FormGroup } from 'reactstrap';
import Axios from "axios";
import apiConfig from '../../../api/apis/apiConfig';
import BlogService from '../../../api/services/BlogService';
import FileUploader from '../FileUploader/FileUploader';
import Loader from '../Loader/Loader';
import Strip from '../Strip/Strip';

const AddBlogBannerVideo = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [videoFileName, setVideoFileName] = useState('');
    const [videoFile, setVideoFile] = useState('');
    const [videoThumbnailFileName, setVideoThumbnailFileName] = useState('');
    const [videoThumbnailFile, setVideoThumbnailFile] = useState('');
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isThumbnailFileChanged, setIsThumbnailFileChanged] = useState(false);
    const [id, setId] = useState('');
    const { handleToggle, isAdd, item, handleRefresh } = props;

    React.useEffect(() => {
        if (isAdd) {
            setId('');
            setVideoFile('');
            setVideoFileName('');
            setIsFileChanged(false);
            setIsThumbnailFileChanged(false);
            setVideoUrl('');
            setVideoThumbnailFile('');
            setVideoThumbnailFileName('');
        } else {
            setId(item.id)
            setVideoFileName(item.videoPath);
            setVideoThumbnailFileName(item.thumbnailImg);
            setVideoUrl(item.videoUrl);
        }
    }, [isAdd, item])


    const getVideo = async (item) => {
        await setVideoFile(item[0].file);
        await setVideoFileName(item[0].file.name);
        setIsFileChanged(true);
    }

    const getThumbnail = async (item) => {
        await setVideoThumbnailFile(item[0].file);
        await setVideoThumbnailFileName(item[0].file.name);
        setIsThumbnailFileChanged(true);
    }

    const handleVideoUrl = (event) => {
        setVideoUrl(event.target.value)
    }

    const handleSubmit = async () => {
        const userId = await localStorage.getItem('userId')
        let data = {
            videoPath: isFileChanged ? `/blog-assets/video/${videoFileName}` : videoFileName,
            videoUrl: '',
            id: id,
            thumbnailImg: isThumbnailFileChanged ? `/blog-assets/video/${videoThumbnailFileName}` : videoThumbnailFileName,
            userId: userId,
            status: 1,
        };

        if (isFileChanged && isThumbnailFileChanged) {
            let files = [];
            files.push(videoThumbnailFile);
            files.push(videoFile)
            UploadVideo(files, data)
        } else if (isFileChanged) {
            let files = [];
            files.push(videoFile)
            UploadVideo(files, data)
        } else if (isThumbnailFileChanged) {
            let files = [];
            files.push(videoThumbnailFile)
            UploadVideo(files, data)
        }else {
            insertOrUpdate(data);
        }
    }

    const UploadVideo = async (files, insertOrUpdateData) => {
        const data = await new FormData();
        await data.append("floderPath", "uploads/video/");
        await files.map((item) => {
            data.append("image", item);
        });
        setIsLoading(true);

        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        // console.log('photos : ', data);
        Axios.post(`${apiConfig.BASEURL}upload`, data, config)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        if (res.data.code === 0) {
                            await insertOrUpdate(insertOrUpdateData);
                        } else {
                            setIsLoading(false);
                            alert(`${res.message}`)
                            // alertDangerConfirmMessage(`${res.data.message}`);
                        }
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log("We will be back soon.");
                return err;
            });
    };

    const insertOrUpdate = async (data) => {
        await BlogService.insertOrUpdateBlogVideo(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                alert(`${result.message}`)
                                // alertSuccessMessage(`${result.message}`);
                                handleRefresh();
                                setIsLoading(false);

                            } else {
                                setIsLoading(false);
                                alert(`${result.message}`)
                                //alertDangerConfirmMessage(`${result.message}`);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    return (
        <Strip
            id="tst-add-banner-video"
            className="strip strip-no-padding"
            containerType="container admin-container box-shadow"
        >
            <Row className="mx-0 pt-3 pb-3 align-items-center bg-light-blue br-lr-8px">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h6 className="sub-section-title title-white txt-uppercase gothamBlack align-left">
                        {isAdd ? 'Add Banner Video' : 'Update Banner Video'}
                    </h6>
                </Col>
            </Row>
            <Row className="mx-0 pt-3 pb-3 align-items-center">
                <Col xs={12} sm={12} md={12} lg={8} xl={8} className="m-auto">
                    <Form>
                        {/* <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Video URL :
                                </Label>
                                <Input
                                    type="text"
                                    placeholder="Title"
                                    id="Title"
                                    name="Title"
                                    className="form-control common-form-control"
                                    value={videoUrl}
                                    onChange={event => handleVideoUrl(event)}
                                />
                            </Col>
                        </FormGroup> */}
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Video File :
                                </Label>
                                <FileUploader
                                    isVideo={true}
                                    getVideo={(item) =>
                                        getVideo(item)
                                    }
                                    value={videoFileName}
                                    labelName="Upload Video File"
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup
                            row
                            className="mx-0 align-items-center"
                        >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Label className="common-label text-left">
                                    Upload Thumbnail File* :
                                </Label>
                                <FileUploader
                                    isImg={true}
                                    getThumbnail={(item) =>
                                        getThumbnail(item)
                                    }
                                    value={videoThumbnailFileName}
                                    labelName="Upload Thumbnail File"
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8} xl={8} className="m-auto">
                    <Row className="no-margin py-2 mt-4 mx-0">
                        <Col
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            xl={3}
                            className={`${isLoading ? 'm-auto' : 'm-auto'}`}
                        >
                            {!isLoading ?
                                <Row>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-theme-btn custom-btn--small"
                                            onClick={handleToggle}
                                        >
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Button
                                            className="btn btn-block custom-blue-btn custom-btn--small"
                                            onClick={handleSubmit}
                                            disabled={(videoUrl || videoFileName) && videoThumbnailFileName ? false : true}
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row> : null}
                            {isLoading ? (
                                <div className="d-flex align-item-center justify-content-center">
                                    <Loader containerClassName="" />
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Strip>
    )
}


export default AddBlogBannerVideo;

