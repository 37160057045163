const MenuData = [
    {
        name: 'Home',
        link: 'Home',
        delay: 0.5
    },
    {
        name: 'Srila Prabhupada',
        link: 'Srila Prabhupada',
        delay: 1
    },
    {
        name: 'Srila Prabhupada Foundation',
        link: 'Srila Prabhupada Foundation',
        delay: 1.5
    },
    {
        name: 'Projects and Initiatives',
        link: 'Projects and Initiatives',
        delay: 2
    },
    {
        name: 'Donate',
        link: 'Donate',
        delay: 2.5
    },
    {
        name: 'Blog',
        link: 'Blog',
        delay: 3
    },
];

export default MenuData;