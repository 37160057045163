import application_contants from "../constants/application_contant";

function activeTab(path) {
    if (path.split("/")[1] === application_contants.PAYMENT.split("/")[1]) {
        return application_contants.PAYMENT;
    } else if (path === application_contants.ADMINSEVALIST) {
        return application_contants.ADMINSEVALIST;
    }else if (path === application_contants.ADMINSUBSEVALIST) {
        return application_contants.ADMINSUBSEVALIST;
    }else if (path === application_contants.ADMINBLOGLIST) {
        return application_contants.ADMINBLOGLIST;
    }else if (path === application_contants.ADMINBLOGSUBMISSIONLIST) {
        return application_contants.ADMINBLOGSUBMISSIONLIST;
    }
    else {
        return path;
    }
}

export default activeTab;