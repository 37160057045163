import { combineReducers } from 'redux'
import { blogItemReducer, blogReducer } from './blogItemReducer';
import { currentPageReducer } from './currentPageReducer';
import { currentPostionReducer } from './currentPositionReducer';
import { directionReducer } from './directionReducer';
import { playStatusReducer } from './playStatusReducers';
import { sevaListReducer, updateSevaReducer } from './updateSevaReducer';
import { userAuthenticatedReducer } from './userAuthenticatedReducer';

const reducers = combineReducers({
    direction: directionReducer,
    currentPosition: currentPostionReducer,
    isPlay: playStatusReducer,
    currentPage: currentPageReducer,
    sevaItem: updateSevaReducer,
    sevaList: sevaListReducer,
    userInfo: userAuthenticatedReducer,
    blogItem: blogItemReducer,
    blogList: blogReducer,
});

export default reducers