import { useWindowSize } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import Header from '../../customComponents/Header';
import { setCurrentPage } from '../../redux/actions/currentPage';
import AnniversaryCelebrationData from '../../utils/json/anniversaryCelebrationData';

const Anniversary = () => {
    const [width] = useWindowSize();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const { sectionImg, year, title, subTitle } = AnniversaryCelebrationData[0];

    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
        }, 500)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 200)
        }
    }, [direction]);

    const handleNavigation = () => {
        dispatch(setCurrentPage(application_contants.ANNIVERSARYCELEBRATION)); 
    }

    return (
        <div className={`${width <= application_contants.MOBILE_BREAKPOINT? 'content-container' : 'content-container'} full-height overflow-hidden position-relative`}>
            <Header isScroll={false} />
            {classNames !== '' ?
                <Row className="mx-0 anniversary mb-5">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={`position-relative year-container`}>
                            <div className={`${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h1-large' : 'h1-extra-small'} sub-section-title poplarStd title-orinoco-green`}>{year}</h1>
                                <div className="anniversary-spec--container">
                                    <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h4-small' : 'h1-small'} info sub-section-title poplarStd title-orinoco-green`}>
                                        th
                                    </h1>
                                </div>
                                <div className="anniversary-year--text">
                                    <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h4-small' : 'h1-small'} info sub-section-title saolStandardRegularItalic title-orinoco-green`}>
                                        year
                                    </h1>
                                </div>
                            </div>
                            <div className={`anniversary-img--container ${isAnimate ? `${classNames} animate__slow` : ''}`}>
                                <img src={sectionImg} alt="" />
                            </div>
                        </div>
                        <div className={`hover-one anniversary-title--container ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                            <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h2-small pt-3' : 'h1-small'} sub-section-title align-left pl-38px lh-48px`}>{title}</h1>
                            <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h1-small-large lh-0px' : 'h1-medium lh-72px'} sub-section-title saolStandardMedium align-left txt-uppercase`}>{subTitle}</h1>
                        </div>
                    </Col>
                </Row> : null}
            <div className={`info-container animate__animated  animate__slower`} >
                <div className="scroll-arrow--container hover-one" onClick={() => handleNavigation()}>
                    <img src={application_contants.SCROLLARROWIMG} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Anniversary;