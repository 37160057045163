import { useWindowSize } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../../constants/application_contant';
import Header from '../../../customComponents/Header';
import { setCurrentPage } from '../../../redux/actions/currentPage';
import FoundationData from '../../../utils/json/foundation';

const Banner = () => {
    const [width] = useWindowSize();
    const dispatch = useDispatch();
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const state = useSelector(state => state);
    const { direction } = state;
    const { sectionImg, title, subTitle, text } = FoundationData[0];

    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
        }, 300)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 300)
        }
    }, [direction]);

    const handleNavigation = () => {
        dispatch(setCurrentPage(application_contants.FOUNDATIONHISTORY));
    }

    return (
        <div className={`${width <= application_contants.MOBILE_BREAKPOINT? 'content-container' : 'content-container'} full-height overflow-hidden position-relative`}>
            <Header isScroll={false} />
            {classNames !== "" ?
                <Row className="mx-0 full-width">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={`position-relative`}>
                            <div className={`banner-img--container ${isAnimate ? `${classNames} animate__slow` : ''}`}>
                                <div className="pb-4">
                                    <img src={sectionImg} alt="" className="" />
                                </div>
                            </div>
                            <div className={`banner-info--container ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                <div className="title-container hover-one">
                                    <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h3-small lh-48px align-center' : 'h1-small lh-48px align-left'}  sub-section-title`}>{title}</h1>
                                    <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h1-small-large lh-0px align-center' : 'h1-medium lh-72px align-left'} sub-section-title saolStandardMedium `}>{subTitle}</h1>
                                </div>
                                <div className="position-relative mt-3">
                                    {width <= application_contants.MOBILE_BREAKPOINT ? 
                                         <img src={application_contants.MobileBGBanner} alt="" className="bottom--img-container" /> :
                                        <img src={application_contants.DESCCARDIMG} alt="" className="bottom--img-container" />
                                    }
                                    <div className="banner-description--container">
                                        <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-small align-center width_60' : `${width <= 1024 ? 'p-small align-center' : 'align-left pt-1'}`} sub-section-title saolStandardRegular ${isAnimate ? `${classNames} animate__slower` : ''}`}>{text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row> : null}
            <div className={`info-container animate__animated  animate__slower`} >
                <div className="scroll-arrow--container hover-one" onClick={() => handleNavigation()}>
                    <img src={application_contants.SCROLLARROWIMG} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Banner;