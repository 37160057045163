import application_contants from "../../constants/application_contant";

const InfluenceData = [
    {
        bgImgPath: application_contants.INFLUENCEIMG,
        title: `Srila Prabhupada's Influence on Different Continents`,
        moreInfo: [
            {
                title: `North America`,
                desc: `On July 11, 1966, he officially registered his organization in the state of New York, formally founding the International Society for Krishna Consciousness(ISKCON) operating from a small rented place with the help of a few young followers. ISKCON gradually grew. New centers came up in San Francisco, Montreal, Boston, Los Angeles and Buffalo. In 1968 the first farming community was started in the hills of West Virginia. In Dallas, Srila Prabhupada started the first Gurukula, (spiritual school) in the West. In Los Angeles, where he had attracted a substantial following, he installed ISKCON’s first Radha-Krishna deities. For their worship, he trained some of his dedicated disciples – young American men and women – to become their pujaris, or priests.`,
                moreInfo: [],
            },
            {
                title: `Europe`,
                desc: `Srila Prabhupada arrived in London in September of 1969 and stayed for 2 months on the estate of the Beatles fame, John Lennon. George Harrison another musician of the Beatles, was becoming very attracted to the chanting of the holy names of the Lord. Between George and the devotees, a record was produced-The Radha Krishna Temple Album. It became a massive hit all over Europe, reaching No 1 in the hit parade in several countries. Srila Prabhupada installed the first large Radha Krishna Deities in London. In 1967 Srila Prabhupada initiated the Ratha-yatra festival in San Francisco. It is the first time a full-scale Chariot was pulled on the streets anywhere outside of Jagannatha Puri. In August of 1969, Srila Prabhupada visited Hamburg and stayed there for two months. During his stay, he gave many lectures and met with important Sanskrit professors.`,
                moreInfo: [],
            },
            {
                title: `Asia`,
                desc: ``,
                moreInfo: [
                    {
                        title: `India`,
                        desc: `In 1971 in Kolkata Srila Prabhupada established ISKCON’s first temple in India. 1970, July 29, Srila Prabhupada established the Bhaktivedanta Book Trust to widely, effectively, and steadily share spiritual wisdom through books. In May 1971, in Mayapura, he initiated the project of building a spiritual city with the largest temple in the world. Here he also started the program of mass prasadam distribution to the needy. In 1975, on the day commemorating the appearance of Lord Ramachandra, he inaugurated the marvelous Krishna-Balarama Mandir in the place where he had lived, prayed and written. Recognizing the enormous influence of science on the modern mind, In 1975 Srila Prabhupada established ISKCON’s scientific wing. In 1976 he inaugurated the Bhaktivedanta gurukula (spiritual school) in Vrindavan to train children in the path of self-realization.`,
                    },
                    {
                        title: `Russia`,
                        desc: `The country’s communist government and underlying atheistic ideology made it difficult to preach Krishna Consciousness, but even the Iron Curtain couldn’t hold Srila Prabhupada back. Through his correspondence with a respected Russian professor of Indology, he was eventually able to visit the USSR for five days in Moscow in 1971. Though he wasn’t allowed to do any public programs, through a series of transcendental coincidences, one sincere Russian seeker had taken a deep interest in Krishna Consciousness after meeting him and accepted initiation from him. Through this first Russian disciple, the message of bhakti spread gradually but unstoppably to thousands.`,
                    },
                    {
                        title: `Malaysia`,
                        desc: `Srila Prabhupada visited Malaysia from May 5 to May 9, 1971. Before his arrival, Vaishnavism was not well known here. Soon after Srila Prabhupada’s visit, a group of brahmacharis (celibate students), including a young Singaporean Chinese man who was later initiated by Srila Prabhupada, spent at least six months touring the country and teaching Krishna consciousness. The local people received them well, and their programs attracted huge crowds.`,
                    },
                    {
                        title: `Tokyo`,
                        desc: `Prabhupada stayed briefly overnight in Tokyo, Hanada in early 1969 on his way to India, which was technically his first visit to Japan. In August 1970, he again visited Tokyo, Japan and the new temple with some of his main disciples. He was also in Japan to sign a book publishing contract with Dai Nippon Printing, who had agreed to print the ‘Krishna’ Book.`,
                    },
                    {
                        title: `HongKong`,
                        desc: `In 1972 and 1974, Srila Prabhupada visited Hong Kong, where he gave a talk to a group of Indian businessmen and their families at the Park Hotel in Tsim Sha Tsui. With Srila Prabhupada’s blessings, and the sincere efforts of his disciples, an ISKCON center opened in Hong Kong in 1981—a small flat in the district of Ho Man Tin.`,
                    },
                    {
                        title: `Tehran`,
                        desc: `In 1975 and 1976 Srila Prabhupada visits Tehran. During these visits, Srila Prabhupada gave his association to the devotees and guests through room conversations, morning walks, lectures and kirtana.`,
                    },
                ]
            },
            {
                title: `Africa`,
                desc: `In 1972, Srila Prabhupada again visited South Africa and presided over the World Hare Krishna Festival in Nairobi held at the Nairobi city stadium. The British Broadcasting Company made a film about this festival. Srila Prabhupada spiritually surcharged the whole community with his preaching tours throughout Northern and Western Kenya, Tanzania, Zambia, Salisbury and Rhodesia.\n In 1975, Srila Prabhupada spent three weeks in South Africa delivering lectures in several centres in Durban, Johannesburg and Pretoria. Wherever he delivered lectures, he was greeted by capacity audiences. During his stay in the country, he met several prominent members of the Hindu community who pledged their support in assisting the growth of the Movement in South Africa.`,
                moreInfo: [],
            },
            {
                title: `Oceania`,
                desc: `Srila Prabhupada visited Australia six times between 1971 and 1976, staying a total of eighty days. He personally installed the Deities in Sydney, Melbourne, Perth, and Auckland (New Zealand). During his stay in Australia, he gave a total of sixty-eight morning and evening classes on Srimad Bhagavatam, Bhagavad-Gita, and Sri Chaitanya Charitamrita. During these classes and many other interactions, he influenced the lives of many. During his visits, he received many guests from all walks of life. Srila Prabhupada spoke at twenty public speaking engagements in Australia and New Zealand. In 1974, a grand Ratha yatra festival was organized in Melbourne. Srila Prabhupada lead the entire audience with his ecstatic dancing and joyful kirtana. He also regularly visited Fiji Islands en route to Australia and every time, he lectured at the different avenues, conducted the kirtan program in prominent locations, and met eminent people to establish a strong base for the Hare Krishna Movement.`,
                moreInfo: [],
            },
            {
                title: `South America`,
                desc: `In 1975, February, Srila Prabhupada visited South America for the first time. Having shared Krishna consciousness on four continents, Srila Prabhupada traveled to Caracas, Venezuela, to spiritually enrich this last among the inhabited continents. His followers had already established a vibrant center there, and they welcomed him with a jubilant kirtana. Seeing their enthusiastic devotion as the mercy of the Lord whom he had strived lifelong to glorify, Srila Prabhupada broke into tears during a rare public display of spiritual emotion. In a choked voice, he urged all the assembled devotees and newcomers to treasure the gift of Krishna consciousness, life’s only eternal treasure. His words and actions created a lasting spiritual impression on all present. The inspiration and wisdom he provided powers the bhakti legacy’s ongoing expansion in South America.`,
                moreInfo: [],
            }
        ]
    }
];

export default InfluenceData;