import { useWindowWidth } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import Footer from '../../customComponents/Footer/Footer';
import Header from '../../customComponents/Header';


const Acknowledgments = () => {
    const onlyWidth = useWindowWidth()
    const state = useSelector(state => state);
    const { direction } = state;;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [classNamesLR, setClassNamesLR] = useState('');
    const [classNamesRL, setClassNamesRL] = useState('');

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__fadeIn`
            setClassNames(cls);
            let clsLR = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInRight` : `animate__animated animate__slideInRight`
            setClassNamesLR(clsLR)
            let clsRL = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInLeft` : `animate__animated animate__slideInLeft`
            setClassNamesRL(clsRL)
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
                setClassNamesLR("");
                setClassNamesRL('');
            }, 100)
        }
    }, [direction]);


    return (
        <div>
            <div className="acknowledgments-container pt-4rem">
                <Header isScroll={false} isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} isFooter={false}  menuClassNames={'title-melanzane-Violet'} scrollPositionClassNames={`bt-32px`} bgClassName={'bg-narvik-green'} />
                <div className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pt-4 pb-5' : 'pt-5'}`}>
                    <div className="container-fluid">
                        <Row className="mx-0">
                            <Col xs={12} sm={12} md={12} lg={8} xl={7} className="m-auto">
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''} py-3`} >
                                        <h3 className={`sub-section-title align-center title-stack-grey ${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'h1-small lh-34px' : ''}`}>Acknowledgments and Credits</h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNamesLR} animate__slower` : ''} pb-3`}>
                                        <h5 className="h5-small sub-section-title align-center saolStandardRegular lh-24px">
                                            We wholeheartedly thank all the devotees, technicians, resources and
                                            volunteers who have influenced the shape of the Srila Prabhupada Foundation.
                                            This project wouldn’t have been possible without their constant support,
                                            contribution and guidance.
                                        </h5>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className={`${isAnimate ? `${classNames} animate__slower` : ''} py-3`}>
                                            <h3 className={`sub-section-title align-center title-stack-grey ${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'h1-small lh-34px' : ''}`}>Credits</h3>
                                        </div>
                                        <div className={`${isAnimate ? `${classNamesLR} animate__slower` : ''}`}>
                                            <div>
                                                <h3 className="h3-small sub-section-title align-center saolStandardRegular">Bhaktivedanta Archives</h3>
                                                <p className="p-medium sub-section-title align-center gothamBook pt-1">Images, paintings, documents, and publications</p>
                                            </div>
                                            <div className="py-3">
                                                <h3 className="h3-small sub-section-title align-center saolStandardRegular">Anuradha Devi Dasi</h3>
                                                <p className="p-medium sub-section-title align-center gothamBook pt-1">Srila Prabhupada Illustration</p>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <Row className="mx-0">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Row className="mx-0">
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''} py-3`}>
                                        <h3 className={`sub-section-title align-center title-stack-grey ${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'h1-small lh-34px' : ''}`}>Acknowledgments</h3>
                                    </Col>
                                </Row>
                                <Row className={`mx-0 align-items-center ${isAnimate ? `${classNames} animate__slower` : ''} pt-3`}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3} className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pb-4' : ''}`}>
                                        <h3 className="h3-small sub-section-title align-center saolStandardRegular">Rajashekhar Das Brahmachari</h3>
                                        <p className="p-medium sub-section-title align-center title-stack-grey gothamBook pt-1">Volunteer, Content Development</p>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3} className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pb-4' : 'acknowledgments-line'}`}>
                                        <h3 className="h3-small sub-section-title align-center saolStandardRegular">Kanicka Raj</h3>
                                        <p className="p-medium sub-section-title align-center title-stack-grey gothamBook pt-1">Creative Director, draWings</p>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={2} xl={2} className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pb-4' : 'acknowledgments-line'}`}>
                                        <h3 className="h3-small sub-section-title align-center saolStandardRegular">AppZoy</h3>
                                        <p className="p-medium sub-section-title align-center title-stack-grey gothamBook pt-1">Website Development</p>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={2} xl={2} className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pb-4' : 'acknowledgments-line'}`}>
                                        <h3 className="h3-small sub-section-title align-center saolStandardRegular">Nithin Kumblekar</h3>
                                        <p className="p-medium sub-section-title align-center title-stack-grey gothamBook pt-1">Illustrator</p>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={2} xl={2} className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT ? 'pb-4' : 'acknowledgments-line'}`}>
                                        <h3 className="h3-small sub-section-title align-center saolStandardRegular">Manav Acharya</h3>
                                        <p className="p-medium sub-section-title align-center title-stack-grey gothamBook pt-1">Video Editing</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mx-0">
                            <Col xs={12} sm={12} md={12} lg={8} xl={7} className={`m-auto py-3 pt-5 ${isAnimate ? `${classNamesRL} animate__slower` : ''}`} >
                                <h5 className="h5-small sub-section-title align-center saolStandardRegular">
                                    We also thank ISKCON Bangalore and many gratuitous volunteers who have supported
                                    us throughout the founding of the Srila Prabhupada Foundation and influenced the
                                    development of this website. We would like to extend our heartfelt gratitude to each one
                                    of them for their constant support and guidance.
                                </h5>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Acknowledgments;