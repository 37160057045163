import application_contants from "../../constants/application_contant";

const MoreLink = [
    {
        index: 1,
        img: application_contants.ASSETS15,
        title: `125th Anniversary Celebration`,
        type: 'Most Popular',
        date: '31 August 2021',
        link: application_contants.ANNIVERSARY,
    },
    {
        index: 1,
        img: application_contants.ASSETS16,
        title: `A lifetime in preparation`,
        type: 'Recommended',
        date: '31 August 2021',
        link: application_contants.RECOMMEND,
    }
];

export default MoreLink;