import application_contants from "../../constants/application_contant";

const BlogHeaderMenu = [
    {
        name: 'Srila Prabhupada',
        link: application_contants.SRILAPRABHUPADA,
    },
    {
        name: 'About',
        link: application_contants.ABOUT,
    },
    {
        name: 'Editorials',
        link: application_contants.EDITORIALS,
    },
    {
        name: 'Events',
        link: application_contants.EVENTS,
    },
    {
        name: 'Features',
        link: application_contants.FEATURES,
    },
    {
        name: 'News',
        link: application_contants.NEWS,
    },
    {
        name: 'Submit',
        link: application_contants.SUBMISSIONSGUIDNELINES,
    },
    {
        name: 'Contact Us',
        link: application_contants.CONTACTUS,
    }
];

export default BlogHeaderMenu;