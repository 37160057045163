import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import InfluenceData from '../../utils/json/InfluenceData';


const Influence = (props) => {
    const state = useSelector(state => state);
    const { direction } = state;;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNamesRL, setClassNamesRL] = useState('');
    const [classNames, setClassNames] = useState('');

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setIsAnimate(true);
            let clsRL = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInLeft` : `animate__animated animate__slideInLeft`
            setClassNamesRL(clsRL);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn animate__slow` : `animate__animated animate__zoomIn animate__slow`
            setClassNames(cls);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNamesRL('');
            }, 100)
        }
    }, [direction]);


    return (
        <div>
            <div className="influence-container bg-celeste">
                <div>
                    <div className="container-fluid px-0">
                        <Row className="mx-0">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                                {InfluenceData.length > 0 && InfluenceData.map((it, index) => (
                                    <Row className="mx-0" key={index}>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 position-relative">
                                            <img src={it.bgImgPath} alt="" className="img-position"/>
                                            <div className={`title-container ${isAnimate ? `${classNamesRL} animate__slow` : ''}`}>
                                                <h1 className="h2-medium sub-section-title align-left title-black">{it.title}</h1>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0 m-auto">
                                            <Row className="mx-0">
                                                <Col xs={11} sm={11} md={11} lg={10} xl={10} className="m-auto py-5">
                                                    {it.moreInfo.length > 0 && it.moreInfo.map((item, ind) => (
                                                        <div key={ind} className={`pb-3 ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                                            <h1 className="h1-very-small sub-section-title align-left title-stack-grey gothamBlack pb-3">{item.title}</h1>
                                                            {item.desc !== '' ?
                                                                <div>
                                                                    {item.desc.split(/[\n\r]/g).map((params, i) => (
                                                                        <h6 className="h6-small p-large sub-section-title pb-3 align-left gothamBook lh-26px title-black" key={i}>{params}</h6>
                                                                    ))}
                                                                </div> : null}
                                                            {item.moreInfo.length > 0 && item.moreInfo.map((i, index) => (
                                                                <div key={index} className="pt-2">
                                                                    <h4 className="h4-small sub-section-title align-left title-stack-grey gothamBook fw-600 fs-italic pb-2">{i.title}</h4>
                                                                    {i.desc.split(/[\n\r]/g).map((params, i) => (
                                                                        <div>
                                                                            <h6 className="h6-small p-large sub-section-title pb-3 align-left gothamBook lh-26px title-black" key={i}>{params}</h6>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Influence;