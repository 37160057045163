import { useWindowSize } from '@react-hook/window-size';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../../constants/application_contant';
import Header from '../../../customComponents/Header';
import { setCurrentPage } from '../../../redux/actions/currentPage';
import InitiativesData from '../../../utils/json/initiativesData';

const Banner = () => {
    const [width] = useWindowSize();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [yearCount, setYearCount] = useState(1896);
    const [classNames, setClassNames] = useState('');
    const [classNamesLR, setClassNamesLF] = useState('');

    const { bgImg, prabuImg, img, title, subTitle } = InitiativesData[0];

    React.useEffect(() => {
        let interval;
        setTimeout(() => {
            interval = setInterval(() => {
                if (yearCount < new Date().getFullYear()) {
                    setYearCount(yearCount + 1);
                }
            }, 100);
        }, 2500)

        return () => {
            clearInterval(interval);
        };
    }, [yearCount]);


    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
            let clsLR = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInRight` : `animate__animated animate__slideInRight`
            setClassNamesLF(clsLR)
        }, 300)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
                setClassNamesLF('')
            }, 300)
        }
    }, [direction]);

    const handleNavigation = () => {
        dispatch(setCurrentPage(application_contants.PROJECTINHISTORY)); 
    }

    return (
        <div className={`content-container overflow-hidden full-height position-relative`}>
            <Header isScroll={false} />
            {classNames !== '' ?
                <Row className="mx-0 full-width animate__hover">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="full-width">
                        <div>
                            <div className="initiatives-container position-relative">
                                <img src={bgImg} alt="" className={`${isAnimate ? `${classNames} animate__slower` : ''}`} />
                                <div className="initiative-right-img--container">
                                    <img src={prabuImg} alt="" className={`${isAnimate ? `${classNames} animate__slower` : ''}`} />
                                </div>
                                <div className="initiative-left-img--container">
                                    <img src={img} alt="" className={`${isAnimate ? `${classNames} animate__slow` : ''}`} />
                                </div>
                                <div>
                                    <div className={`initiative-title--container ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                        <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT? 'h3-small' :'h1-small'} sub-section-title align-left subTitle pl-38px`}>{subTitle}</h1>
                                        <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT? 'h1-small' :'h1-medium'} sub-section-title saolStandardMedium align-left title`}>{title}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className={`info-container animate__animated  animate__slower`} >
                                <div className="scroll-arrow--container hover-one" onClick={() => handleNavigation()}>
                                    <img src={application_contants.SCROLLARROWIMG} alt="" />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row> : null}
        </div>
    )
}

export default Banner;