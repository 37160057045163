import React from 'react';
import PropTypes from "prop-types";
import { Col, Row } from 'reactstrap';
import SocialMedia from '../../utils/json/socialMedia';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../redux/actions/currentPage';
import application_contants from '../../constants/application_contant';
import { useWindowSize } from '@react-hook/window-size';


const BlogFooter = () => {
    const [width] = useWindowSize();
    const dispatch = useDispatch();

    const handleNavigation = (name) => {
        dispatch(setCurrentPage(name));
    }

    return (
        <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'py-5 px-2' : 'py-5'} bg-celeste-green`}>
            <Row className={`${width <= application_contants.MOBILE_BREAKPOINT || width <= 1024 ? 'fd-cr' : ''} mx-0 align-items-start`}>
                <Col xs={12} sm={12} md={12} lg={width <= 1024 ? 10 : 7} xl={6} className={`${width <= 1024 ? 'm-auto pt-2' : ''}`}>
                    <div className="d-flex align-items-center blog-footer">
                        {SocialMedia.map((it, index) => (
                            <div key={index} >
                                <a href={it.link} target="_blank" rel="noreferrer">
                                    <img src={`${false ? it.img : it.whiteImg}`} alt="" className="social-icon cursor-pointer" />
                                </a>
                            </div>
                        ))}
                    </div>
                    <Row className={`mx-0 align-items-center ${width <= application_contants.MOBILE_BREAKPOINT || width <= 1024 ? 'pt-3' : 'pt-5'}`}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={`d-flex align-items-center ${width <= application_contants.MOBILE_BREAKPOINT ? 'flex-wrap justify-content-center' : ''}`}>
                                <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'full-width' : ''}`}>
                                    <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center title-stack-grey gothamBook' : 'p-very-small align-left title-black text-uppercase porterBold fw-600'} sub-section-title pb-1`}>Terms of service
                                        {width <= application_contants.MOBILE_BREAKPOINT ? '' : <span className="p-1">|</span>}
                                    </p>
                                </div>
                                <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'width_50' : ''}`}>
                                    <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-right title-stack-grey gothamBook' : 'p-very-small align-left title-black text-uppercase porterBold fw-600'} sub-section-title pb-1`}>Contact Us <span className="p-1">|</span></p>
                                </div>
                                <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'width_50' : ''}`}>
                                    <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-left title-stack-grey gothamBook' : 'p-very-small align-left title-black text-uppercase porterBold fw-600'} sub-section-title pb-1`}>Careers
                                        {width <= application_contants.MOBILE_BREAKPOINT ? '' : <span className="p-1">|</span>}
                                    </p>
                                </div>
                                <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'width_50' : ''}`}>
                                    <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-right title-stack-grey gothamBook' : 'p-very-small align-left title-black text-uppercase porterBold fw-600'} sub-section-title pb-1`}>Advertising <span className="p-1">|</span></p>
                                </div>
                                <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'width_50' : ''}`}>
                                    <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-left title-stack-grey gothamBook' : 'p-very-small align-left title-black text-uppercase porterBold fw-600'} sub-section-title pb-1`}>Newsletter
                                        {width <= application_contants.MOBILE_BREAKPOINT ? '' : <span className="p-1">|</span>}
                                    </p>
                                </div>
                                <div className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'full-width' : ''}`}>
                                    {width <= application_contants.MOBILE_BREAKPOINT ?
                                        <div>
                                            <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center title-stack-grey gothamBook' : 'p-very-small align-left title-black text-uppercase porterBold fw-600'} sub-section-title pb-1`}>Subscribe</p>
                                            <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center title-stack-grey gothamBook' : 'p-very-small align-left title-black text-uppercase porterBold fw-600'} sub-section-title pb-1`}><font className="br-contianer">COOKIES SETTINGS</font></p>
                                        </div>
                                        :
                                        <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center title-stack-grey gothamBook' : 'p-very-small align-left title-black text-uppercase porterBold fw-600'} sub-section-title pb-1`}>Subscribe <span className="p-1 br-1px">COOKIES SETTINGS</span></p>
                                    }
                                </div>
                            </div>
                            <p className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-medium align-center align-center title-stack-grey' : 'p-very-small align-left title-melanzane-Violet'} sub-section-title gothamBook cursor-pointer fw-800 pt-2`}>Copyright © 2021 . All rights reserved. We have updated our Privacy Policy.© 2021</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={width <= 1024 ? 10 : 5} xl={6} className={`${width <= 1024 ? 'm-auto' : ''}`}>
                    <Row className={`${width <= application_contants.MOBILE_BREAKPOINT ? '' : ''} mx-0`}>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left'} sub-section-title porterBold fw-600 title-stack-grey text-uppercase pb-2`}><font className="footer-title">QUICK LINKS</font></h6>
                            <div className="pt-2">
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`} onClick={() => handleNavigation(application_contants.VOLUNTEER)}>Get Involved</h6>
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`}>
                                    <a href="https://www.srilaprabhupadalila.org/" target="_blank" rel="noreferrer" className="title-stack-grey link-hover">Srila Prabhupada Lila</a>
                                </h6>
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`}>Srila Prabhupada Books</h6>
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`}>Biographies</h6>
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`}>Books in other Languages</h6>
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`} onClick={() => handleNavigation(application_contants.ACKNOWLEDGMENTS)}>Acknowledgments</h6>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'pt-4' : ''}`}>
                            <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left'} sub-section-title porterBold fw-600 title-stack-grey text-uppercase pb-2`}><font className="footer-title">CONTACT US</font></h6>
                            <div className="pt-2">
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`}>Srila Prabhupada House,</h6>
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`}>Sri Radha Damodar Mandir,</h6>
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`}>Seva Kunj, Vrindavan,</h6>
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`}>Uttar Pradesh - 281121</h6>
                                <h6 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'p-large align-center' : 'p-medium align-left fw-800'} sub-section-title title-stack-grey gothamBook cursor-pointer pb-1`}>Email: info@srilaprabhupada.in</h6>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

BlogFooter.propTypes = {

};

BlogFooter.defaultProps = {

};

export default BlogFooter;