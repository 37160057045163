import React from "react";
import memoizeOne from "memoize-one";
import { Button } from "reactstrap";

const TablesColumns = memoizeOne((handleDelete, handleEdit) => [
    {
        name: "Seva Type",
        grow: 2.5,
        selector: "sevaType",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.sevaType}</p></div>,
    },
    {
        name: "Description",
        selector: "description",
        sortable: false,
        grow: 2,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.description ?row.description.length > 50 ? `${row.description.substr(0, 20)}...` : row.description : ''}</p></div>,
    },
    {
        name: "TotalAmount",
        grow: 2.5,
        selector: "totalAmount",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.totalAmount}</p></div>,
    },
    {
        name: "Schedule Payment",
        grow: 2,
        selector: "isPaymentSchedule",
        sortable: false,
        cell: (row) => <div className="py-1"><p className="p-medium-small sub-section-title gothamBook">{row.isPaymentSchedule === 1  ? 'true':  'false'}</p></div>,
    },
    {
        name: "Status",
        selector: "status",
        sortable: false,
        cell: (row) => <div className="py-1"><p className={`p-medium-small sub-section-title gothamBook ${row.status === 1 ? 'title-green fw-600' : 'title-danger-light fw-600'}`}>{`${row.status === 1 ? 'Active' : 'InActive'}`}</p></div>,
    },
    {
        name: "Action",
        selector: "",
        sortable: false,
        ignoreRowClick: true,
        allowOverflow: true,
        grow: 2,
        cell: (row) => (
            <div className="d-flex py-1">
                <div onClick={() => handleEdit(row)}>
                    <Button className="btn btn-small custom-green-btn custom-btn--small fs-10px">Edit</Button>
                </div>
                <div onClick={() => handleDelete(row)} className="ml-12px">
                    <Button className="btn btn-small custom-theme-btn custom-btn--small fs-10px">Delete</Button>
                </div>
            </div>
        ),
    },
]);


export default TablesColumns;
