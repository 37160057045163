import { useWindowSize } from '@react-hook/window-size';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import apiConfig from '../../../api/apis/apiConfig';
import application_contants from '../../constants/application_contant';

const VideoPlayer = (props) => {
    const [width] = useWindowSize();
    const [isVideoPlay, setIsVideoPlay] = useState(false);
    const state = useSelector(state => state);
    const { isPlay, direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');

    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__fadeIn` : `animate__animated animate__fadeIn`
            setClassNames(cls);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 100)
        }
    }, [direction]);

    const { item, index } = props;

    return (
        <Row className="mx-0" key={index}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="px-0">
                <div className={`blog-player ${width <= application_contants.MOBILE_BREAKPOINT ? `${props.isPadding ? 'pt-3' : ''}` : 'pt-3'}`}>
                    {isVideoPlay ?
                        <ReactPlayer
                            className="react-player1"
                            url={`${apiConfig.URL}${item.videoPath}`}
                            width="100%"
                            height="100%"
                            playing={isVideoPlay}
                            controls={true}
                            volume={0.85}
                            muted={false}
                            onEnded={() => setIsVideoPlay(false)}
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 1 },
                                },
                                attributes: {
                                    controlsList: "download",
                                    // poster: `${application_contants.BGBLOGIMG}`,
                                },
                            }}
                        /> :
                        <div className={`${isAnimate ? `${classNames} animate__slower` : ''}`}>
                            <img src={`${apiConfig.URL}${item.thumbnailImg}`} alt="" />
                        </div>}
                    {!isVideoPlay ?
                        <div className="play-icon--container">
                            <img src={application_contants.WHITEPLAYIMG} alt="" onClick={() => setIsVideoPlay(!isVideoPlay)} />
                        </div> : null}
                </div>
            </Col>
        </Row>
    )
};


VideoPlayer.propTypes = {
    isPadding: PropTypes.bool,
  };
  
  VideoPlayer.defaultProps = {
    isPadding: true,
  };


export default VideoPlayer;