import application_contants from "../../constants/application_contant";

const DamodarTempleData = [
    {
        imgPath: application_contants.DAMODRA,
        title: 'Sri Radha Damodar',
        preTitle: 'Importance of',
        postTitle: 'Temple',
        sectionImg: application_contants.DAMODRATEMPLE,
        sectionBgImgPath: application_contants.DAMODARBG,
        bio: [
            {
                description: `Established in 1542 by Jiva Goswami Sri Radha Damodar temple is located at Seva Kunj, Vrindavan. The founder acharya of the International Society for Krishna Consciousness, A. C. Bhaktivedanta Swami Prabhupada, spent six years (1959-1965) in this temple, meditating on his mission and doing bhajans before establishing the society.`,
            },
            {
                description: `The history of Sri Damodar Temple began when the two most prominent Gaudiya Vaishnava saints, Srila Rupa Goswami and Srila Sanatan Goswami came to Vrindavan in 1516. Lord Krishna Himself in the form of Sri Chaitanya Mahaprabhu had ordered them to uncover the pastime places of Sri Radha Krishna and distribute bhakti for the benefit of all human beings. Established by Srila Jiva Goswami in 1542, Sri Sri Radha Damodar Mandir remains dear to devotees in the Madhva Gaudiya Sampradaya even to this day.`,
            },
            {
                description: `The prominent Gaudiya Vaishnava acharyas like Srila Rupa Goswami, Sanatan Goswami, Gopal Bhatt Goswami, Raghunath das Goswami, Raghunath Bhatt Goswami, and many other associates would meet in this temple on a regular basis. This was their headquarters. They would study the books, discuss their writings, honor prasadam together, and perform beautiful kirtans. After the disappearance of Rupa and Sanatana, Jiva Goswami became the head of the Gaudiya sampradaya. Jiva Goswami, in his life, wrote over twenty-five books, over a hundred thousand Sanskrit shlokas, which were all based on the teachings of Rupa and Sanatan. He established an incredible library of all the Goswamis’ literature. Later on in the courtyard of this temple, many acharyas like Narottam das Thakur, Srinivas Acharya, and Shyamanand prabhu studied under him. Radha Damodar Temple became famous as a seat of learning due to its vast library of rare books and manuscripts of the Goswamis.`,
            },
            {
                description: `Srila Prabhupada’s spiritual master, Srila Bhaktisiddhanta Saraswati Thakur, instructed him in their first meeting in Ultadanga junction road in Calcutta, “You are a young intelligent man. Take the message of Sri Chaitanya Mahaprabhu and spread it throughout the world in the English language.” To fulfill this desire of his guru, in 1959, Srila Prabhupada gave up his family life and came to Vrindavan and took to the renounced order of life (Sannyas). He thought, “Which more holy place is there in all of creation, to be inspired and prepare myself for this great preaching mission, than the temple of Radha Damodar.” He lived in Jiva Goswami’s temple for six full years (1959 - 1965). It was his bhajan kutir. He lived his simple life chanting the holy names, associating with Vrajavasis, while always absorbed in a prayerful mood.`,
            },
            {
                description: `In the Radha Damodar Temple, Srila Prabhupada took two rooms, one that acted as a kitchen, the other as a study where he would write. From his kitchen, he would gaze out of the small window into the courtyard where Rupa Goswami’s samadhi mandir is situated. He received great inspiration from Rupa Goswami and his plans for spreading Krsna Consciousness according to the desire of his spiritual master were formulated during his years at the Radha Damodar Temple.`,
            },
            {
                description: `Srila Prabhupada went to Vrindavan unlike most people do. Although he was in his old age, he did not go there to retire; he went here to prepare himself for his life’s mission. In Vrindavan, he lived a very simple life. The sadhus of Vrindavan witnessed Prabhupada’s simplicity and devotion. He was writing the Back to Godhead magazine and he would have to take a train all the way up to Delhi, regularly, to get the magazine printed. Then he would go into the streets of Delhi and with his own hands distribute them and return to Vrindavan. Some devotees told him, “If you really want to establish very strongly, you should publish books.” That reminded him of what his spiritual master, Srila Bhaktisiddhanta Saraswati Thakur had ordered him, &#39;print and distribute books.&#39; It was in the rooms of Sri Radha Damodar that Srila Prabhupada wrote the translations and Bhaktivedanta purports to the three volumes of the first canto of Srimad Bhagavatam.`,
            },
            {
                description: `With just a crate of his English translations of the Srimad-Bhagavatam and only forty rupees in his pocket, Srila Prabhupada left the Radha Damodara Mandira in Vrindavana on a journey that would ultimately change the course of religion in both America and the world forever. On the 13 th of August 1965, Srila Prabhupada set sail from the Indian port of Calcutta, having obtained free passage on the Scindia Steamship Lines cargo ship named the Jaladutta, bound for the United States of America, where he landed at Boston Harbor on the 17 th September. The arrival of Srila Prabhupada in America gave birth to the Hare Krishna revolution which has now become an important watershed in modern history.`,
            },
            {
                description: `Srila Prabhupada’s love for Sri Radha Damodar temple was so deep and intimate that even when he was in the west, he was sending whatever he had, to maintain these rooms. His stay at Radha Damodara, not only transformed his own life, but ultimately it transformed the lives of millions of people around the world. He once confidentially revealed to his disciples, “I eternally reside in my rooms at Radha Damodar temple.”`,
            }
        ]
    }
];

export default DamodarTempleData;