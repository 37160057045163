import { useWindowSize } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import application_contants from '../../constants/application_contant';
import Header from '../../customComponents/Header';
import { setCurrentPage } from '../../redux/actions/currentPage';
import { fetchSeva } from '../../redux/reducers/updateSevaReducer';


const Donate = () => {
    const [width] = useWindowSize();
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');


    React.useEffect(() => {
        dispatch(fetchSeva());
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 100)
        }

    }, [direction]);



    const handleNavigation = () => {
        dispatch(setCurrentPage(application_contants.SEVATYPES));
    }


    return (
        <div className="donate-content-container position-relative">
            <Header isScroll={false} />
            {classNames !== '' ?
                <div className="donate--container">
                    <div className="position-relative">
                        <img src={application_contants.PUBLICIMG} alt="" className={`${isAnimate ? `${classNames} animate__slower` : ''}`} />
                        <div className="donate-info-img--container hover-one">
                            <div className="position-relative">
                                {width <= application_contants.MOBILE_BREAKPOINT ?  
                                    <img src={application_contants.MOBILEDONATEBANNER} alt="" className={`${isAnimate ? `${classNames} animate__faster` : ''}`} />
                                    :
                                    <img src={application_contants.DONATETITLEIMG} alt="" className={`${isAnimate ? `${classNames} animate__faster` : ''}`} />
                                }
                                <div className={`donate-info-title-container ${isAnimate ? `${classNames} animate__faster` : ''}`}>
                                    <p className="p-medium-small sub-section-title title-white saolStandardMedium">
                                        Please help us serve Srila Prabhupada's legacy, inspiring today's world and enabling future generations to benefit
                                        from his spiritual vision for humanity. Every penny is vital to our continued service, and we are immensely grateful
                                        for your contributions and constant support!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="donate-center-img--container">
                            <div>
                                <img src={application_contants.DONATEPRABHUIMG} alt="" className={`${isAnimate ? `${classNames} animate__slow` : ''}`} />
                                <div className={`donate-btn--container ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                    <div className="position-relative donate-btn hover-one m-auto">
                                        <img src={application_contants.REDDONATEIMG} alt="" />
                                        <div className="donate-btn-text--position" onClick={() => handleNavigation()}>
                                            <h5 className="sub-section-title title-white txt-uppercase saolStandardMedium btn--small cursor-pointer">Donate</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </div>
    )
}

export default Donate;