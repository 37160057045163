import React from "react";
import PropTypes from "prop-types";
import { useWindowHeight, useWindowWidth } from "@react-hook/window-size";
import application_contants from "../../constants/application_contant";


const RenderPage = (props) => {
    const onlyHeight = useWindowHeight();
    const width = useWindowWidth()
    //console.log(width, onlyHeight);
    return (
        <div className={`${props.className} ${props.containerType}`} style={{ 'minHeight': (onlyHeight - (width <= application_contants.MOBILE_BREAKPOINT ? 180 : width === 1024 ? 420 : 370)) }} id={props.id}>
            {props.children}
        </div>
    )
}

RenderPage.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    containerType: PropTypes.string,
};

RenderPage.defaultProps = {
    containerType: "container",
    className: "render-page",
    id: "",
};

export default RenderPage;
