import React from 'react';
import Banner from './Component/Banner';

const ProjectInitiatives = () => {

    return (
        <div>
            <Banner />
        </div>
    )
}

export default ProjectInitiatives;