import { ActionTypes } from "../constants/action-types"


export const setBlogItem =(item) => {
    return{
        type: ActionTypes.SET_BLOG_ITEM,
        payload: item,
    }
}

export const FetchBlogItem =(item) => {
    return{
        type: ActionTypes.FETCH_BLOG,
        payload: item,
    }
}

