import { useWindowSize } from '@react-hook/window-size'
import React, { useState } from 'react'
import Select from "react-select";
import DataTable from "react-data-table-component";
import DatePicker from "react-date-picker";
import { Row, Col, Label, Button } from 'reactstrap';
import TablesColumns from './TablesColumns.js';
import PaymentService from '../../../../api/services/PaymentService'
import application_contants from '../../../constants/application_contant'
import DashboardLayout from '../../../customComponents/InternalLayout/DashboardLayout'
import Loader from '../../../customComponents/Loader/Loader'
import RenderPage from '../../../customComponents/RenderPage/RenderPage'
import Strip from '../../../customComponents/Strip/Strip'
import { dataTablesMobileStyledTheme, dataTablesStyledTheme } from '../../../utils/dataTablesStyledTheme';
import SevaService from '../../../../api/services/SevaService.js';
import { displayYYMMDD } from '../../../utils/displayFormatter.js';

const Payment = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [sevaType, setSevaType] = useState('');
    const [subSevaType, setSubSevaType] = useState('');
    const [sevaData, setSevaData] = useState([]);
    const [subSevaData, setSubSevaData] = useState([]);


    const [width] = useWindowSize();

    React.useEffect(() => {
        getPaymentInformation();
        getSevaList();

    }, [])

    const getPaymentInformation = async () => {
        setIsLoading(true);
        await PaymentService.getPaymentList()
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                setIsLoading(false);
                                setData(result.data ? result.data.list.length > 0 ? result.data.list : [] : []);

                            } else {
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });

    }

    const getSevaList = async () => {
        setIsLoading(true);
        await SevaService.getSevaList(0)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                setIsLoading(false);
                                if (result.data.list) {
                                    let sevaObj = {}
                                    let sevaListOptions = [];
                                    let subSevaObj = {};
                                    let subSevaOptions = [];
                                    result.data.list.map((it) => {
                                        sevaObj = {
                                            label: it.sevaType,
                                            value: it.id
                                        };
                                        sevaListOptions.push(sevaObj);
                                        if (it.subSeva.length > 0) {
                                            it.subSeva.map((sub) => {
                                                subSevaObj = {
                                                    label: sub.sevaType,
                                                    value: sub.subSevaId
                                                };
                                                subSevaOptions.push(subSevaObj);
                                            });
                                        }
                                    });
                                    setSevaData([...sevaListOptions]);
                                    setSubSevaData([...subSevaOptions])
                                }

                            } else {
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });

    }

    const handleStartDate = async (date) => {
        setStartDate(date.toDateString());
    };

    const handleEndDate = async (date) => {
        setEndDate(date.toDateString());
    };

    const handleSevaType = (item) => {
        setSevaType(item)
    }

    const handleSubSevaType = (item) => {
        setSubSevaType(item)
    }

    const handleApply = async () => {
        const data = {
            startDate: displayYYMMDD(startDate),
            endDate: displayYYMMDD(endDate),
            sevaId: sevaType ? sevaType.value : -1,
            subSevaId: subSevaType ? subSevaType.value : -1,
        }
        await PaymentService.paymentFilters(data)
            .then(async (res) => {
                if (res.status === 200) {
                    try {
                        res.text().then(async (res) => {
                            let result = JSON.parse(res);
                            if (result.code === 0) {
                                setIsLoading(false);
                                setData(result.data ? result.data.list.length > 0 ? result.data.list : [] : []);

                            } else {
                                setIsLoading(false);
                            }
                        });
                    } catch (err) {
                        console.log(err);
                        setIsLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    }

    const handleReset = async () => {
        await getPaymentInformation();
        setStartDate(new Date());
        setEndDate(new Date());
        setSevaType('');
        setSubSevaType('');
    }

    return (
        <DashboardLayout>
            <RenderPage
                id="about-page"
                className="render-page mt-5"
                containerType="container-fluid"
            >
                <Strip
                    id="tst-about"
                    className="strip strip-no-padding"
                    containerType="container admin-container box-shadow"
                >
                    <Row className="mx-0 pt-3 align-items-end">
                        <Col xs={12} sm={12} md={6} lg={2} xl={2} className="mobile-pb12px">
                            <Label className="common-label text-left">
                                Start Date*:
                            </Label>
                            <DatePicker
                                type="text"
                                id="date"
                                onChange={(date) => handleStartDate(date)}
                                value={new Date(startDate)}
                                maxDate={new Date()}
                                className="react-date-picker"
                                showLeadingZeros={true}
                                clearIcon={false}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={2} xl={2} className="mobile-pb12px">
                            <Label className="common-label text-left">
                                End Date*:
                            </Label>
                            <DatePicker
                                type="text"
                                id="date"
                                onChange={(date) => handleEndDate(date)}
                                value={new Date(endDate)}
                                maxDate={new Date()}
                                className="react-date-picker"
                                showLeadingZeros={true}
                                clearIcon={false}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={3} xl={3} className="mobile-pb12px">
                            <Label className="common-label text-left">
                                Seva Type*:
                            </Label>
                            <Select
                                value={sevaType}
                                onChange={handleSevaType}
                                options={sevaData}
                                placeholder={"Select"}
                                classNamePrefix="common-select-drop-down"
                                isSearchable={false}
                                isClearable
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={3} xl={3} className="mobile-pb12px">
                            <Label className="common-label text-left">
                                SubSeva Type*:
                            </Label>
                            <Select
                                value={subSevaType}
                                onChange={handleSubSevaType}
                                options={subSevaData}
                                placeholder={"Select"}
                                classNamePrefix="common-select-drop-down"
                                isSearchable={false}
                                isClearable
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={2} xl={2} className="mobile-pb12px">
                            <div className="align-item-center justify-content-between">
                                <div>
                                    <Button className="btn btn-block custom-green-btn custom-btn--small" onClick={() => handleApply()}>Apply</Button>
                                </div>
                                <div className="mt-2">
                                    <Button className="btn btn-block custom-theme-btn custom-btn--small" onClick={() => handleReset()}>Reset</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mx-0">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="pb-3 pt-3">
                                {!isLoading ? (
                                    <DataTable
                                        title=""
                                        columns={TablesColumns()}
                                        data={data}
                                        pagination
                                        // expandableRows
                                        className="data-table"
                                        persistTableHead={false}
                                        highlightOnHover
                                        // expandableRowsComponent={<ExpandedComponent />}
                                        customStyles={
                                            width > application_contants.MOBILE_BREAKPOINT
                                                ? dataTablesStyledTheme
                                                : dataTablesMobileStyledTheme
                                        }
                                    // onRowClicked={(data) => this.getHospitalItem(data)}
                                    />
                                ) : (
                                    <div className="pt-32px pb-64px d-flex justify-content-center">
                                        <Loader containerClassName="" />
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Strip>
            </RenderPage>
        </DashboardLayout>
    )

}


export default Payment;

