import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from '@react-hook/window-size'
import application_contants from '../../constants/application_contant';
import Carousel from '../../customComponents/ScrollSlider/Carousel/index'
import CarouselItem from '../../customComponents/ScrollSlider/CarouselItem';
import SlideData from '../../utils/json/slideData';
import Acknowledgments from '../Acknowledgments/Acknowledgments';
import Anniversary from '../AnniversaryCelebration/Anniversary';
import AnniversaryCelebration from '../AnniversaryCelebration/AnniversaryCelebration';
import Blog from '../Blog/Blog';
import BlogDetails from '../BlogDetails/BlogDetails';
import ChairmansMessage from '../ChairmansMessage/ChairmansMessage';
import Donate from '../Donate/Donate';
import Foundation from '../Foundation/Foundation';
import GuruParampara from '../GuruParampara/GuruParampara';
import BioHistoy from '../ProjectInitiatives/Component/BioHistory';
import BioHistoryFoundation from '../Foundation/Component/BioHistory';
import ProjectInitiatives from '../ProjectInitiatives/ProjectInitiatives';
import Recommend from '../Recommend/Recommend';
import SevaDonate from '../SevaDonate/SevaDonate';
import SevaInfo from '../SevaInfo/SevaInfo';
import SevaTypes from '../SevaTypes/SevaTypes';
import Banner from '../Srilaprahupada/Component/Banner';
import SubSeva from '../SubSeva/SubSeva';
import ThankYou from '../ThankYou/ThankYou';
import Trustees from '../Trustees/Trustees';
import Volunteer from '../Volunteer/Volunteer';
import SrilaprahupadaBioHistory from '../Srilaprahupada/Component/BioHistory';
import SubmissionsGuidelines from '../SubmissionsGuidelines/SubmissionsGuidelines';
import About from '../About/About';
import Editorials from '../Editorials/Editorials';
import Events from '../Events/Events';
import News from '../News/News';
import ContactUs from '../ContactUs/ContactUs';
import Features from '../Features/Features';
import MobileHome from './Component/MobileHome';

const Home = () => {
    const [width] = useWindowSize();
    const name = useSelector(state => state.currentPage.name);


    if (name === application_contants.HOME) {
        return (
            <div>
                {width <= 1024 ? 
                  <MobileHome data={SlideData}/>
                :
                <Carousel current={0} next={1} prev={SlideData.length - 1} data={SlideData}>
                    {SlideData.length > 0 && SlideData.map((item, index) => (
                        <div key={index}>
                            <CarouselItem item={item} length={SlideData.length} index={index} />
                        </div>
                    ))}
                </Carousel>}
            </div>
        );
    } else if (name === application_contants.SRILAPRABHUPADA) {
        return <Banner name={name} />
    } else if (name === application_contants.SRILAPRABHUPADAHISTORY) {
        return <SrilaprahupadaBioHistory name={name} />
    } else if (name === application_contants.SRILAPRABHUPADAFOUNDATION) {
        return <Foundation name={name} />
    } else if (name === application_contants.FOUNDATIONHISTORY) {
        return <BioHistoryFoundation name={name} />
    } else if (name === application_contants.PROJECTINITIATIVES || name === application_contants.PROJECTANDINITIATIVES) {
        return <ProjectInitiatives name={name} />;
    } else if (name === application_contants.PROJECTINHISTORY) {
        return <BioHistoy name={name} />;
    } else if (name === application_contants.BLOG) {
        return <Blog name={name} />
    } else if (name === application_contants.DONATE) {
        return <Donate name={name} />
    } else if (name === application_contants.ANNIVERSARY) {
        return <Anniversary name={name} />
    } else if (name === application_contants.ANNIVERSARYCELEBRATION) {
        return <AnniversaryCelebration name={name} />
    } else if (name === application_contants.RECOMMEND) {
        return <Recommend name={name} />
    } else if (name === application_contants.SEVATYPES) {
        return <SevaTypes name={name} />
    } else if (name === application_contants.SEVAINFO) {
        return <SevaInfo name={name} />
    } else if (name === application_contants.SUBSEVA) {
        return <SubSeva name={name} />
    } else if (name === application_contants.SEVADONATE) {
        return <SevaDonate name={name} />
    } else if (name === application_contants.NAMATHE) {
        return <ThankYou name={name} />
    } else if (name === application_contants.BLOGDETAILSITEM) {
        return <BlogDetails name={name} />
    } else if (name === application_contants.TRUSTEES) {
        return <Trustees name={name} />
    } else if (name === application_contants.GURUPARAMPARA) {
        return <GuruParampara name={name} />
    } else if (name === application_contants.ACKNOWLEDGMENTS) {
        return <Acknowledgments name={name} />
    } else if (name === application_contants.VOLUNTEER) {
        return <Volunteer name={name} />
    } else if (name === application_contants.CHAIRMANMESSAGES) {
        return <ChairmansMessage name={name} />
    } else if (name === application_contants.SUBMISSIONSGUIDNELINES) {
        return <SubmissionsGuidelines name={name} />
    } else if (name === application_contants.ABOUT) {
        return <About name={name} />
    } else if (name === application_contants.EDITORIALS) {
        return <Editorials name={name} />
    } else if (name === application_contants.EVENTS) {
        return <Events name={name} />
    } else if (name === application_contants.NEWS) {
        return <News name={name} />
    } else if (name === application_contants.CONTACTUS) {
        return <ContactUs name={name} />
    } else if (name === application_contants.FEATURES) {
        return <Features name={name} />
    }
}

export default Home;