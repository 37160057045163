import { useWindowWidth } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import apiConfig from '../../../api/apis/apiConfig';
import application_contants from '../../constants/application_contant';
import Header from '../../customComponents/Header';
import { setCurrentPage } from '../../redux/actions/currentPage';
import { updateSeva } from '../../redux/actions/updateSeva';

const SubSeva = () => {
    const state = useSelector(state => state);
    const { direction } = state;
    const { item } = state.sevaItem;
    const dispatch = useDispatch();
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [classNamesLR, setClassNamesLF] = useState('');
    const onlyWidth = useWindowWidth();

    React.useEffect(() => {
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn animate__slow` : `animate__animated animate__zoomIn animate__slow`
            setClassNames(cls);
            let clsLR = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideInRight animate__slow` : `animate__animated animate__slideInRight animate__slow`
            setClassNamesLF(clsLR)
        }, 500)
        return () => {

            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
                setClassNamesLF('');
            }, 200)
        }
    }, [direction]);


    const handleSelectedItem = (it) => {
        dispatch(updateSeva(it));
        dispatch(setCurrentPage(application_contants.SEVAINFO));
    }

    const handleBack =() => {
        dispatch(setCurrentPage(application_contants.SEVATYPES));  
    }

    return (
        <div className="seva-info-content-container pt-4rem">
            <Header isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} menuClassNames={'title-melanzane-Violet'} isScroll={false} isFooter={false} bgClassName={`bg-narvik-green`}/>
            {classNames !== '' ?
                <Row className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT || onlyWidth <= 1024 ? 'py-5' : ''} mx-0 align-items-center full-width`}>
                    <Col xs={12} sm={12} md={10} lg={onlyWidth === 1270 ? 12: 10} xl={onlyWidth === 1270 ? 12: 10} className="m-auto">
                        <Row className="mx-0 align-items-start full-width">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames}` : ''}`}>
                                <Row className="mx-0 full-width">
                                    <Col xs={12} sm={12} md={12} lg={onlyWidth <= 1024 ? 7 : 4} xl={4} className="m-auto">
                                        <div className="seva-info-img--container">
                                            <div className="position-relative">
                                                <div className="seva-info-img--position">
                                                    <img src={`${apiConfig.URL}${item.imgPath}`} alt="" />
                                                </div>
                                                <div className="seva-info-img-title--position">
                                                    <div className="position-relative">
                                                        <img src={application_contants.SEVAINFOASSETS4} alt="" />
                                                    </div>
                                                    <div className="seva-info-title--position">
                                                        <div>
                                                            <p className="p-very-small sub-section-title gothamBook fw-600 title-white text-uppercase">{item.sevaType}</p>
                                                            <p className="p-very-small sub-section-title gothamBook fw-600 title-white text-uppercase">{item.sevaTypeTitle}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={{ offset: 1, size: 12 }} xl={{ offset: 1, size: 12 }} className={`${isAnimate ? `${classNamesLR}` : ''} m-auto`}>
                                {item.subSeva.length > 0 ?
                                    <div className="mt-5">
                                        <Row className="pt-5 pb-5">
                                            {item.subSeva.map((subitem, ind) => (
                                                <Col lg={onlyWidth <= 1024 ? 7 : 4} xl={4} className={`position-relative sub-seva-donate-btn hover-one ${onlyWidth <= 1024 ? 'm-auto' : ''}`} key={ind}>
                                                    <img src={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT || onlyWidth <= 1024 ? application_contants.MOBILESEVABANNER : application_contants.SEVAINFOASSETS4}`} alt="" />
                                                    <div className="donate-btn-text--position" onClick={() => handleSelectedItem(subitem)}>
                                                        <p className={`${onlyWidth <= application_contants.MOBILE_BREAKPOINT || onlyWidth <= 1024 ? 'pb-3 p-medium' : 'p-very-small'} sub-section-title title-white pt-1 txt-uppercase gothamBook fw-600 btn--small cursor-pointer`}>{subitem.sevaType}</p>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                    : null
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                : null}
            <div className="donate-go-back-container" onClick={() => handleBack()}>
                <p className="p-small sub-section-title saolStandardMedium align-left fw-600 cursor-pointer">GO BACK</p>
            </div>
        </div>
    )
};


export default SubSeva