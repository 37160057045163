import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import application_contants from '../../constants/application_contant';
import { setCurrentPage } from '../../redux/actions/currentPage';
import { setCurrentPosition } from '../../redux/actions/currentPosition';
import { setDirection } from '../../redux/actions/direction';
import { setPlayStatus } from '../../redux/actions/playStatus';
import BlogHeaderMenu from '../../utils/json/blogHeaderMenu';
import BlogMenuData from '../../utils/json/BlogMenuData';
import { useWindowSize } from '@react-hook/window-size';


const BlogHeader = (props) => {
    const [width] = useWindowSize();
    const [isOpen, setIsOpen] = useState(false);
    const state = useSelector(state => state);
    const { isPlay, currentPage } = state;
    const dispatch = useDispatch();

    React.useEffect(() => {
        setIsOpen(false)
    }, [state])

    const handleMenuOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleNavigation = (name) => {
        dispatch(setCurrentPage(name));
        dispatch(setCurrentPosition(0));
        dispatch(setDirection(application_contants.FORWARD));
        dispatch(setPlayStatus(false))
    }

    return (
        <div>
            <div>
                <div className={`blog-header--container animate__animated animate__slower ${props.isMenuAnimate ? isPlay && isPlay.isPlay ? 'animate__fadeOut' : '' : ''} ${`animate__delay-4s`} `}>
                    <div className="width-95 align-left position-relative">
                        <img src={`${width <= application_contants.MOBILE_BREAKPOINT ? application_contants.MOBILEBLOGHEADER : application_contants.BLOGHEADERIMG}`} alt="" className="img-item cursor-pointer" onClick={() => handleNavigation(application_contants.BLOG)} />
                        {width <= application_contants.MOBILE_BREAKPOINT || width <= 1024 ? null :
                            <div className="blog-menu-list--container">
                                {BlogHeaderMenu.map((it, index) => (
                                    <div key={index} className={index !== 0 ? 'line' : ''}>
                                        <p className={`p-small sub-section-title magdalenaAltMedium title-light-blue align-right cursor-pointer p-2 menu-item`} onClick={() => handleNavigation(it.link)}>{it.name}</p>
                                    </div>
                                ))}
                            </div>}
                    </div>
                    <div className={`align-center ${width <= application_contants.MOBILE_BREAKPOINT || width <= 1024 ? 'width_10' : 'width-5'}`}>
                        <img src={props.isBlackLogo ? application_contants.BLACKMENUIMG : application_contants.MENUIMG} alt="" className="img-menu-icon cursor-pointer" onClick={() => handleMenuOpen()} />
                        {width <= application_contants.MOBILE_BREAKPOINT ? null :
                            <div>
                                {isOpen ?
                                    <div className="menu-list-container">
                                        <div className={`${width <= application_contants.MOBILE_BREAKPOINT || width <= 1024 ? 'menu-list-bg-container' : ''}`}>
                                            {BlogHeaderMenu.map((it, index) => (
                                                <div key={index} className={`animate__animated ${isOpen ? 'animate__fadeInUp' : ''} ${`animate__delay-${0.5}s`} `} onClick={() => handleNavigation(it.link)}>
                                                    <p className={`p-very-small sub-section-title porterBold py-1 align-right cursor-pointer menu-item ${currentPage.name === it.name ? `${props.isBlackMenu ? 'title-stack-grey' : 'title-orinoco-green'}` : `${props.menuClassNames}`}`}>{it.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div> : null}
                            </div>}
                    </div>
                </div>
                {width <= application_contants.MOBILE_BREAKPOINT ?
                    <div className="blog-mobile-menu-container">
                        <div className="d-flex align-items-center">
                            {BlogHeaderMenu.map((it, index) => (
                                <div key={index} className="px-4 py-3" onClick={() => handleNavigation(it.link)}>
                                    <p className={`p-small sub-section-title porterBold align-center cursor-pointer menu-item ${index === 0 ? 'width_9rem': index === BlogHeaderMenu.length - 1 ? 'width_6rem' : ''} ${currentPage.name === it.name ? 'title-stack-grey' : 'title-orinoco-green'}`}>{it.name}</p>
                                </div>
                            ))}
                        </div>
                    </div> : null}
            </div>
            {width <= application_contants.MOBILE_BREAKPOINT && isOpen ?
                <div>
                    <div className="mobile-header-container">
                        <div>
                            <div className="menu-close-icon">
                                <img src={application_contants.TIMESIMG} alt="" onClick={() => handleMenuOpen()} />
                            </div>
                            <div>
                                {BlogHeaderMenu.map((it, index) => (
                                    <div key={index} className={`animate__animated ${isOpen ? 'animate__fadeInUp' : ''} ${`animate__delay-${0.5}s`} `} onClick={() => handleNavigation(it.link)}>
                                        <h3 className={`h3-small sub-section-title porterBold p-3 align-center lh-34px cursor-pointer menu-item ${currentPage.name === it.name ? 'title-orinoco-green' : 'title-melanzane-Violet'}`}>{it.name}</h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div> : null}
            {props.isScroll ?
                <div className={`info-container animate__animated  animate__slower ${props.scrollPositionClassNames} ${props.isMenuAnimate ? isPlay && isPlay.isPlay ? 'animate__fadeOut' : '' : ''} ${`animate__delay-${1}s`} `}>
                    <div className="scroll-icon">
                        <div className="dot animate__animated animate__slideInUp animate__infinite animate__slow"></div>
                    </div>
                </div> : null}
        </div>
    )
}

BlogHeader.propTypes = {
    isBlackMenu: PropTypes.bool,
    isBlackLogo: PropTypes.bool,
    isBlackMedia: PropTypes.bool,
    isMenuAnimate: PropTypes.bool,
    menuClassNames: PropTypes.string,
    scrollPositionClassNames: PropTypes.string,
    isScroll: PropTypes.bool,
};

BlogHeader.defaultProps = {
    isBlackMenu: false,
    isBlackLogo: false,
    isBlackMedia: false,
    isMenuAnimate: false,
    menuClassNames: 'title-white',
    scrollPositionClassNames: 'bt-0',
    isScroll: true,
};

export default BlogHeader;