import { ActionTypes } from "../constants/action-types"

const initialState = {
    isPlay: false
}

export const playStatusReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_PLAY:
            return { ...state, isPlay: payload };
        default:
            return state;
    }
}