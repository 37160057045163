const BlogStatus = [
    {
        label: 'Pending',
        value: 0,
    },
    {
        label: 'Approved',
        value: 1,
    },
    {
        label: 'Rejected',
        value: 2,
    }
];

export default BlogStatus;