import { useWindowSize } from '@react-hook/window-size';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import BlogFooter from '../../customComponents/BlogFooter/BlogFooter';
import BlogHeader from '../../customComponents/BlogHeader/BlogHeader';
import SubmissionsGuidelinesForm from '../../customComponents/SubmissionsGuidelinesForm/SubmissionsGuidelinesForm';
import SubmissionsGuidelinesData from '../../utils/json/SubmissionsGuidelinesData';

const SubmissionsGuidelines = () => {
    const [width] = useWindowSize();
    const state = useSelector(state => state);
    const { direction } = state;;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 100)
        }
    }, [direction, isRefresh]);

    const handleRefresh = () => {
        setIsRefresh(!isRefresh)
    }

    return (
        <div className="blog-container submission-container">
            <BlogHeader isScroll={false} isBlackLogo={true} isBlackMenu={true} menuClassNames={`title-melanzane-Violet`} />
            <div className="mt-5">
                <Row className={`mx-0 pb-5 ${width <= application_contants.MOBILE_BREAKPOINT ? 'mt-7rem' : 'mt-7rem'}`}>
                    <Col xs={11} sm={11} md={11} lg={10} xl={10} className="m-auto pt-4">
                        {SubmissionsGuidelinesData.length > 0 ?
                            <div>
                                {SubmissionsGuidelinesData.length > 0 && SubmissionsGuidelinesData.map((it, index) => (
                                    <Row className="mx-0" key={index}>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                            <h2 className="sub-section-title align-left lh-24px pt-4 pb-3">{it.title}</h2>
                                            <h5 className="h5-small sub-section-title align-left gothamBlack lh-24px py-3">{it.shortDesc}</h5>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`pt-3 ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                            {it.info.length > 0 && it.info.map((i, ind) => (
                                                <Row className="mx-0" key={ind}>
                                                    <Col className="px-0 pb-3">
                                                        <h5 className="h5-small sub-section-title align-left gothamBlack lh-24px pb-2">{i.question}</h5>
                                                        {i.answer.split(/[\n\r]/g).map((params) => (
                                                            <p className="h6-small sub-section-title align-left gothamBook lh-24px pb-3">{params}</p>
                                                        ))}
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                            <h5 className="h5-small sub-section-title align-left gothamBlack lh-24px pb-2">Content Format</h5>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                            {it.content.length > 0 && it.content.map((i, ind) => (
                                                <Row className="mx-0" key={ind}>
                                                    <Col className="px-0 pb-3">
                                                        <p className="h6-small sub-section-title align-left gothamBook lh-24px">
                                                            <font className="gothamBlack">{i.title}:</font>
                                                            {i.desc.split(/[\n\r]/g).map((param) => (
                                                                <span className="pl-6px">{param}</span>
                                                            ))}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                            <SubmissionsGuidelinesForm handleRefresh={handleRefresh}/>
                                        </Col>
                                    </Row>
                                ))}

                            </div>
                            : null}
                    </Col>
                </Row>
            </div>
           <BlogFooter/>
        </div>
    )
};

export default SubmissionsGuidelines;