import SectionImg1 from '../assets/ASSETS-04.png'
import SectionImg2 from '../assets/ASSETS-05.png'
import SectionImg3 from '../assets/ASSETS-06.png'
import SectionImg4 from '../assets/ASSETS-07.png'
import SectionImg5 from '../assets/ASSETS-08.png'
import ArrowImg from '../assets/ASSETS-09.png';
import FlyingBrid from '../assets/ASSETS-10.png';
import SignatureImg from '../assets/ASSETS-11.png';
import PlayIcon from '../assets/ASSETS-12.png';
import Menu from '../assets/ASSETS-13.png';
import BoxShadowImg from '../assets/ASSETS-14.png';
import Assets15 from '../assets/ASSETS-15.png';
import Assest16 from '../assets/ASSETS-16.png';
import LogoImg from '../assets/logo.png';
import Instagram from '../assets/instagram.png';
import Facebook from '../assets/facebook.png';
import Linkedin from '../assets/linkedin.png';
import Youtube from '../assets/youtube.png';
import Twitter from '../assets/twitter.png';
//import Backward from '../assets/backward1.mp4';
import Forward from '../assets/forward1.mp4';
import Poster from '../assets/poster.jpg';
import PrabhupadaImg from '../assets/Prabhupada.png';
import ArrowDown from '../assets/ARROW-DOWN.png';
import ArrowUp from '../assets/ARROW-UP.png';
import Line from '../assets/line.png';
import Prabu from '../assets/prabu.png';
import PrabuFace from '../assets/prabu-face.png';
import DescCard from '../assets/desc-card.png';
import BlackLogo from '../assets/black-logo.png';
import BlackMenu from '../assets/black-menu.png';
import WhiteFacebook from '../assets/white-facebook.png';
import WhiteTwitter from '../assets/white-twitter.png';
import WhiteLinkedin from '../assets/white-linkedin.png';
import WhiteInstagram from '../assets/white-instagram.png';
import WhiteYoutube from '../assets/white-youtube.png';
import BirthAnniverseryImg from '../assets/birthanniversery.png';
import DonatePrabhuImg from '../assets/prabu1.png';
import PublicImg from '../assets/public.png';
import QuotationDonateImg from '../assets/quotation-donate.png';
import bgProjectImg from '../assets/bg-project.png';
import prabuReadingImg from '../assets/prabu-reading.png';
import youngPrabuImg from '../assets/young-prabu.png';
import bgBlogImg from '../assets/bg-blog.png';
import WhitePlayImg from '../assets/white-play.png';
import blogHeader from '../assets/blog-header.png';
import blogMobileHeader from '../assets/blog-header-logo.png';
//import blogVideo from '../assets/blog-video.mp4';
import BAssets1 from '../assets/BASSETS-1.png';
import BAssets2 from '../assets/BASSETS-2.png';
import BAssets3 from '../assets/BASSETS-3.png';
import BAssets4 from '../assets/BASSETS-4.png';
import BAssets5 from '../assets/BASSETS-5.png';
import sevaTypeImg from '../assets/seva-type.png';
import sevaAssets1Img from '../assets/seva-assets1.png';
import sevaAssets2Img from '../assets/seva-assets2.png';
import sevaInfoAssets3Img from '../assets/seva-info-assets.png';
import sevaInfoAssets4Img from '../assets/seva-info-assets1.png';
import whiteDonateImg from '../assets/white-donate.png';
import indiaCountryImg from '../assets/india-country.png';
import otherCountryImg from '../assets/other-country.png';
import redDonateImg from '../assets/red-donate.png';
import btnActive from '../assets/btn-active.png';
import btnInActive from '../assets/btn-inactive.png';
import activeRadio from '../assets/active-radio.png';
import inActiveRadio from '../assets/inactive-radio.png';
import timerImg from '../assets/timer.png';
import namatheImg from '../assets/namathe.png';
import BlogBookImg from '../assets/Blog-assets-12.png';
import retweetsImg from '../assets/retweet.png';
import likesImg from '../assets/likes.png';
import shareImg from '../assets/share.png';
import thankImg from '../assets/thankImg.png';
import thankLogoImg from '../assets/thankLogo.png';
import leftImg from '../assets/left.png';
import rightImg from '../assets/right.png';
import TrustessImg1 from '../assets/Madhupandit.png';
import TrustessImg2 from '../assets/Chanchanapathi.png';
import TrusteesImg3 from '../assets/Suvyakta.png';
import TrusteesImg4 from '../assets/Siddhanta.png';
import GuruBanner from '../assets/guru/guru-parampara-banner.png';
import GuruTitleBanner from '../assets/guru/guru-parampara-title-banner.png';
import GuruImg5 from '../assets/guru/Guru-Parampara-05.png';
import GuruImg6 from '../assets/guru/Guru-Parampara-06.png';
import GuruImg7 from '../assets/guru/Guru-Parampara-07.png';
import GuruImg8 from '../assets/guru/Guru-Parampara-08.png';
import GuruImg9 from '../assets/guru/Guru-Parampara-09.png';
import GuruImg10 from '../assets/guru/Guru-Parampara-10.png';
import GuruImg11 from '../assets/guru/Guru-Parampara-11.png';
import GuruImg12 from '../assets/guru/Guru-Parampara-12.png';
import GuruImg13 from '../assets/guru/Guru-Parampara-13.png';
import GuruImg14 from '../assets/guru/Guru-Parampara-14.png';
import GuruImg15 from '../assets/guru/Guru-Parampara-15.png';
import GuruImg16 from '../assets/guru/Guru-Parampara-16.png';
import GuruImg17 from '../assets/guru/Guru-Parampara-17.png';
import GuruImg18 from '../assets/guru/Guru-Parampara-18.png';
import GuruImg19 from '../assets/guru/Guru-Parampara-19.png';
import GuruImg20 from '../assets/guru/Guru-Parampara-20.png';
import GuruImg21 from '../assets/guru/Guru-Parampara-21.png';
import GuruImg22 from '../assets/guru/Guru-Parampara-22.png';
import GuruImg23 from '../assets/guru/Guru-Parampara-23.png';
import GuruImg24 from '../assets/guru/Guru-Parampara-24.png';
import GuruImg25 from '../assets/guru/Guru-Parampara-25.png';
import GuruImg26 from '../assets/guru/Guru-Parampara-26.png';
import GuruImg27 from '../assets/guru/Guru-Parampara-27.png';
import GuruImg28 from '../assets/guru/Guru-Parampara-28.png';
import GuruImg29 from '../assets/guru/Guru-Parampara-29.png';
import GuruImg30 from '../assets/guru/Guru-Parampara-30.png';
import GuruImg31 from '../assets/guru/Guru-Parampara-31.png';
import GuruImg32 from '../assets/guru/Guru-Parampara-32.png';
import GuruImg33 from '../assets/guru/Guru-Parampara-33.png';
import GuruImg34 from '../assets/guru/Guru-Parampara-34.png';
import GuruImg35 from '../assets/guru/Guru-Parampara-35.png';
import GuruImg36 from '../assets/guru/Guru-Parampara-36.png';
import madhuPanditChairman from '../assets/madhu-pandit-chairman.png';
import madhupanditSignature from '../assets/madhu-pandit-signature.png';
import scrollArrowImg from '../assets/scroll-arrow.png';
import InfluenceImg from '../assets/influence.png';
import damodra from '../assets/damodra.png';
import damodraTemple from '../assets/damodra-temple.png';
import damodarBg from '../assets/damodra-bg.png';
import DonateTitleImg from '../assets/donate-title-img.png';
import FblogImg from '../assets/FBLOG-Img.png';
import FblogLogo from '../assets/FBLOG-logo.png';
import MobileGuruBanner from '../assets/mobile-guru-parampara.png';
import MobileBGBanner from '../assets/mobile-bg.png';
import MobileDonateBanner from '../assets/mobile-donate-bg.png';
import MobileSevaBanner from '../assets/mobile-seva-bg.png';
import Times from '../assets/times.png';
import mobileVideo from '../assets/mobile-video.mp4';
import UnSelectImg from '../assets/un-select.png';
import SelectImg from '../assets/select.png';

const application_contants = {
    SECTIONIMG1: SectionImg1,
    SECTIONIMG2: SectionImg2,
    SECTIONIMG3: SectionImg3,
    SECTIONIMG4: SectionImg4,
    SECTIONIMG5: SectionImg5,
    FLYINGBRIDIMG: FlyingBrid,
    SIGNATUREIMG: SignatureImg,
    MENUIMG: Menu,
    LOGOIMG: LogoImg,
    INSTAGRAMIMG: Instagram,
    FACEBOOKIMG: Facebook,
    LINKEDINIMG: Linkedin,
    YOUTUBEIMG: Youtube,
    TWITTERIMG: Twitter,
    // BACKWARDVIDEO: Backward,
    FORWARDVIDEO: Forward,
    POSTERIMG: Poster,
    ARROWIMG: ArrowImg,
    BOXSHADOWIMG: BoxShadowImg,
    PRABHUPADAIMG: PrabhupadaImg,
    ARROWDOWN: ArrowDown,
    ARROWUP: ArrowUp,
    LINEIMG: Line,
    ASSETS15: Assets15,
    ASSETS16: Assest16,
    PRABHUANNIVERSARYIMG: Prabu,
    PRABHUFACEIMG: PrabuFace,
    DESCCARDIMG: DescCard,
    PLAYICONIMG: PlayIcon,
    BLACKLOGOIMG: BlackLogo,
    BLACKMENUIMG: BlackMenu,
    WHITEFACEBOOKIMG: WhiteFacebook,
    WHITERTWITTERIMG: WhiteTwitter,
    WHITELINKEDIN: WhiteLinkedin,
    WHITEINSTAGRAM: WhiteInstagram,
    WHITEYOUTUBE: WhiteYoutube,
    BRITHANNIVERSARYIMG: BirthAnniverseryImg,
    DONATEPRABHUIMG: DonatePrabhuImg,
    PUBLICIMG: PublicImg,
    QUOTATIONDONATEIMG: QuotationDonateImg,
    BGPROJECTIMG: bgProjectImg,
    PRABUREADINGIMG: prabuReadingImg,
    YOUNGPRABUIMG: youngPrabuImg,
    BGBLOGIMG: bgBlogImg,
    WHITEPLAYIMG: WhitePlayImg,
    BLOGHEADERIMG: blogHeader,
    //BLOGVIDEO: blogVideo,
    BASSETS1: BAssets1,
    BASSETS2: BAssets2,
    BASSETS3: BAssets3,
    BASSETS4: BAssets4,
    BASSETS5: BAssets5,
    SEVATYPEIMG: sevaTypeImg,
    SEVAASSETS1: sevaAssets1Img,
    SEVAASSETS2: sevaAssets2Img,
    SEVAINFOASSETS3: sevaInfoAssets3Img,
    SEVAINFOASSETS4: sevaInfoAssets4Img,
    WHITEDONATEIMG: whiteDonateImg,
    INDIACOUNTRYIMG: indiaCountryImg,
    OTHERCOUNTRYIMG: otherCountryImg,
    REDDONATEIMG: redDonateImg,
    BTNACTIVEIMG: btnActive,
    BTNINACTIVEIMG: btnInActive,
    ACTIVERADIOIMG: activeRadio,
    INACTIVERADIOIMG: inActiveRadio,
    TIMERIMG: timerImg,
    NAMATHEIMG: namatheImg,
    BLOGBOOKIMG: BlogBookImg,
    LIKESIMG: likesImg,
    RETWEETSIMG: retweetsImg,
    THANKSLOGOIMG: thankLogoImg,
    THANKIMG: thankImg,
    SHAREIMG: shareImg,
    LEFTARROWIMG: leftImg,
    RIGHTARROWIMG: rightImg,
    TRUSTESSIMG1: TrustessImg1,
    TRUSTESSIMG2: TrustessImg2,
    TRUSTESSIMG3: TrusteesImg3,
    TRUSTESSIMG4: TrusteesImg4,
    GURUPARAMPARABANNER: GuruBanner,
    GURUPARAMPARATITLEBANNER: GuruTitleBanner,
    GURUIMG5: GuruImg5,
    GURUIMG6: GuruImg6,
    GURUIMG7: GuruImg7,
    GURUIMG8: GuruImg8,
    GURUIMG9: GuruImg9,
    GURUIMG10: GuruImg10,
    GURUIMG11: GuruImg11,
    GURUIMG12: GuruImg12,
    GURUIMG13: GuruImg13,
    GURUIMG14: GuruImg14,
    GURUIMG15: GuruImg15,
    GURUIMG16: GuruImg16,
    GURUIMG17: GuruImg17,
    GURUIMG18: GuruImg18,
    GURUIMG19: GuruImg19,
    GURUIMG20: GuruImg20,
    GURUIMG21: GuruImg21,
    GURUIMG22: GuruImg22,
    GURUIMG23: GuruImg23,
    GURUIMG24: GuruImg24,
    GURUIMG25: GuruImg25,
    GURUIMG26: GuruImg26,
    GURUIMG27: GuruImg27,
    GURUIMG28: GuruImg28,
    GURUIMG29: GuruImg29,
    GURUIMG30: GuruImg30,
    GURUIMG31: GuruImg31,
    GURUIMG32: GuruImg32,
    GURUIMG33: GuruImg33,
    GURUIMG34: GuruImg34,
    GURUIMG35: GuruImg35,
    GURUIMG36: GuruImg36,
    MADHUPANDITCHAIRMANIMG: madhuPanditChairman,
    MADHUPANDITSIGNATUREIMG: madhupanditSignature,
    SCROLLARROWIMG: scrollArrowImg,
    INFLUENCEIMG: InfluenceImg,
    DAMODRA: damodra,
    DAMODRATEMPLE: damodraTemple,
    DAMODARBG: damodarBg,
    DONATETITLEIMG: DonateTitleImg,
    FBLOGIMG: FblogImg,
    FBLOGLOGO: FblogLogo,
    MOBILEGURUBANNER: MobileGuruBanner,
    MobileBGBanner: MobileBGBanner,
    MOBILEDONATEBANNER: MobileDonateBanner,
    MOBILESEVABANNER: MobileSevaBanner,
    MOBILEBLOGHEADER:blogMobileHeader,
    TIMESIMG: Times,
    MOBILEVIDEO: mobileVideo,
    UNSELECTEDIMG: UnSelectImg,
    SELECTIMG: SelectImg,
    MOBILE_BREAKPOINT: 1023,
    // page constant
    NONE: 'NONE',
    FORWARD: 'FORWARD',
    BACKWARD: 'BACKWARD',
    HOME: 'Home',
    SRILAPRABHUPADA: 'Srila Prabhupada',
    SRILAPRABHUPADAHISTORY: 'Srila Prabhupada History',
    SRILAPRABHUPADAFOUNDATION: 'Srila Prabhupada Foundation',
    FOUNDATIONHISTORY: 'Foundation History',
    PROJECTINITIATIVES: 'Projects and Initiatives',
    PROJECTINHISTORY: 'Project History',
    PROJECTANDINITIATIVES: 'Projects & Initiatives',
    BLOG: 'Blog',
    DONATE: 'Donate',
    ANNIVERSARY: 'Anniversary',
    RECOMMEND: 'Recommend',
    SEVATYPES: 'seva-types',
    SEVAINFO: 'seva-info',
    SEVADONATE: 'seva-donate',
    SUBSEVA: 'sub-seva',
    NAMATHE: 'namathe',
    BLOGDETAILSITEM: 'blog-details-item',
    TRUSTEES: 'trustees',
    GURUPARAMPARA: 'guru parampara',
    ACKNOWLEDGMENTS: 'Acknowledgments',
    VOLUNTEER: 'Volunteer',
    CHAIRMANMESSAGES: 'Chairman’s Message',
    ANNIVERSARYCELEBRATION: 'Anniversary Celebration',
    SUBMISSIONSGUIDNELINES: 'submissions guidelines',
    ABOUT: 'About',
    EDITORIALS:'Editorials',
    EVENTS: 'Events',
    FEATURES: 'Features',
    NEWS: 'News',
    CONTACTUS :'Contact Us', 
    //payment type
    DOMESTIC: 'Domestic',

    PAYMENT: '/payment',
    ADMINSEVA: '/seva',
    ADMINSEVALIST: '/seva/seva-list',
    ADMINSUBSEVALIST: '/seva/sub-seva-list',
    ADMINBLOGLIST: '/blog/blog-list',
    ADMINBLOGVIDEOLIST: '/blog/video-list',
    ADMINBLOGIMAGELIST: '/blog/img-list',
    ADMINBLOGSUBMISSIONLIST: '/blog/submission',
};


export default application_contants
