import application_contants from "../../constants/application_contant";

const FoundationData =[
    {
       title: 'Srila Prabhupada',
       subTitle: 'FOUNDATION',
       text: 'Preserving the glorious legacy of His Divine Grace A.C. Bhaktivedanta Swami Prabhupada',
       sectionImg: application_contants.PRABHUFACEIMG,  
       section: 1,
    },
    {
        bio: [
            {
                title: 'Srila Prabhupada Foundation',
                desc: [
                    // {
                    //     index: 8,
                    //     params: `About Srila Prabhupada Foundation`,
                    //     isBold: true,
                    //     isPadding: false,
                    //     isItalic: false,
                    // },
                    {
                        index: 9,
                        params: `Srila Prabhupada Foundation has been established with a vision to preserve and perpetuate the legacy of His Divine Grace A.C. Bhaktivedanta Swami Prabhupada.`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 10,
                        params: `Srila Prabhupada singlehandedly brought about a spiritual revolution and united human society with his ideals of universal love and compassion for every living being. He practically demonstrated that all of us could become one in peace, friendship and prosperity. Through his books of wisdom, he rationally and authoritatively explained the knowledge of God, the Almighty One. He encouraged everyone to work together for the common cause of human progress. He gave us the knowledge that our ultimate goal is to return home to the spiritual kingdom of the Supreme Person, where we can experience real freedom and unending bliss.`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 12,
                        params: `It is our aim to preserve, spread and articulate the unique solutions offered by Srila Prabhupada to the problems that plague our society. We want to deepen the understanding of his life and works, and increase the awareness and appreciation of Srila Prabhupada, his saintly personality, his mission and his institution.`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 13,
                        params: `The activities of the Foundation are intended to be as follows:`,
                        isBold: false,
                        isPadding: false,
                        isItalic: true,
                    },
                    {
                        index: 14,
                        params: `Acquiring, maintaining and preserving personal papers, writings, pictures, videos, audios, news events, memorabilia and other historical materials pertaining to Srila Prabhupada’s life and works.`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 15,
                        params: `Acquiring, maintaining and preserving the papers of Srila Prabhupada’s institution, his godbrothers, his guru, his purvashrama family members and the general public who served him in various capacities and the records of organisations, associations and societies `,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 16,
                        params: `Acquiring, maintaining and preserving the places related to Srila Prabhupada`,
                        isBold: false,
                        isItalic: false,
                    },
                    {
                        index: 17,
                        params: `Establishing a Museum of Srila Prabhupada’s personal items, memorabilia, mementos and objects about his life and his institution`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 18,
                        params: `Publishing books, articles and periodicals on the life and teachings of Srila Prabhupada in various languages`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 19,
                        params: `Celebrating and propagating the importance of events in the life of Srila Prabhupada`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 20,
                        params: `Organising lectures, seminars, symposia, conferences, and exhibitions in India and abroad to promote and encourage the study of Srila Prabhupada and his teachings`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 21,
                        params:`Srila Prabhupada had articulated unique solutions to the problems of society. He successfully dealt with many stigmas based on colour, caste and creed. He helped many youngsters come out of their deep addictions. He appealed to saner members of the society through powerful logic and deep love. We wish to systematically propagate his ideas rooted in the Vedic wisdom to heal the world.`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 22,
                        params:`Developing and selling souvenirs related to Srila Prabhupada`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },
                    {
                        index: 23,
                        params: `Instituting public libraries containing the collections of papers and resources for serious study and research on Srila Prabhupada, his life, teachings, mission and institutions.`,
                        isBold: false,
                        isPadding: true,
                        isItalic: false,
                    },

                ]
            },
        ],
        moreLinks: [
            {
                index: 1,
                img: application_contants.ASSETS15,
                title: `125 th Anniversary Celebration`,
                type: 'Most Popular',
                date: '31 August 2021',
                link: application_contants.ANNIVERSARY,
            },
            {
                index: 1,
                img: application_contants.ASSETS16,
                title: `A lifetime in preparation`,
                type: 'Recommended',
                date: '31 August 2021',
                link: application_contants.RECOMMEND,
            }
        ],
        section: 3,
    },
    // {
    //     year: '125',
    //     sectionImg: application_contants.PRABHUANNIVERSARYIMG,
    //     title: 'Anniversary',
    //     subTitle: 'Celebration',
    //     section: 4,
    // },
]

export default FoundationData;