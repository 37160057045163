import application_contants from "../../constants/application_contant";

const RecommendData = [
    {
        title: 'A life time in preparation',
        subTitle: '1954 - 1965',
        img: '',
        info: [
            {
                desc: `After adopting the retired order of vanaprastha-ashrama in the year 1954, Srila Prabhupada left behind his family members, his business, and his home, to embark on a life dedicated to fulfilling the order of his spiritual master—which was to preach the message of Lord Krishna in the English language and specifically to the people of the western world. Although it was certainly very painful for Srila Prabhupada to leave his wife and children, after reaching the age of fifty-eight, he firmly believed that fulfilling the order of his spiritual master and taking Lord Krishna’s message contained in the Bhagavad-Gita and the Srimad-Bhagavatam to the people of the western world, would be far more beneficial to the human race—than to remain at home, caring only for his family members.`
            },
            {
                desc: `Srila Prabhupada found himself on the streets of Delhi, penniless and without any place to stay. Out of their kindness, a number of Delhi’s residents, who had met Srila Prabhupada came forward to provide him with both food and shelter. They were of the opinion that Srila Prabhupada was a genuine sadhu, as well as an erudite Vedic scholar, and being religious-minded Hindus, they felt privileged that they could offer their help to such a noble and saintly person. In this way, Srila Prabhupada would spend a few days at one gentleman’s residence, and then a few more days at another’s, and in this way, he managed to survive. During this difficult period, regardless of his almost penniless and homeless existence, Srila Prabhupada continued preaching Lord Krishna’s transcendental message wherever he went and also continued to write articles whenever he got the chance. During this trying time, he also spent a number of days staying in a room at the Kapoor College of Commerce, and on another occasion, he spent a few days in a room at a local Vishnu temple. Srila Prabhupada continued to receive support from the pious people of Delhi, and thanks to them, he managed to survive what was the most testing time of his entire life. Those early days in Delhi were extremely difficult and it was certainly a very severe test of his faith and determination to fulfill the order of his spiritual master.`
            },
            {
                desc: `Despite the many reversals that he had to face, Srila Prabhupada’s faith in Lord Krishna did not diminish even slightly, but instead, it increased dramatically because he knew that it was only by the Lord’s special mercy that he was able to continue with his preaching mission due to the help provided by the pious people of Delhi. Undaunted by his near beggarly existence, Srila Prabhupada always remembering the order of his guru maharaja, continued to write articles and essays in English and was always enthusiastic to preach about Krishna consciousness to anyone who was eager to listen. All those who heard Srila Prabhupada speak on the philosophy of the Gita and the Bhagavatam were greatly impressed by his deep understanding of the subject, his knowledge of Sanskrit, his scholastic presentation, and his absolute faith in guru and Krishna.`
            },
            {
                desc: `Srila Prabhupada, who was always brimming with enthusiasm to preach, would regularly visit many of Delhi’s leading businessmen, industrialists, educators, religious leaders, judges, lawyers, government officials, and a host of political luminaries, in the hope of convincing them about the importance of spreading India’s Vedic culture, not only in India but more importantly all over the world. Srila Prabhupada also attempted to garner donations from these gentlemen for restarting his magazine ‘Back to Godhead.’`
            },
            {
                desc: `Over the next four months, Srila Prabhupada’s undying desire to continue to preach regardless of his personal difficulties eventually began to bear fruit. With the support of some very favorable trustees of the Birla Mandira, Srila Prabhupada was able to organize a public gathering at the Trusts famous Lakshmi-Narayana Mandira in Delhi on December 22nd, 1955, which Srila Prabhupada advertised in the Hindustan Times. The meeting was presided over by the then president of Delhi Municipal Committee, Mr. R. N. Agarwal, and was attended by many respectable gentlemen of Delhi. All those in attendance appreciated the scholarly discourse given by Srila Prabhupada in which he discussed Vedic philosophy and the great relevance in today’s world of scriptures like Bhagavad-Gita and Srimad-Bhagavatam.`,
            },
            {
                desc: `Because of the successful program held at the Birla Mandira, Srila Prabhupada had managed to raise sufficient donations to again publish his ‘Back to Godhead’ magazine. During the chilly winter mornings of January 1956, without a chaddar or any warm clothing, wearing just a thin cotton jacket and cotton hat and without taking any breakfast, Srila Prabhupada walked all the way to the printers in Old Delhi, rather than take a bus or ricksha, in order to save money, and would spend the morning hours checking the proofs of the magazine and making the necessary corrections. The proprietor of the press, Surinder Kumar Jain, was amazed at the austerities Srila Prabhupada underwent in order to print his ‘Back to Godhead’ magazine and his unflinching enthusiasm to preach the message of Lord Krishna. He saw that Srila Prabhupada had very little money, but whatever he did collect by way of donations, he never wasted it on his own needs, but would instead spend every penny for printing ‘Back to Godhead’ magazine.`
            },
            {
                desc: `Regardless of whatever difficulties he had confronted, undaunted, Srila
                Prabhupada continued with his mission to preach Lord Krishna’s message
                in the English language and continued to produce his monthly magazine
                ‘Back to Godhead.’ On one occasion, while out distributing his magazines
                on the streets of Delhi, Srila Prabhupada was gored and knocked to the
                ground by a rampaging cow and was forced to take bedrest. However,
                within days he was back on the streets distributing his magazines just as
                before. On another occasion, later that same year, during the intense
                summer heat that afflicts Delhi, with temperatures hovering around 44
                degrees centigrade (112 Fahrenheit), Srila Prabhupada began to feel
                suddenly very weak and dizzy, and was on the verge of collapsing in the
                street due to the effects of heat-stroke. Fortunately, at that very moment,
                one of the regular donors of the ‘Back to Godhead’ magazine, who knew
                Srila Prabhupada, was passing by in his car, and seeing that he was
                experiencing some difficulty, took him in his car and drove him to the
                nearest doctor. The doctor advised him to rest for a number of days, but
                soon Srila Prabhupada was back on the streets of Delhi distributing his
                ‘Back to Godhead’ magazines once again.`,
            },
            {
                desc: `Although it began as a simple four-page pamphlet-style magazine containing articles and essays written exclusively by Srila Prabhupada, the importance of the ‘Back to Godhead’ magazine could never be minimized on the grounds that it was just a magazine; it was, in fact, an important introduction to Krishna consciousness for thousands of people who would otherwise never have read books like the Bhagavad-Gita or Srimad-Bhagavatam. In the western world, when ‘Back to Godhead’ was first published there, it gained the status of a ‘new-age magazine’ and its sales worldwide eventually reached a staggering one million copies every month.`,
            },
            // {
            //     desc: `Regardless of whatever difficulties he had confronted, undaunted, Srila Prabhupada continued with his mission to preach Lord Krishna’s message in the English language and continued to produce his monthly magazine ‘Back to Godhead.’ On one occasion, while out distributing his magazines on the streets of Delhi, Srila Prabhupada was gored and knocked to the ground by a rampaging cow and was forced to take bedrest. However, within days he was back on the streets distributing his magazines just as before. On another occasion, later that same year, during the intense summer heat that afflicts Delhi, with temperatures hovering around 44 degrees centigrade (112 Fahrenheit), Srila Prabhupada began to feel suddenly very weak and dizzy and was on the verge of collapsing in the street due to the effects of heat-stroke. Fortunately, at that very moment, one of the regular donors of the ‘Back to Godhead’ magazine, who knew Srila Prabhupada, was passing by in his car, and seeing that he was experiencing some difficulty, took him in his car and drove him to the nearest doctor. The doctor advised him to rest for a number of days, but soon Srila Prabhupada was back on the streets of Delhi distributing his ‘Back to Godhead’ magazines once again.`,
            // },
            {
                desc: `Sometime after taking sannyasa in 1959, Srila Prabhupada happened to meet a librarian who advised him that it would far better for him to publish books rather than magazines, as books lasted a very long time, whereas magazines are generally thrown away after having being read only once. Around this same time, Srila Prabhupada also met an officer from the Indian army who had been regularly reading ‘Back to Godhead’ magazine, and he also advised him that it would be better to print books, as they have a far greater value than magazines. Srila Prabhupada took this advice as the direct mercy of his spiritual master. Therefore he decided to concentrate on writing and publishing books, even though books would be far more time-consuming and certainly more costly to print than magazines. Srila Prabhupada decided that he would begin by writing the first-ever English translation of the great Srimad-Bhagavatam, which was considered to be the cream of all Vedic literature.`,
            },
            {
                desc: `During the year 1961, Srila Prabhupada spent most of his time in Old Delhi and concentrated on the task of completing part one of the Srimad-Bhagavatam's momentous First Canto. Srila Prabhupada’s daily schedule was to wake around 1:00 A.M., and after bathing and chanting japa on his beads, he would begin translating the Bhagavatam around 3:00 A.M. He would continue translating for most of the day, taking only a short break for darshana, take a morning walk, take lunch, and then continue translating for as long as possible throughout the day, unless there were some important meetings or preaching engagements which he would attend. As a result of his pure desire and unbreakable determination, by 1965 the First Canto of Srimad-Bhagavatam was published. Srila Prabhupada was feeling greatly inspired, at long last his life’s mission to fulfill the order of his spiritual master to preach in the English language was now progressing nicely. He firmly believed that the English translation of the spiritually all-potent Srimad-Bhagavatam would be the medium by which he could convert the English-speaking people of the world to Krishna consciousness.`,
            },
            {
                desc: `Having completed the three volumes of the First Canto of the Srimad-Bhagavatam, Srila Prabhupada was now ready. But there were many difficulties in those days for people of Indian origin to travel to America, especially for a mendicant sadhu without any funds. It was necessary to have a sponsorship from an American citizen in order to get permission from the Indian government to make the journey, and also obtain a visa from the American Embassy. `
            },
            {
                desc: `Srila Prabhupada had seen how Lord Krishna had guided him on his long and sometimes arduous journey over the last eleven years and although he had been a penniless and homeless mendicant, by the blessings of his spiritual master, he had survived and somehow or other had managed to translate and print three large volumes of the Srimad-Bhagavatam. He now felt that the final obstacles on his often difficult but nevertheless successful journey towards his goal of preaching the glories of Lord Krishna all over the world would surely be crossed.`,
            },
            {
                desc: `By July 10, 1965, Srila Prabhupada obtained his Indian passport and immediately travelled to Bombay to meet Mrs. Sumati Morarji in the hope of getting a free passage on one of Scindia’s cargo ships to America. On reaching Bombay, Srila Prabhupada went straight to the Scindia Company’s office and Mrs. Morarji's secretary informed her of Srila Prabhupada’s arrival and about his desire to go to America. Mrs. Morarji immediately rejected the proposal, telling that Swamiji was far too old at the age of almost seventy to go to America, adding that it would be far too difficult at such an advanced age to accomplish anything. Mrs. Morarji’s opinion was conveyed to Srila Prabhupada, but he was not ready to give in so easily and asked her secretary to try once again to convince Mrs. Morarji on his behalf. Nevertheless, once again Mrs. Morarji refused, fearing that Srila Prabhupada would face untold problems in such an inhospitable place like America and may even die in the process. After receiving once again Mrs. Morarji’s negative response, Srila Prabhupada pleaded with her assistant to arrange a personal meeting with Mrs. Morarji in order to convince her. She ultimately agreed to meet Srila Prabhupada and after all of her various objections have been defeated, she finally agreed to give him free passage to America on one of her company's ships. `
            },
            {
                desc: `The many reversals and hardships that Srila Prabhupada had faced since accepting the renounced order of life, certainly tested his resolve and determination to continue preaching the message of Lord Krishna. However, these same reversals and hardships would ultimately prepare Srila Prabhupada for the future, when he would have to endure the loneliness of living in a foreign land, amid the inhospitable people of a highly materialistic and morally decadent culture, what to speak of surviving the freezing cold winters of New York City, with its bitingly cold winds, snow and ice. Soon he would arrive on the shores of America, where his greatest test still awaited him.`,
            }
        ],
        moreLinks: [
            {

                index: 1,
                img: application_contants.ASSETS15,
                title: `125th Anniversary Celebration`,
                type: 'Most Popular',
                date: '31 August 2021',
                link: application_contants.ANNIVERSARY,
            }
        ],
    },
];


export default RecommendData;