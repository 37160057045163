import { useWindowSize } from '@react-hook/window-size';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import application_contants from '../../constants/application_contant';
import Header from '../../customComponents/Header';
import GuruParamparaData from '../../utils/json/guruparamparaData';


const GuruParampara = (props) => {
    const myRef = useRef(0);
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { direction } = state;
    const [isAnimate, setIsAnimate] = useState(false);
    const [classNames, setClassNames] = useState('');
    const [slideUpClassNames, setSlideUpClassNames] = useState('');
    const [firstSectionData, setFirstSectionData] = useState([]);
    const [secondSectionData, setSecondSectionData] = useState([]);
    const [width] = useWindowSize();

    React.useEffect(() => {
        const firstList = GuruParamparaData.slice(0, 1);
        const secondList = GuruParamparaData.slice(1, GuruParamparaData.length);
        setFirstSectionData(firstList);
        setSecondSectionData(secondList);
        setTimeout(() => {
            setIsAnimate(true);
            let cls = direction.direction === application_contants.FORWARD ? `animate__animated animate__zoomIn` : `animate__animated animate__zoomIn`
            setClassNames(cls);
            let clsUp = direction.direction === application_contants.FORWARD ? `animate__animated animate__slideUp` : `animate__animated animate__slideUp`
            setSlideUpClassNames(clsUp);
        }, 100)
        return () => {
            setTimeout(() => {
                setIsAnimate(false);
                setClassNames('');
            }, 100)
        }

    }, [direction]);

    React.useEffect(() => {
        if (myRef && myRef.current && myRef.current.clientHeight) {
            props.handleRef(myRef.current.clientHeight);
        }
    }, [myRef, props]);

    return (
        <div className="parampara-content-container position-relative">
            <Header isScroll={false} isBlackLogo={true} isBlackMenu={true} isBlackMedia={true} isFooter={false} />
            {classNames !== '' ?
                <Row className="mx-0 align-items-center">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="m-auto px-0 position-relative">
                        <div className={`position-relative`} ref={myRef}>
                            {width <= application_contants.MOBILE_BREAKPOINT ?
                                <img src={application_contants.GURUPARAMPARABANNER} alt="" className="parampara-img--container" />
                                :
                                <img src={application_contants.GURUPARAMPARABANNER} alt="" className="parampara-img--container" />
                            }
                            <div className={`parampara-title-container ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                <img src={application_contants.GURUPARAMPARATITLEBANNER} alt="" />
                                <div className="title--position">
                                    <h1 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h3-small' : ''} sub-section-title saolStandardMedium pb-1`}>Guru Parampara</h1>
                                </div>
                            </div>
                        </div>
                        {/* {width <= application_contants.MOBILE_BREAKPOINT ? <div className="mt-2 guru-mobile-title--container">
                            <h3 className={`${width <= application_contants.MOBILE_BREAKPOINT ? 'h4-small' : ''} sub-section-title saolStandardLight`}>The Disciplic Succession</h3>
                        </div> : null} */}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={10} xl={10} className="m-auto mt-5">
                        <div className="mt-2">
                                <h3 className="sub-section-title saolStandardLight">The Disciplic Succession</h3>
                            </div>
                        <Row className="mx-0 py-5">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={`d-flex mx-0 flex-wrap justify-content-center ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                    {firstSectionData.length > 0 && firstSectionData.map((it, index) => (
                                        <div className={`pb-5 parampara-guru-img-container m-auto`} key={index}>
                                            <img src={it.imgPath} alt="" />
                                            <p className="p-medium sub-section-title saolStandardLight pt-2">{it.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={`d-flex mx-0 flex-wrap ${isAnimate ? `${classNames} animate__slower` : ''}`}>
                                    {secondSectionData.length > 0 && secondSectionData.map((it, index) => (
                                        <div className={`pb-5 parampara-guru-img-container m-auto`} key={index}>
                                            <img src={it.imgPath} alt="" />
                                            <p className="p-medium sub-section-title saolStandardLight pt-2">{it.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                : null}
        </div>
    )
}

export default GuruParampara;